import { Grid, Icon, Typography } from "@material-ui/core";
import React from "react";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import docIcon from "../../../images/PDF.png";
import blueCircleDoc from "../../../images/blueCircleDoc.png";
import calendar from "../../../images/calendar.png";
import arrow from "../../../images/arrow-right.png";

const DocComplaintCard = ({ doc }) => {
  const { heading, name, date, status, description } = doc;
  return (
    <div style={{ padding: 12 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{ fontSize: 14, fontWeight: 500, padding: "10px 0px" }}
        >
          {heading}
        </Typography>
        <div
          style={{
            backgroundColor: status === "Compliant" ? "#BFE2CD" : "#FFEFD0",
            borderRadius: "13px",
            display: "flex",
            alignItems: "center",
            padding: "3px 8px",
            width: "fit-content",
          }}
        >
          <div
            style={{
              width: "4px",
              height: "4px",
              borderRadius: "50%",
              marginRight: 2,
              backgroundColor: "#154328",
            }}
          />
          <Typography
            style={{
              fontSize: 11,
              fontWeight: 600,
              color: status === "Compliant" ? "#154328" : "#8C7038",
            }}
          >
            {status}
          </Typography>
        </div>
      </div>
      <Typography
        style={{
          fontSize: 13,
          fontWeight: 400,
          padding: "8px 8px",
          wordBreak: "break",
          border: "1px solid #E9E9E9",
          backgroundColor: "#FCFCFC",
          borderRadius: "6px",
          marginTop: 8,
          width: "100%",
        }}
      >
        {description}
      </Typography>
    </div>
  );
};
function DoraCompilance() {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
          Dora Compilance
        </Typography>
        <Icon style={{ cursor: "pointer", color: "#3374b9", fontSize: 20 }}>
          more_vert
        </Icon>
      </div>

      <div
        className="scroll"
        style={{ height: "58vh", marginTop: "10px", paddingBottom: 20 }}
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img src={blueCircleDoc} alt="docIcon" style={{ width: 28 }} />
            <div style={{ width: "65%", paddingLeft: 8 }}>
              <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                ICT INCIDENT MANAGEMENT POLICY
              </Typography>
              <Typography
                style={{ fontSize: 12, color: "#676767", fontWeight: 400 }}
              >
                Refers to an adverse event in an information , communication and
                technology system, and/or network, or the threat of the
                occurrence of such an event.
              </Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", width: "30%" }}
            >
              <img src={calendar} alt="" style={{ width: 20 }} />
              <Typography
                style={{ fontSize: 12, padding: 2, wordBreak: "no-break" }}
              >
                23rd Aug 2030
              </Typography>
              <img src={arrow} alt="" style={{ width: 20 }} />
              <Typography
                style={{ fontSize: 12, padding: 2, wordBreak: "no-break" }}
              >
                23rd Aug 2030
              </Typography>
            </div>
            <Typography
              style={{
                fontSize: 12,
                padding: 2,
                wordBreak: "no-break",
                width: "8%",
              }}
            >
              <b>12</b> times
            </Typography>
          </div>
        </div>

        <Grid
          container
          justifyContent="space-between"
          style={{
            padding: 10,
            marginTop: 15,
            backgroundColor: "#EBF1F8",
            borderBottom: "1px solid #EBF1F8",
          }}
        >
          <Grid item md={1}>
            <Typography style={{ fontSize: 13, fontWeight: 500 }}>
              Item No.
            </Typography>
          </Grid>
          <Grid item md={7}>
            <Typography style={{ fontSize: 14, fontWeight: 500 }}>
              Item
            </Typography>
          </Grid>
          <Grid item md={2} align="center">
            <Typography style={{ fontSize: 14, fontWeight: 500 }}>
              status
            </Typography>
          </Grid>
          <Grid item md={2} align="center">
            <Typography style={{ fontSize: 14, fontWeight: 500 }}>
              Reference
            </Typography>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          {[
            {
              no: "1",
              name: "Incident Management Process",
              desc: "Define and implement an ICT incident management process for detection, handling, and notification.",
              status: "Compliant",
              ref: "",
            },
            {
              no: "2",
              name: "Recording and Monitoring",
              desc: "Record all ICT incidents and significant cyber threats.",
              ref: "",
              status: "Not Compliant",
            },
            {
              no: "3 (a)",
              name: "Incident Management Process Details",
              desc: "Implement early warning indicators.",
              ref: "",
              status: "Compliant",
            },
            {
              no: "3 (b)",
              name: "Incident Management Process Details",
              desc: "Establish procedures for incident identification, tracking, logging, categorization, and classification based on priority, severity, and criticality.",
              ref: "",
              status: "Compliant",
            },
            {
              no: "3 (c)",
              name: "Incident Management Process Details",
              desc: "Assign roles and responsibilities for different incident types.",
              ref: "",
              status: "Not Compliant",
            },
            {
              no: "3 (d)",
              name: "Incident Management Process Details",
              desc: "Develop communication plans for staff, stakeholders, media, and clients, with internal escalation procedures and notification to financial entities",
              ref: "",
              status: "Compliant",
            },
            {
              no: "3 (e)",
              name: "Incident Management Process Details",
              desc: "Report major incidents to senior management and inform the management body, explaining impact, response, and additional controls",
              ref: "",
              status: "Compliant",
            },
            {
              no: "3 (f)",
              name: "Incident Management Process Details",
              desc: "Establish incident response procedures for timely service restoration",
              ref: "",
              status: "Compliant",
            },
          ].map((key) => (
            <Grid
              container
              justifyContent="space-between"
              style={{
                padding: 10,
                backgroundColor: "#FBFBFB",
                borderBottom: "1px solid #EBF1F8",
              }}
            >
              <Grid item md={1}>
                <Typography style={{ fontSize: 13, fontWeight: 400 }}>
                  {key.no}
                </Typography>
              </Grid>
              <Grid item md={7}>
                <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                  {key.name}
                </Typography>
                <Typography
                  style={{ fontSize: 12, color: "#676767", fontWeight: 400 }}
                >
                  {key.desc}
                </Typography>
              </Grid>
              <Grid item md={2} align="center">
                <div
                  style={{
                    backgroundColor:
                      key.status === "Compliant" ? "#BFE2CD" : "#FFEFD0",
                    borderRadius: "13px",
                    display: "flex",
                    alignItems: "center",
                    padding: "3px 8px",
                    width: "fit-content",
                  }}
                >
                  <div
                    style={{
                      width: "4px",
                      height: "4px",
                      borderRadius: "50%",
                      marginRight: 2,
                      backgroundColor: "#154328",
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: 11,
                      fontWeight: 600,
                      color: key.status === "Compliant" ? "#154328" : "#8C7038",
                    }}
                  >
                    {key.status}
                  </Typography>
                </div>
              </Grid>
              <Grid item md={2} align="center">
                <Typography
                  coloe="primary"
                  style={{ fontSize: 13, fontWeight: 400, color: "#3374B9" }}
                >
                  View link
                </Typography>
              </Grid>
            </Grid>
          ))}
        </div>

        {[
          {
            heading: "Incident Management Process",
            name: "Document Name 1",
            date: " 24th Aug 2019",
            status: "Compliant",
            description: `Article 12: The Incident Response Plan (IRP) is a crucial component of
            an organization's cybersecurity strategy, designed to effectively and
            efficiently manage and mitigate security incidents. It serves as a
            structured approach for identifying, containing, eradicating, recovering
            from, and learning from security breaches or unexpected events that may
            impact the confidentiality, integrity, or availability of data and
            systems.`,
          },
          {
            heading: "Document Retention and Destruction",
            name: "Document Name 1",
            date: " 24th Aug 2019",
            status: "Compliant",
            description: `Article 124a : Document Retention and Destruction is a critical aspect of an organization's compliance strategy, focusing on the systematic management, storage, and secure disposal of business records. This process involves establishing policies and procedures to govern the lifecycle of documents, ensuring that information is retained for the necessary duration to meet legal, regulatory, and operational requirements while also being securely destroyed when it is no longer needed.`,
          },
        ].map((doc) => (
          <DocComplaintCard doc={doc} />
        ))}
      </div>
    </div>
  );
}

export default DoraCompilance;
