import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import CustomTextField from "../../UI/textfield/textfield";
import LowtoHighChip from "./LowtoHighChip";
import CustomButton from "../../UI/button/button";

import AttachmentIcon from "@material-ui/icons/AttachmentOutlined";

function EsgRiskRating() {
  const [riskChip, setRiskChip] = useState("medium");

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          style={{ fontWeight: 600, fontSize: "16px", color: "#15314E" }}
        >
          ESG Risk Rating
        </Typography>

        <CustomButton
          variant="text"
          color="primary"
          style={{ textTransform: "none" }}
        >
          Save change
        </CustomButton>
      </div>

      <div className="scroll" style={{ marginTop: 18, height: "55vh" }}>
        <Typography style={{ fontSize: 13, fontWeight: 500, color: "#6C6C6C" }}>
          Assign the Risk score
        </Typography>

        <LowtoHighChip selectedChip={riskChip} setSelectedChip={setRiskChip} />

        <div style={{ padding: "5px 8px" }}>
          <Typography
            style={{
              color: "#6C6C6C",
              fontSize: 13,
              width: "fit-content",
              padding: "5px 0px",
            }}
          >
            Comments
          </Typography>
          <CustomTextField
            variant="outlined"
            multiline
            rows={4}
            size="large"
            inputProps={{
              style: {
                padding: "0 14px",
              },
            }}
            style={{ width: "100%" }}
          />
        </div>

        {/* add attachment */}
        <CustomButton
          color="primary"
          style={{
            marginTop: "10px",
            marginLeft: "8px",
            padding: "8px 16px 8px 16px",
            borderRadius: "8px",
            textTransform: "none",
            backgroundColor: "#EBF1F8",
          }}
          startIcon={<AttachmentIcon style={{ rotate: "310deg" }} />}
        >
          Add attachment
        </CustomButton>
      </div>
    </div>
  );
}

export default EsgRiskRating;
