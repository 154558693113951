import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  makeStyles,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";

// icons
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

// redux imports
import { useDispatch, useSelector } from "react-redux";

import NoData from "../../NoData/NoData";
import {
  setSelectedSection,
  setSelectedSurvey,
} from "../../../../redux/actions/riskdashboard/riskDashboardActions";

const useStyles = makeStyles((theme) => ({
  // table styles
  tableContainer: {
    boxShadow: "none",
  },
  tableHead: {
    backgroundColor: "#EBF1F8",
  },
  tableRows: {
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
    cursor: "pointer",
  },

  // survey status
  activeStatus: {
    padding: "8px 12px 8px 12px",
    borderRadius: "80px",
    display: "flex",
    alignItems: "center",
    width: "max-content",
    fontWeight: "500",
    color: "#154328",
    backgroundColor: "#BFE2CD",
  },
  inactiveStatus: {
    padding: "8px 12px 8px 12px",
    borderRadius: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "max-content",
    fontWeight: "500",
    color: "#8C7038",
    backgroundColor: "#FFEFD0",
  },

  // buttons
  actionButton: {
    fontWeight: "500",
    textTransform: "none",
    color: "#3374B9",
    width: "5rem",
  },
}));

// ----------------- Main Render -----------------
const RiskSurveyInMain = () => {
  // from redux
  const dispatch = useDispatch();
  const { surveyData, surveyPaginationPage } = useSelector(
    (state) => state.riskDashboard
  );

  const classes = useStyles();
  const history = useHistory();

  // table headings
  const headings = [
    "Survey Name",
    // "Survey Type",
    "Survey Created",
    "Survey Dated",
    "Survey Status",
    "Action",
  ];

  // states
  const [publishedRows, setPublishedRows] = useState({});
  const [anchorEl, setAnchorEl] = useState(null); // For Menu anchor element
  const [surveyOption, setSurveyOption] = useState(null);

  // --------------- inner functions ---------------
  // publish handler
  const handlePublish = (index) => {
    setPublishedRows((prevRows) => {
      return {
        ...prevRows,
        [index]: !prevRows[index],
      };
    });
  };

  // Menu open/close handler
  const handleMenuToggle = (event, survey) => {
    setAnchorEl((prevAnchorEl) => (prevAnchorEl ? null : event.currentTarget));
    setSurveyOption(survey);
  };

  // Menu item click handler
  const handleMenuItemClick = (event) => {
    const menuItemText = event.currentTarget.textContent;

    // Determine which menu item was clicked
    switch (menuItemText) {
      case "Edit":
        // Logic for Edit
        console.log("Edit survey:", surveyOption);
        break;
      case "Duplicate":
        // Logic for Duplicate
        console.log("Duplicate survey:", surveyOption);
        break;
      case "Delete Survey":
        // Logic for Delete Survey
        console.log("Deleting survey:", surveyOption);
        break;
      default:
        break;
    }

    // Close the menu
    handleMenuToggle();
  };

  // Survey Status
  const renderSurveyStatus = (status) => {
    const status_class =
      status === true ? classes.activeStatus : classes.inactiveStatus;
    return (
      <Box className={status_class}>
        <FiberManualRecordIcon
          style={{ width: 10, height: 10, marginRight: 5 }}
        />
        {status === true ? "Active" : "Inactive"}
      </Box>
    );
  };

  // Button click handler to prevent propagation
  const handleButtonClick = (event) => {
    event.stopPropagation();
  };
  const sample = {
    survey_name: "survey2",
    survey_createdBy: "baibhav",
    survey_dated: "feb 29, 2022",
    survey_status: true,
    survey_description: "custom section",
  };

  // Survey click handler
  const handleSurveyClick = (selectedSurvey) => {
    const handleName =
      selectedSurvey?.survey_name === "survey1" ||
      selectedSurvey?.survey_name === "survey2"
        ? selectedSurvey
        : sample;

    // setting selected survey
    dispatch(setSelectedSurvey(handleName));

    // Navigate to the survey details
    history.push(`/vendor_survey/survey_details`);
  };
  // -----------------------------------------------
  useEffect(() => {
    dispatch(setSelectedSection(""));
  }, []);
  // Calculate the range of surveys to display based on pagination
  const startIndex = (surveyPaginationPage - 1) * 5;
  const endIndex = startIndex + 5;

  // Check for No Survey Data
  if (!surveyData || surveyData.length === 0) {
    return <NoData />;
  }

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            {headings.map((heading, index) => (
              <TableCell key={index} className={classes.tableHead}>
                {heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {surveyData.slice(startIndex, endIndex).map((survey, index) => (
            <TableRow
              className={classes.tableRows}
              key={index}
              onClick={() => handleSurveyClick(survey)}
            >
              <TableCell>
                {survey?.survey_name === "survey1"
                  ? "ESG Assessment Survey"
                  : "DORA TPRM Survey"}
              </TableCell>
              {/* <TableCell>{survey.survey_type}</TableCell> */}
              <TableCell>{survey.survey_createdBy}</TableCell>
              <TableCell>{survey.survey_dated}</TableCell>
              <TableCell>{renderSurveyStatus(survey.survey_status)}</TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button
                    className={classes.actionButton}
                    onClick={(e) => {
                      handleButtonClick(e);
                      handlePublish(index);
                    }}
                  >
                    {!publishedRows[index] ? "Publish" : "Unpublish"}
                  </Button>
                  <IconButton
                    aria-controls="survey-actions-menu"
                    aria-haspopup="true"
                    onClick={(e) => {
                      handleButtonClick(e);
                      handleMenuToggle(e, survey);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Survey Actions Menu */}
      <Menu
        id="survey-actions-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuToggle}
        PaperProps={{
          style: {
            borderRadius: 8,
            width: 200,
          },
        }}
      >
        <MenuItem
          style={{
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            marginBottom: "10px",
          }}
          onClick={(e) => handleMenuItemClick(e)}
        >
          <EditOutlinedIcon style={{ color: "#3374B9", marginRight: 8 }} />
          Edit
        </MenuItem>
        <MenuItem
          style={{
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            marginBottom: "10px",
          }}
          onClick={(e) => handleMenuItemClick(e)}
        >
          <FileCopyOutlinedIcon style={{ color: "#3374B9", marginRight: 8 }} />
          Duplicate
        </MenuItem>
        <MenuItem
          style={{
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#D0766B",
          }}
          onClick={(e) => handleMenuItemClick(e)}
        >
          <DeleteOutlinedIcon style={{ color: "#C45446", marginRight: 8 }} />
          Delete Survey
        </MenuItem>
      </Menu>
    </TableContainer>
  );
};

export default RiskSurveyInMain;
