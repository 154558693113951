import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomTextfield from "./../../UI/textfield/textfield";
import CustomButton from "./../../UI/button/button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { passwordValidation } from "../../../redux/actions/regexValidation/regexValidation";
import generalSettingFormStyles from "./genralSettingsFormStyles";
import { useDispatch, useSelector } from "react-redux";
import { resetUserPassword } from "../../../redux/actions/generalSettings/generalSettingsAction";

export default function GeneralSettingForm(props) {
  const classes = generalSettingFormStyles();
  const { login } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [dialog, setDialog] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newpasswordConfirm, setNewpasswordConfirm] = useState("");

  const [isCrntPasswordVisible, setIsCrntPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfPasswordVisible, setIsConfPasswordVisible] = useState(false);

  const handleReset = () => {
    setDialog(!dialog);
    dispatch(resetUserPassword(login.token, currentPassword, newPassword));
    setCurrentPassword("");
    setNewPassword("");
    setNewpasswordConfirm("");
  };

  function PasswordResetDialog() {
    return (
      <>
        <Dialog open={dialog} maxWidth="xs">
          <DialogTitle>
            <Grid container justifyContent="center">
              <Grid item xs={6}>
                <Typography className={classes.Typography}>
                  Reset Password
                </Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box mx={1}>
                  <CustomTextfield
                    name="currPassword"
                    variant="outlined"
                    label="Current Password"
                    size="small"
                    type={isCrntPasswordVisible ? "text" : "password"}
                    className={classes.CustomTextfield}
                    value={currentPassword}
                    onChange={(e) => {
                      setCurrentPassword(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.InputAdornment}
                          onClick={() =>
                            setIsCrntPasswordVisible(!isCrntPasswordVisible)
                          }
                        >
                          {isCrntPasswordVisible ? (
                            <VisibilityIcon fontSize="small" color="disabled" />
                          ) : (
                            <VisibilityOffIcon
                              fontSize="small"
                              color="disabled"
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box mx={1}>
                  <CustomTextfield
                    name="newPassword"
                    variant="outlined"
                    label="New Password"
                    size="small"
                    type={isNewPasswordVisible ? "text" : "password"}
                    className={classes.CustomTextfield}
                    value={newPassword}
                    error={
                      newPassword !== "" && !passwordValidation(newPassword)
                    }
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.InputAdornment}
                          onClick={() =>
                            setIsNewPasswordVisible(!isNewPasswordVisible)
                          }
                        >
                          {isNewPasswordVisible ? (
                            <VisibilityIcon fontSize="small" color="disabled" />
                          ) : (
                            <VisibilityOffIcon
                              fontSize="small"
                              color="disabled"
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    helperText="At least 8 character, 1 uppercase, 1 lowercase, 1 number and 1 symboll."
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box mx={1}>
                  <CustomTextfield
                    name="cnfPassword"
                    variant="outlined"
                    label="Confirm Password"
                    size="small"
                    type={isConfPasswordVisible ? "text" : "password"}
                    value={newpasswordConfirm}
                    error={
                      newpasswordConfirm !== "" &&
                      newPassword !== newpasswordConfirm
                    }
                    onChange={(e) => {
                      setNewpasswordConfirm(e.target.value);
                    }}
                    className={classes.CustomTextfield}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.InputAdornment}
                          onClick={() =>
                            setIsConfPasswordVisible(!isConfPasswordVisible)
                          }
                        >
                          {isConfPasswordVisible ? (
                            <VisibilityIcon fontSize="small" color="disabled" />
                          ) : (
                            <VisibilityOffIcon
                              fontSize="small"
                              color="disabled"
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="space-evenly">
              <Grid item xs={3}>
                <CustomButton
                  name="cancelBtn"
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setDialog(!dialog);
                  }}
                >
                  Cancel
                </CustomButton>
              </Grid>
              <Grid item xs={5}>
                <CustomButton
                  name="resetBtn"
                  color="primary"
                  variant="contained"
                  onClick={handleReset}
                  className={classes.CustomButton}
                  disabled={
                    currentPassword === "" ||
                    newPassword === "" ||
                    newpasswordConfirm === "" ||
                    newPassword !== newpasswordConfirm ||
                    !passwordValidation(newpasswordConfirm)
                  }
                >
                  Reset
                </CustomButton>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <>
      {dialog && PasswordResetDialog()}
      <div style={{ padding: 100, paddingTop: 100 }}>
        {/* Headingh */}
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.Typography1}>
              General Settings
            </Typography>
            <Typography>
              These settings will only be applicable to this account
            </Typography>
          </Grid>
        </Grid>

        {/* Email and password reset */}
        <Grid container spacing={1} className={classes.grid}>
          <Grid item xs={12}>
            <CustomTextfield
              variant="outlined"
              size="small"
              className={classes.CustomTextfield1}
              value={login.email}
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <CustomButton
              name="passReset"
              variant="outlined"
              color="primary"
              onClick={() => {
                setDialog(!dialog);
              }}
            >
              Password Reset
            </CustomButton>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
