import React, { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CustomButton from "../../../../../UI/button/button";

import CustomTextfield from "../../../../../UI/textfield/textfield";

import PublishRoundedIcon from "@material-ui/icons/PublishRounded";

import GetApp from "@material-ui/icons/GetApp";

// import { brmRightStyles } from "./brmRightStyle";

// import { useDispatch, useSelector } from "react-redux";
// import { addMember } from "../../../../redux/actions/member/member";

const brmRightStyles = makeStyles(() => ({
  emptyCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  emptyImg: {
    width: "40%",
  },
  text: { fontWeight: 500, paddingBottom: 5 },
  subText: { fontSize: 13, color: "#878787", paddingBottom: 8 },
  uploadBtn: {
    width: "20%",
    textTransform: "Capitalize",
    margin: "0px 5px",
  },
  cardContainerDefault: {
    width: "100%",
    height: "100px",
    background: "#FDFDFD",
    border: "1px solid #C0D4E9",
    borderRadius: "8px",
    marginBottom: "16px",
  },
  addSectorContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  mainHeadingDefault: {
    fontWeight: 500,
    fontSize: "16px",
    color: "#242424",
    lineHeight: "24px",
    letterSpacing: "0.0015em",
    marginBottom: 8,
  },

  subHeadingDefault: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#BBBBBB",
    letterSpacing: "0.0025em",
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    // cursor: "pointer",
  },
  cardContainer: {
    backgroundColor: "#FFFFFF",
    // marginBottom: '15px',
    borderRadius: 5,
    height: "100%",
  },

  cardTypography: {
    fontWeight: "600",
  },
  Typography3: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  grid2: { marginTop: 15 },
  grid3: { display: "flex", justifyContent: "flex-end" },
  grid4: { paddingLeft: 75 },
  grid5: { paddingRight: 75 },
  grid6: { paddingRight: 10 },
  grid7: { marginTop: 20 },
  CustomButton1: { fontSize: 12 },
  CustomButton2: { marginLeft: 15, fontSize: 12, textTransform: "none" },
  mainGrid2: { borderBottom: "1px solid #DADBE6", paddingBottom: 5 },
  Typographyshare: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  grid8: { width: "100%" },
  AppBar: { boxShadow: "none", maxHeight: "25px" },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  tab: {
    fontSize: 12,
  },
  sectorChip: {
    justifyContent: "flex-start",
    color: "#77787B",
    backgroundColor: "#F5F6F8",
    borderRadius: "5%",
    border: "0px",
    margin: "4px 8px 4px 0px",
    "&&:hover": {
      "& $removeChipIcon": {
        display: "inline",
      },
    },
    "&&:focus": {
      color: "#77787B",
      backgroundColor: "#F5F6F8",
    },
  },
  selectedSectorTitle: {
    color: "#77787B",
    fontSize: 14,
  },
  companyLogo: {
    border: "2px solid #DADBE6",
    width: 120,
    height: 120,
    borderRadius: 5,
    zIndex: 1,
    "& img": {
      width: "100%",
      height: "100%",
    },
    position: "relative",
  },
  ClearImage: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 2,
  },
  logoSelecter: {
    border: "2px dashed #DADBE6",
    width: 120,
    height: 120,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      "& $addIcon": {
        color: "#3374B9",
      },
    },
  },
  addIcon: {
    color: "#DADBE6",
  },
  apiCont: {
    flexGrow: 1,
  },
  paper: {
    //   padding: theme.spacing(1),
    //   color: theme.palette.text.secondary,
    border: "1px solid lightgrey",
    borderRadius: "8px",
  },
  onListHover: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
  },
  CustomButton3: {
    textTransform: "none",
    width: "10em",
  },
}));

const stakeHolderCategoryList = [
  "Board of Directors",
  "Executive Team",
  "Employees",
  "Investors & Shareholders",
  "Suppliers & Vendors",
  "Consultants",
  "Customers",
  "Government Agencies",
];

export default function UserDialog({
  addNewUserDialogOpen,
  handleCloseUserDialog,
}) {
  const classes = brmRightStyles();
//   const dispatch = useDispatch();

//   const { token, bankId } = useSelector((state) => state?.login);
//   const { organizationDetails } = useSelector((state) => state?.brmData);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("");
  const [role, setRole] = useState("");
//   const type = "";

  const uploadHandler = (e) => {
    const file = e.target.files[0];
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      alert("Please upload an excel file");
    } else {
      // uploadUserSheet(login.token, login.current_organisation, file);
      handleCloseUserDialog();
    }
  };

  function handleAddUser() {
    // dispatch(
    //   addMember(
    //     token,
    //     bankId,
    //     organizationDetails[0]?._id
    //       ? organizationDetails[0]?._id
    //       : localStorage.getItem("organization_id"),
    //     name,
    //     email,
    //     role,
    //     category,
    //     type,
    //     window.location.origin
    //   )
    // );
    handleCloseUserDialog();
  }

  return (
    <Dialog open={addNewUserDialogOpen} maxWidth="md">
      <DialogTitle>
        <Grid container justifyContent="center" className={classes.mainGrid}>
          <Grid item xs={3}>
            <Typography className={classes.Typography3}>
              Add Stakeholders
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
          className={classes.grid2}
        >
          <Grid item md={12} className={classes.grid3}>
            <>
              <CustomButton
                name="uploadTemp"
                startIcon={<PublishRoundedIcon />}
                className={classes.CustomButton2}
                variant="outlined"
                color="secondary"
                component="label"
              >
                Upload Excel
                <input
                  id="excel-upload"
                  type="file"
                  hidden
                  cursor="pointer"
                  onChange={(e) => uploadHandler(e)}
                />
              </CustomButton>
              <a
                download
                href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQKbeO_-PkJKIXrH3qHW4ePLL-xxKtRkB8VwfSRlkcSbjrfjiYY6VSxWjQjQOunfGSptRXHTOfqfUxW/pub?output=xlsx"
              >
                <CustomButton
                  name="uploadTemp"
                  startIcon={<GetApp />}
                  className={classes.CustomButton2}
                  variant="contained"
                  size="small"
                  color="secondary"
                  component="label"
                >
                  Download Excel
                </CustomButton>
              </a>
            </>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.grid2}>
          <Grid item xs={12} md={6} className={classes.grid4}>
            <CustomTextfield
              name="userName"
              label="Name"
              variant="outlined"
              size="small"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.grid5}>
            <CustomTextfield
              name="userRole"
              label="Role"
              variant="outlined"
              size="small"
              fullWidth
              value={role}
              onChange={(e) => setRole(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} md={6} className={classes.grid4}>
            <CustomTextfield
              name="userEmail"
              label="Email"
              variant="outlined"
              size="small"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.grid5}>
            <CustomTextfield
              name="userCategory"
              label="Category"
              variant="outlined"
              size="small"
              fullWidth
              select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              {stakeHolderCategoryList.map((cat) => (
                <MenuItem key={cat} value={cat}>
                  {cat}
                </MenuItem>
              ))}
            </CustomTextfield>
          </Grid>

          <Grid item xs={12} md={6} className={classes.grid4}></Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} className={classes.grid7}>
          <Grid item xs={3} md={6} className={classes.grid3}>
            <CustomButton
              name="cancelBtn"
              color="primary"
              variant="outlined"
              onClick={() => handleCloseUserDialog()}
            >
              Cancel
            </CustomButton>
          </Grid>
          <Grid item xs={5} md={6} className={classes.grid6}>
            <CustomButton
              name="addNewBtn"
              color="primary"
              variant="contained"
              className={classes.CustomButton3}
              disabled={
                name === "" || email === "" || category === "" || role === ""
              }
              onClick={handleAddUser}
            >
              Add New User
            </CustomButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
