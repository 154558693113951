import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Drawer,
} from "@material-ui/core";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  getSurveyRespondedUser,
  getSurveyResponses,
  setSurveyResponse,
} from "../../../../../../redux/actions/riskdashboard/riskDashboardActions";

// component imports
import { responsesData } from "../tempData";
import NoData from "../../../../NoData/NoData";
import ResponseDrawer from "./ResponseComponents/ResponseDrawer";

const useStyles = makeStyles(() => ({
  // table styles
  tableContainer: {
    boxShadow: "none",
  },
  tableHead: {
    backgroundColor: "#EBF1F8",
  },
  tableRows: {
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
    cursor: "pointer",
  },
}));

// ----------------- Main Render -----------------
const ResponsesTab = () => {
  const classes = useStyles();

  // from redux
  const dispatch = useDispatch();

  // state for drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { token } = useSelector((state) => state?.login);
  const { respondedOrganizations } = useSelector(
    (state) => state?.riskDashboard
  );


  // table headings
  const headings = [
    "Organisation Name",
    "No. of Stakeholder",
    "Response count",
    "Organization Dated",
  ];

  useEffect(() => {
    // dispatch(getSurveyResponses(token));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --------------- inner functions ---------------
  // handling drawer open and close
  const handleDrawer = (_id) => {
    setDrawerOpen(!drawerOpen);
    // !drawerOpen &&
    //   dispatch(getSurveyRespondedUser(token, _id?.organization_id));
  };

  // Format date function
  const formatDate = (dateString) => {
    const options = { month: "short", day: "numeric", year: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  };

  // -----------------------------------------------

  if (!responsesData || responsesData.length === 0) {
    return <NoData />;
  }

  return (
    <div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {headings.map((heading, index) => (
                <TableCell key={index} className={classes.tableHead}>
                  {heading}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {respondedOrganizations?.map((response, index) => (
              <TableRow
                className={classes.tableRows}
                key={index}
                onClick={() => {
                  dispatch(setSurveyResponse(response));
                  handleDrawer(response);
                }}
              >
                <TableCell>{response.organization_name}</TableCell>
                <TableCell>{response.no_of_stakeholders}</TableCell>
                <TableCell>{response.response_count}</TableCell>
                <TableCell>{formatDate(response.organization_dated)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Response Drawer */}
      {drawerOpen && (
        <Drawer anchor="right" open={drawerOpen} onClose={handleDrawer}>
          <ResponseDrawer handleDrawer={handleDrawer} />
        </Drawer>
      )}
    </div>
  );
};
export default ResponsesTab;