import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  TableRow,
  TableCell,
  Box,
  Avatar,
  Typography,
} from "@material-ui/core";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";
import { IoIosArrowForward } from "react-icons/io";

import DynamicTable, {
  statusStyle,
  textStyle,
  useTableStyles,
} from "../../../UI/dynamicTable/DynamicTable";
import {
  selectedTaskData,
  selectedTaskHeadings,
} from "../../../screen/taskScreen/taskTemp";
import { useDispatch } from "react-redux";
import { SetSelectedSection } from "../../../../redux/actions/taskActions/TaskAction";

const noBgStatusStyle = (status) => {
  let colorToUse = "#FFC250";

  if (status === "complete") {
    colorToUse = "#5BB37F";
  }

  return {
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    color: colorToUse,
    gap: "4px",
  };
};

const AssignedTab = () => {
  const classes = useTableStyles();
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  // Grouping tasks based on date
  const groupedTasks = selectedTaskData.reduce((acc, curr) => {
    acc[curr.date] = [...(acc[curr.date] || []), curr];
    return acc;
  }, {});

  return (
    <Box>
      <DynamicTable headings={selectedTaskHeadings}>
        {Object.entries(groupedTasks).map(([date, tasks]) => (
          <React.Fragment key={date}>
            {/* Render Date Header */}
            <TableRow>
              <TableCell
                colSpan={7}
                style={{
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#15314E",
                }}
              >
                {date}
              </TableCell>
            </TableRow>
            {/* Render Task Rows */}
            {tasks.map((rowData, index) => (
              <TableRow
                key={index}
                className={classes.tableRowHover}
                onClick={async () => {
                  await dispatch(
                    SetSelectedSection(rowData._id, rowData.task_type)
                  );
                  history.push(
                    `${location.pathname}/${rowData.task_type.replace(
                      / /g,
                      "_"
                    )}`
                  );
                }}
              >
                <TableCell style={textStyle("main")}>
                  {rowData.task_type}
                </TableCell>
                <TableCell>
                  <Box
                    style={noBgStatusStyle(
                      rowData.survey_frequency.toLowerCase()
                    )}
                  >
                    <DotIcon style={{ widht: "10px", height: "10px" }} />
                    <Typography style={textStyle("main")}>
                      {rowData.survey_frequency}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    style={noBgStatusStyle(
                      rowData.action_required.toLowerCase()
                    )}
                  >
                    <DotIcon style={{ widht: "10px", height: "10px" }} />
                    <Typography style={textStyle("main")}>
                      {rowData.action_required}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box style={statusStyle(rowData.status.toLowerCase())}>
                    <DotIcon style={{ widht: "10px", height: "10px" }} />
                    {rowData.status}
                  </Box>
                </TableCell>
                <TableCell style={textStyle()}>{rowData.sent_on}</TableCell>
                <TableCell style={textStyle()}>
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    sx={{ gap: 10 }}
                  >
                    <Avatar
                      src={
                        rowData.assigned_by.avatar || rowData.assigned_by.name
                      }
                      alt={rowData.assigned_by.name}
                    />
                    <Box display={"flex"} flexDirection={"column"}>
                      <Typography style={textStyle("main")}>
                        {rowData.assigned_by.name}
                      </Typography>
                      <Typography
                        style={{
                          color: "#AFAFAF",
                          fontWeight: 500,
                          fontSize: "11px",
                          lineHeight: "16px",
                        }}
                      >
                        {rowData.assigned_by.designation}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell style={textStyle()}>
                  <IoIosArrowForward color="#374151" size={20} />
                </TableCell>
              </TableRow>
            ))}
          </React.Fragment>
        ))}
      </DynamicTable>
    </Box>
  );
};
export default AssignedTab;
