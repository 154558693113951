import {
  Button,
  Grid,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { useRef, useState } from "react";
import { brmRightStyles } from "./brmDataRight.js/brmDataRight";
import { UserCard } from "./riskUtilComponents";
import CustomButton from "../../UI/button/button";
import CustomTextfield from "../../UI/textfield/textfield";
import noUser from "../../../images/NoUser.png";
function StakeholderList({ brmData, setAddNewUserDialogOpen, handleEditUser }) {
  const classes = brmRightStyles();
  const [filtered, setFiltered] = useState([]);
  const text = useRef("");
  const handleSearch = (e) => {
    const searchText = e.target.value.trim().toLowerCase();
    text.current = searchText;
    if (brmData?.members && brmData?.members.length > 0 && searchText) {
      console.log(searchText);

      const filter = brmData?.members.filter((item) => {
        return (
          item.name.toLowerCase().includes(searchText) ||
          item.email.toLowerCase().includes(searchText)
        );
      });
      setFiltered(filter);
    } else if (brmData?.members && brmData?.members.length > 0 && !searchText) {
      setFiltered(brmData?.members);
    } else {
      setFiltered([]);
    }
  };
  console.log(filtered);
  return (
    <div style={{}} className={classes.mainDiv}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography style={{ fontWeight: 400, fontSize: "16px" }}>
            Stakeholders List
          </Typography>
        </div>
        <div>
          <Button
            // variant="contained"
            size="small"
            // startIcon={<Add />}
            color="primary"
            style={{ textTransform: "capitalize" }}
            onClick={() => setAddNewUserDialogOpen(true)}
          >
            Add Users
          </Button>
        </div>
      </div>
      <div>
        {/* <Grid
          container
          md={12}
          sm={12}
          xs={12}
          style={{
            marginTop: "10px",
            backgroundColor: "#EBF1F8",
            padding: "3px",
            borderRadius: "5px",
          }}
        >
          <Grid item md={5} xs={5}>
            <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
              Name / E-mail
            </Typography>
          </Grid>
          <Grid item md={3} xs={3}>
            <Typography style={{ fontSize: "13px" }}>Category</Typography>
          </Grid>
          <Grid item md={3} xs={3} align="center">
            <Typography style={{ fontSize: "13px" }}>Role</Typography>
          </Grid>
          <Grid item md={1} xs={1}>
            <Typography style={{ fontSize: "13px", alignSelf: "right" }}>
              Edit
            </Typography>
          </Grid>
        </Grid> */}
        <div className="scroll" style={{ height: "59vh" }}>
          {brmData?.members && brmData?.members.length > 0 && (
            <CustomTextfield
              name="Search"
              variant="outlined"
              fullWidth
              size="small"
              type="text"
              label="Search by employee name/ email/ Job/ Id"
              onChange={handleSearch}
              style={{ margin: "8px 0px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon style={{ color: "#3374B9" }} />
                  </InputAdornment>
                ),
              }}
              // value={searchCompanyText}
            />
          )}

          {text.current ? (
            filtered.length > 0 ? (
              filtered.map((member) => (
                <UserCard
                  hideCheckbox
                  key={member.name}
                  member={member}
                  onClick={() => {}}
                />
              ))
            ) : (
              <Typography>No Users Found</Typography>
            )
          ) : (
            brmData?.members?.map((member) => (
              <UserCard
                hideCheckbox
                key={member.name}
                member={member}
                onClick={() => {}}
              />
            ))
          )}
          {/* {text.current && filtered.length > 0 ? (
            filtered.map((member) => (
              <UserCard
                hideCheckbox
                key={member.name}
                member={member}
                onClick={() => {}}
              />
            ))
          ) : filtered.length === 0 ? (
            <Typography>No Users Found</Typography>
          ) : (
            brmData??.members?.map((member) => (
              <UserCard
                hideCheckbox
                key={member.name}
                member={member}
                onClick={() => {}}
              />
            ))
          )} */}
          {brmData?.members && brmData?.members.length === 0 && (
            <div className={classes.emptyCont}>
              <img className={classes.emptyImg} src={noUser} alt="analytics" />
              <Typography className={classes.text}>
                Currently no User has been uploaded to showcase
              </Typography>
              <Typography className={classes.subText}>
                Upload file type (.xls, .pdf, .doc) Max size of 5 Mb
              </Typography>
              <Grid container justifyContent="center">
                <CustomButton
                  className={classes.uploadBtn}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setAddNewUserDialogOpen(true);
                  }}
                >
                  Add User
                </CustomButton>
              </Grid>
            </div>
          )}
        </div>
        {/* {brmData?.members &&
          brmData?.members.length > 0 &&
          brmData?.members.map((member) => (
            <Grid
              container
              md={12}
              sm={12}
              xs={12}
              style={{
                marginTop: "10px",
                padding: "5px",
                borderBottom: "1px solid grey",
                borderRadius: "2px",
                alignItems: "center",
              }}
            >
              <Grid item md={5} xs={5}>
                <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                  {member.name}
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    marginLeft: "5px",
                    color: "grey",
                  }}
                >
                  {member.email}
                </Typography>
              </Grid>
              <Grid item md={3} xs={3}>
                <Typography style={{ fontSize: "13px" }}>
                  {member.category}
                </Typography>
              </Grid>
              <Grid item md={3} xs={3} align="center">
                <Typography style={{ fontSize: "13px" }}>
                  {member.role}
                </Typography>
              </Grid>
              <Grid item md={1} xs={1}>
                <Icon
                  onClick={() => handleEditUser(member)}
                  style={{ alignSelf: "right", cursor: "pointer" }}
                >
                  more_vert
                </Icon>
              </Grid>
            </Grid>
          ))} */}
      </div>
    </div>
  );
}

export default StakeholderList;
