import moment from "moment";
import {
  SET_BRM_MAIN_TAB_NO,
  SET_BRM_SUB_TAB_NO,
  SET_MEMBERS,
  SET_ORGANIZATIONS_LIST,
  SET_ORGANIZATION_DETAILS,
  SET_SECTOR_INDUSTRIES,
  SET_SELECTED_SECTOR_INDUSTRIES,
  SET_DECLARATION_TAB_NO,
  SET_CONTROVERSY_TAB_NO,
  SET_PROFILE_TAB_NO,
  SET_COUNTRIES,
  SET_ADD_VENDOR,
  SET_COMPANY_DOCUMENTS,
  SET_UPDATE_ORGS_LIST,
} from "../../../constants/brmConstants";
import { SET_SURVEY_QUESTIONS } from "../../../constants/corporateSurveyConstants";
import {
  dummyIndustries,
  vendorCountries,
  vendorDetails,
  vendorOrgs,
  vendorUsers,
} from "../../../utils/riskUtils/riskUtils";
const dummy = JSON.parse(JSON.stringify(dummyIndustries));

const demoCompanyDocs = [
  {
    file_name: "Service Annual Agreement",
    year: "2023-2024",
    type: "Agreement",
    user_name: "Anish Varma",
    uploaded_on: moment("02:00pm 01 March 24", "hh:mma DD MMMM YY"),
    satus: true,
  },
  {
    file_name: "Provision for Cloud Services Agreement",
    year: "2023-2024",
    type: "Agreement",
    user_name: "Anish Varma",
    uploaded_on: moment("02:10pm 01 March 24", "hh:mma DD MMMM YY"),
    satus: true,
  },
];

const initial_state = {
  brmMainTabNo: 0,
  brmSubTabNo: 0,
  declarationTabNo: 0,
  controversyTabNo: 0,
  profileTabNo: 0,
  sectorIndustries: dummy || [],
  organizationDetails: "", //vendorDetails
  members: vendorUsers || "",
  organizationsList: vendorOrgs || "",
  surveyQuestions: [],
  totalOrganizations: 0,
  countries: vendorCountries || [],

  companyDocuments: demoCompanyDocs || [],
};

export default function brmReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_BRM_MAIN_TAB_NO:
      return (state = { ...state, brmMainTabNo: action.payload });
    case SET_COUNTRIES:
      return (state = { ...state, countries: action.payload });
    case SET_BRM_SUB_TAB_NO:
      return (state = { ...state, brmSubTabNo: action.payload });
    case SET_DECLARATION_TAB_NO:
      return (state = { ...state, declarationTabNo: action.payload });
    case SET_CONTROVERSY_TAB_NO:
      return (state = { ...state, controversyTabNo: action.payload });
    case SET_PROFILE_TAB_NO:
      return (state = { ...state, profileTabNo: action.payload });
    case SET_SECTOR_INDUSTRIES:
      return (state = { ...state, sectorIndustries: action.payload });
    case SET_SELECTED_SECTOR_INDUSTRIES:
      return (state = { ...state, selectedSectorIndustries: action.payload });
    case SET_ORGANIZATION_DETAILS:
      return (state = { ...state, organizationDetails: action.payload });
    case SET_MEMBERS:
      return (state = { ...state, members: action.payload });
    case SET_ORGANIZATIONS_LIST:
      return (state = {
        ...state,
        organizationsList: action.payload,
        totalOrganizations: action.total,
      });
    case SET_UPDATE_ORGS_LIST:
      return (state = { ...state, organizationsList: action.payload });
    case SET_ADD_VENDOR:
      return {
        ...state,
        organizationsList: [action.payload, ...state.organizationsList],
      };
    case SET_SURVEY_QUESTIONS:
      return (state = { ...state, surveyQuestions: action.payload });

    case SET_COMPANY_DOCUMENTS:
      return {
        ...state,
        companyDocuments: [action.payload, ...state.companyDocuments],
      };

    default:
      return state;
  }
}
