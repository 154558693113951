import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function ChartPie({
  backgroundColor,
  label = [],
  dataChart = [],
}) {
  const data = {
    labels: label,
    datasets: [
      {
        label: "# of Votes",
        data: dataChart,
        backgroundColor,
        borderColor: backgroundColor,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        // position: 'left',
        labels: {
          align: "left",
          // Customize label styles here
          font: {
            size: "10rem",
            weight: "bold",
          },
          usePointStyle: true,
          pointStyle: "circle",
          color: "#878787",
        },
      },
    },
  };

  return <Pie data={data} options={options} />;
}
