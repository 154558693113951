/* eslint-disable no-multi-str */
import React, { useState } from "react";

import StepLeft from "../../stepLayout/left/StepLeft";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  MuiThemeProvider,
  createTheme,
} from "@material-ui/core";
import { appTheme } from "../../../selectedSurvey";

import SurveyList from "../../step2/left/SurveyList";
import { LeftCard } from "../../step1/left/SurveyLeftCard";

const widgetData = [
  { name: "Sections Assigned", value: 16 },
  { name: "Sections Collected", value: 16 },
  { name: "Sections Completed", value: 16 },
  { name: "Sections Approved", value: 16 },
];

const basicDataFirstTab = [
  {
    id: 1,
    mainHead: "Brief",
    subHead:
      "• The Freeze data Tab is to ensure the data is Secure & stored in a blockchain.\n\
    • The Link we develop is called Hash link which is SHA256 (256 bits, 64 symbols) It is secure.\n\
    • SHA = Secure Hash Algorithm\n\
    • Even a smallest of change in the data will have a completely unique code\n\
    • The code in SHA256 is collision resistance which means the same code wont be available for any other data\n\
    • This option can be skipped if you do not wish to freeze the data",
  },
];

function Step3Left({
  tabValue,
  handleTabChange,
  surveyList,
  handleSelectSection,
}) {
  return (
    <StepLeft>
      <Box
        style={{
          display: "flex",
          // padding: "10px",
          justifyContent: "space-between",
          gap: 10,
          alignItems: "center",
          // border:"solid",
          // height:"30px"
          // width:"50%"
        }}
      >
        <MuiThemeProvider theme={appTheme}>
          <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth">
            <Tab
              disableRipple
              label={<span>Audit data</span>}
              style={{ textTransform: "capitalize" }}
            />
            <Tab
              disableRipple
              label={<span>Freeze Data</span>}
              style={{ textTransform: "capitalize" }}
            />
          </Tabs>
        </MuiThemeProvider>
      </Box>
      {tabValue === 0 && (
        <Box
          style={{
            display: "flex",
            padding: "10px",
            // justifyContent: "space-between",
            gap: 3,
          }}
        >
          {widgetData.map((data) => (
            <Box
              style={{
                border: "1px solid #E9E9E9",
                borderRadius: "8px",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                flex: 1,
                justifyContent: "space-between",
              }}
            >
              <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                {data?.name}
              </Typography>
              <Typography
                style={{ fontSize: "26px", fontWeight: 500, color: "#3374B9" }}
              >
                {data?.value}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
      {tabValue === 0 && (
        <Box style={{ overflow: "scroll", height: "60%" }}>
          <SurveyList
            surveyList={surveyList}
            handleSelectSection={handleSelectSection}
          />
        </Box>
      )}
      {tabValue === 1 && (
        <Box style={{ marginTop: "10px" }}>
          <LeftCard toMap={basicDataFirstTab} subTabNo={1} />
        </Box>
      )}
    </StepLeft>
  );
}

export default Step3Left;
