import React, { useState } from "react";
import { Typography } from "@material-ui/core";

import StepRight from "../../stepLayout/right/StepRight";
import StakeholderList from "./SurveyStakeHolderList";
import UserDialog from "./UserDialog";

export const dummyData = {
  members: [
    { name: "saad", email: "saad@appstone.in", role: "dev", id: 0, isCheck:false },
    { name: "vaibhaw", email: "vaibhaw@appstone.in", role: "dev", id: 1, isCheck:false },
    { name: "bhaibhav", email: "bhaibhav@appstone.in", role: "dev", id: 2, isCheck:false },
    { name: "deepak", email: "deepak@appstone.in", role: "dev", id: 3, isCheck:false },
    { name: "john", email: "john@example.com", role: "manager", id: 4, isCheck:false },
    { name: "alice", email: "alice@example.com", role: "designer", id: 5, isCheck:false },
    { name: "bob", email: "bob@example.com", role: "dev", id: 6 , isCheck:false},
    { name: "emma", email: "emma@example.com", role: "manager", id: 7, isCheck:false },
    { name: "michael", email: "michael@example.com", role: "dev", id: 8, isCheck:false },
    { name: "sara", email: "sara@example.com", role: "designer", id: 9, isCheck:false },
    { name: "peter", email: "peter@example.com", role: "dev", id: 10, isCheck:false },
    { name: "linda", email: "linda@example.com", role: "manager", id: 11 , isCheck:false},
    { name: "tom", email: "tom@example.com", role: "dev", id: 12, isCheck:false },
    { name: "jane", email: "jane@example.com", role: "designer", id: 13 , isCheck:false},
    { name: "mark", email: "mark@example.com", role: "dev", id: 14, isCheck:false },
  ],
};

function Step1Right() {
  const [addNewUserDialogOpen, setAddNewUserDialogOpen] = useState(false);
  const [dummyUser, setDummyUser] = useState({ members: [] });
  return (
    <StepRight>
      {addNewUserDialogOpen && (
        <UserDialog
          addNewUserDialogOpen={addNewUserDialogOpen}
          handleCloseUserDialog={() => {
            setAddNewUserDialogOpen(false);
            setDummyUser(dummyData);
          }}
        />
      )}
      <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
        Stakeholders List
      </Typography>

      <StakeholderList
        brmData={dummyData}
        setAddNewUserDialogOpen={setAddNewUserDialogOpen}
      />
    </StepRight>
  );
}

export default Step1Right;
