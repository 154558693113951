import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";

import { MainHeader } from "../../screen/vendorAdminScreen";
import TaskLeftPanel from "./TaskSurveyComps/TaskLeftPanel";
import TaskRightPanel from "./TaskSurveyComps/TaskRightPanel";
import DataRequest from "./DataRequestComps/DataRequest";
import { useSelector } from "react-redux";

const TaskSurveyMain = () => {
  const { selectedSection } = useSelector((state) => state.taskReducer);

  const location = useLocation();

  const isDataRequestRoute = location.pathname.includes("Data_Request");

  const [activeSurvey, setActiveSurvey] = useState(0);

  const [activeTab, setActiveTab] = useState(selectedSection[activeSurvey]);
  const [completedTab, setCompletedTab] = useState([]);

  useEffect(() => {
    const completedTabIds = [];

    selectedSection.forEach((section) => {
      // Check if isSurveyComplete is true
      if (section.isSurveyComplete) {
        completedTabIds.push(section._id);
      }
    });

    setCompletedTab(completedTabIds);
  }, [selectedSection]);

  // Effect to update activeSurvey when activeTab changes
  useEffect(() => {
    const index = selectedSection.findIndex(
      (section) => section._id === activeTab._id
    );
    if (index !== -1) {
      setActiveSurvey(index);
    }
  }, [activeTab, selectedSection]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{ gap: 30, padding: "12px 0px" }}
    >
      {/* Header with Breadcrumbs */}
      <MainHeader mainCrumb={"My Task"} />

      {isDataRequestRoute ? (
        <Box
          width={"100%"}
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <DataRequest />
        </Box>
      ) : (
        <Box
          display={"flex"}
          width={"98%"}
          height={"75vh"}
          sx={{ gap: 20, padding: "0px 20px" }}
        >
          {/* Left Panel */}
          <TaskLeftPanel
            selectedSection={selectedSection}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            completedTab={completedTab}
          />

          {/* Right Panel */}
          <TaskRightPanel
            selectedSection={selectedSection}
            activeSurvey={activeSurvey}
            setActiveSurvey={setActiveSurvey}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            completedTab={completedTab}
            setCompletedTab={setCompletedTab}
          />
        </Box>
      )}
    </Box>
  );
};
export default TaskSurveyMain;
