import {
  SET_SELECTED_QUESTION,
  SET_SELECTED_SECTION,
} from "../../../constants/taskConstants";

const initial_state = {
  completedSections: [],

  selectedSection: [],
  selectedQuestion: null,
};

export default function taskReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_SELECTED_SECTION:
      return {
        ...state,
        selectedSection: action.payload,
      };

    case SET_SELECTED_QUESTION:
      return {
        ...state,
        selectedQuestion: action.payload,
      };

    default:
      return state;
  }
}
