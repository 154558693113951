import { useHistory } from "react-router-dom";
import { Box, TableRow, TableCell } from "@material-ui/core";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";
import { IoDocumentText } from "react-icons/io5";

import DynamicTable, {
  statusStyle,
  textStyle,
  useTableStyles,
} from "../../UI/dynamicTable/DynamicTable";
import { taskData, taskHeadings } from "./taskTemp";

// -------- main render ---------
const TaskMain = () => {
  const classes = useTableStyles();

  const history = useHistory();

  return (
    <Box>
      <DynamicTable headings={taskHeadings}>
        {taskData.map((rowData, rowIndex) => (
          <TableRow
            key={rowIndex}
            className={classes.tableRowHover}
            onClick={() =>
              history.push(`/task/${rowData.bank_name.replace(/ /g, "_")}`)
            }
          >
            <TableCell style={textStyle("main")}>
              <Box display={"flex"} alignItems={"center"} sx={{ gap: 10 }}>
                <IoDocumentText color="#5C90C7" size={20} />
                {rowData.bank_name}
              </Box>
            </TableCell>
            <TableCell style={textStyle()}>{rowData.last_modified}</TableCell>
            <TableCell style={textStyle()}>{rowData.edited_by}</TableCell>
            <TableCell>
              <Box style={statusStyle(rowData.status.toLowerCase())}>
                <DotIcon style={{ widht: "10px", height: "10px" }} />
                {rowData.status}
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </DynamicTable>
    </Box>
  );
};
export default TaskMain;
