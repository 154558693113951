import {
  SET_LOGIN,
  SET_LOGOUT,
  SET_TOKEN,
  SET_USER_PROFILE,
  SET_SIGNUP_STEP_NO,
  SET_CHAT_TOKENS,
} from "./loginActionType";
import { set_loader, unset_loader } from "../loader/loader_action";
import encrypt from "../../actions/shared/sharedActions";

import UNIVERSAL from "../../../config/config";
import { set_snack_bar } from "../snackbar/snackbar_action";
import { setDashboardPage } from "../riskdashboard/riskDashboardActions";

// graphql imports
import { client } from "../../store/store";
import {
  LOGIN_WITH_EMAIL,
  LOGOUT,
  REFRESH_TOKEN,
  SIGNUP,
  VERIFY_OTP,
} from "../../graphql/gql_AuthQueries";

import { createHttpLink } from "@apollo/client";

export function setLogin(payload) {
  console.log(payload);
  return {
    type: SET_LOGIN,
    payload: payload,
  };
}
export function setSignupStepNo(payload) {
  console.log("payload", payload);
  localStorage.setItem("signup_process_no", payload);
  return {
    type: SET_SIGNUP_STEP_NO,
    payload: payload,
  };
}

export function setToken(payload) {
  return {
    type: SET_TOKEN,
    payload: payload,
  };
}

export function setLogOut() {
  return {
    type: SET_LOGOUT,
  };
}

export function setUserProfile(payload) {
  return {
    type: SET_USER_PROFILE,
    payload: payload,
  };
}

export function setChatTokens(payload) {
  return {
    type: SET_CHAT_TOKENS,
    payload: payload,
  };
}

// save login data to localstorage
export function storeLoginToLocalstorage(data) {
  localStorage.setItem("bank_id", data?.user?.bank?._id);
  localStorage.setItem("user_type", data?.user?.type);
  localStorage.setItem("userName", data?.user?.name);
  localStorage.setItem("is_premium", data?.user?.is_premium);
  data?.user?.profile_img &&
    localStorage.setItem(
      "userProfile",
      JSON.stringify(data?.user?.profile_img)
    );
  localStorage.setItem("organization_data", JSON.stringify(data?.user?.bank));
  localStorage.setItem(
    "organization_name",
    JSON.stringify(data?.user?.bank?.name)
  );
  localStorage.setItem("user_id", data?.user?._id);

  localStorage.setItem("userEmail", data?.user?.email);
  localStorage.setItem("signup_process_no", 4);
}

export function gql_refresh_token() {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      // refresh token logic
      const { data } = await client.query({
        query: REFRESH_TOKEN,
      });

      client.setLink(
        createHttpLink({
          uri: process.env.REACT_APP_GRAPHQL,
          headers: {
            Authorization: `Bearer ${data?.refreshTokens?.accessToken}`,
          },
        })
      );

      storeLoginToLocalstorage(data?.refreshTokens);

      dispatch(setLogin(data?.refreshTokens));
    } catch (error) {
      // handle refresh token error
      console.error("GraphQL: ", error.message);

      // logout user is error with refreshing token
      client.setLink(
        createHttpLink({
          uri: process.env.REACT_APP_GRAPHQL,
          headers: {}, // Empty headers object will remove the Authorization header
        })
      );

      localStorage.clear();
      dispatch(setLogOut());
    }
    dispatch(unset_loader());
  };
}

export function gql_login_with_email(email, password) {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      // login user call
      const { data } = await client.mutate({
        mutation: LOGIN_WITH_EMAIL,
        variables: { email, password },
      });

      dispatch(setSignupStepNo(4));
      // localStorage.setItem("user_token", data?.login?.accessToken);
      storeLoginToLocalstorage(data?.login);
      // localStorage.setItem(
      //   "isDisclaimer",
      //   responseJson.result?.isDisclaimer || false
      // );

      // setting default header for apollo client
      client.setLink(
        createHttpLink({
          uri: process.env.REACT_APP_GRAPHQL,
          headers: {
            Authorization: `Bearer ${data?.login?.accessToken}`,
          },
        })
      );

      dispatch(setLogin(data.login));

      dispatch(set_snack_bar(true, "Login successful"));
    } catch (error) {
      // gql apollo
      console.error("GraphQL: ", error.message);
      dispatch(set_snack_bar(false, error.message));
    }
    dispatch(unset_loader());
  };
}

export function gql_vendor_signup(
  name,
  email,
  organization_name,
  password,
  designation
) {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      // login user call
      const { data } = await client.mutate({
        mutation: SIGNUP,
        variables: { name, email, organization_name, password, designation },
      });

      dispatch(setSignupStepNo(2));

      localStorage.setItem("user_id", data?.signup?.userId);
      localStorage.setItem("email_id", email);

      dispatch(set_snack_bar(true, "Signup successful"));
    } catch (error) {
      // gql apollo
      console.error("GraphQL: ", error);
      dispatch(set_snack_bar(false, error.message));
    }
    dispatch(unset_loader());
  };
}

export function gql_verify_otp(id, strOtp) {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      const otp = parseInt(strOtp);

      // login user call
      const { data } = await client.mutate({
        mutation: VERIFY_OTP,
        variables: { id, otp },
      });

      dispatch(setSignupStepNo(4));

      // setting default header for apollo client
      client.setLink(
        createHttpLink({
          uri: process.env.REACT_APP_GRAPHQL,
          headers: {
            Authorization: `Bearer ${data?.checkOtp?.accessToken}`,
          },
        })
      );

      storeLoginToLocalstorage(data?.checkOtp);
      dispatch(setLogin(data.checkOtp));

      dispatch(set_snack_bar(true, "OTP Verified"));
    } catch (error) {
      // gql apollo
      console.error("GraphQL: ", error);
      dispatch(set_snack_bar(false, error.message));
    }
    dispatch(unset_loader());
  };
}

export function login_with_email(email, password) {
  const data = encrypt({
    email,
    password,
  });
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/users/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSignupStepNo(4));
          localStorage.setItem("user_token", responseJson.result?.user_token);
          localStorage.setItem("bank_id", responseJson.result?.bank_id);
          localStorage.setItem("user_type", responseJson.result?.user_type);
          localStorage.setItem("userName", responseJson.result?.name);
          localStorage.setItem("is_premium", responseJson.result?.is_premium);
          localStorage.setItem(
            "chat_tour_completed",
            responseJson.result?.chat_tour_completed
          );
          localStorage.setItem(
            "company_tour_completed",
            responseJson.result?.company_tour_completed
          );
          responseJson.result?.profile_img &&
            localStorage.setItem(
              "userProfile",
              JSON.stringify(responseJson.result?.profile_img)
            );
          localStorage.setItem(
            "organization_data",
            JSON.stringify(responseJson.result?.organization_data)
          );
          localStorage.setItem(
            "organization_name",
            JSON.stringify(responseJson.result?.organization_name)
          );
          localStorage.setItem("user_id", responseJson.result?._id);
          localStorage.setItem(
            "chat_tokens",
            responseJson.result?.chat_tokens || 0
          );

          localStorage.setItem("userEmail", email);
          localStorage.setItem("signup_process_no", 4);
          localStorage.setItem(
            "isDisclaimer",
            responseJson.result?.isDisclaimer || false
          );

          // dispatch(setChatTokens(responseJson.result?.tokens));

          dispatch(setLogin(responseJson.result));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        if (responseJson.result) {
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function gpt_signup(
  name,
  email,
  password,
  organization_name,
  designation
) {
  const data = encrypt({
    name,
    email,
    password,
    organization_name,
    designation,
  });
  console.log(email, name, password, organization_name, designation);
  return (dispatch, getState) => {
    const { login } = getState();
    // console.log(state
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/users/add_gpt_user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          console.log(responseJson.status);
          dispatch(setSignupStepNo(2));
          localStorage.setItem("user_token", responseJson.result?.user_token);
          localStorage.setItem("bank_id", responseJson.result?.bank_id);
          localStorage.setItem("user_type", responseJson.result?.user_type);
          localStorage.setItem("userName", responseJson.result?.name);
          localStorage.setItem(
            "signup_process_no",
            responseJson.result?.signup_process_no
          );

          responseJson.result?.profile_img &&
            localStorage.setItem(
              "userProfile",
              JSON.stringify(responseJson.result?.profile_img)
            );
          localStorage.setItem(
            "organization_data",
            JSON.stringify(responseJson.result?.organization_data) || `{}`
          );
          localStorage.setItem("user_id", responseJson.result?.user_id);
          localStorage.setItem("userEmail", email);

          localStorage.setItem("chat_tokens", responseJson.result?.tokens);

          console.log("chec for signup data: ", responseJson.result);

          dispatch(setLogin(responseJson.result));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        if (responseJson.result) {
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export const verifyOtp = (token, otp) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      otp,
    });

    return fetch(UNIVERSAL.BASEURL + "/users/verify_otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSignupStepNo(3));
        }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};
export const resendOtp = (token) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
    });

    return fetch(UNIVERSAL.BASEURL + "/users/resend_otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // if (responseJson.status) {
        //   dispatch(setSignupStepNo(3));
        // }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};
export function postSignupProcessNo(token, signup_process_no) {
  const data = encrypt({
    "user-token": token,
    signup_process_no,
  });
  return (dispatch, getState) => {
    const { login } = getState();
    // console.log(state
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/users/set_signup_process_no", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          console.log(responseJson.status);
          // dispatch(setSignupStepNo(2));
          // localStorage.setItem("signup_process_no", 2);

          // dispatch(setLogin(responseJson.result));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        if (responseJson.result) {
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function logOut(token, isDisclaimer) {
  // const data = encrypt({
  //   "user-token": token,
  //   isDisclaimer,
  // });
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      // login user call
      await client.query({
        query: LOGOUT,
      });

      // Remove the Authorization header from the client
      client.setLink(
        createHttpLink({
          uri: process.env.REACT_APP_GRAPHQL,
          headers: {}, // Empty headers object will remove the Authorization header
        })
      );

      localStorage.clear();
      dispatch(setLogOut());
    } catch (error) {
      // gql apollo
      console.error("GraphQL: ", error);
      dispatch(set_snack_bar(false, error.message));
    }
    dispatch(unset_loader());
    // return fetch(UNIVERSAL.BASEURL + "/users/logout", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "content-type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     data: data,
    //   }),
    // })
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     if (responseJson.status) {
    //       localStorage.clear();
    //       dispatch(setLogOut());
    //       dispatch(setDashboardPage(1));
    //     }
    //     dispatch(unset_loader());
    //     dispatch(set_snack_bar(responseJson.status, responseJson.message));
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };
}

export const forgetPassword = (email, domain_name) => {
  return (dispatch) => {
    dispatch(set_loader());

    let url = window.origin;
    let domain_name = window.location.hostname;
    const data = encrypt({
      email: email,
      domain_name,
      url,
    });
    return fetch(UNIVERSAL.BASEURL + "/users/forgot_password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          //
        }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const resetForgetPassword = (password, reset_code) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      password,
      reset_code,
    });

    return fetch(UNIVERSAL.BASEURL + "/users/reset_forgot_password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          //
        }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};
