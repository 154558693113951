import UNIVERSAL from "../../../config";
import {
  SET_SELECTED_MENU,
  SET_USER_IMAGE,
  SET_USER_NAME,
  SET_USER_POSITION,
  SET_USER_CONTACT,
  SET_USER_DISTRICT,
  SET_USER_LOCATION,
  SET_USER_PINCODE,
  SET_USER_STATE,
  SET_COMPANY_INFO,
  SET_SECTORS,
  UNSET_ADDRESS,
  SET_BRANCH_ADDRESS,
  PUSH_BRANCH_ADDRESS,
  SET_ALL_USERS,
  SET_MASTER_SECTOR,
  SET_SELECTED_SECTORS,
  SET_SUBSCRIPTION_DETAILS,
  SET_INVOICE_DETAILS,
  SET_PLAN_AMOUNT,
} from "../../../constants/generalSettings";
import encrypt from "../shared/sharedActions";
import { set_snack_bar } from "../../actions/snackbar/snackbar_action";
import { set_loader, unset_loader } from "../../actions/loader/loader_action";

// import {
//   setIsSubscriptionActive,
//   setName,
//   setUserProfile,
// } from "../../actions/login/loginActions";
// import { getOrganizations } from "../../actions/surveyListing/surveyListingAction";
// import { SET_ALL_STEP_5_USERS } from "../../constants/fielddata/fielddataConst";


export function setSelectedMenu(payload) {
  return {
    type: SET_SELECTED_MENU,
    payload: payload,
  };
}

export function setUserName(payload) {
  return {
    type: SET_USER_NAME,
    payload: payload,
  };
}

export function setSelectedSectors(payload) {
  return {
    type: SET_SELECTED_SECTORS,
    payload,
  };
}

export function setUserPosition(payload) {
  return {
    type: SET_USER_POSITION,
    payload: payload,
  };
}

export function setUserImage(payload) {
  return {
    type: SET_USER_IMAGE,
    payload: payload,
  };
}

export function setUserContact(payload) {
  return {
    type: SET_USER_CONTACT,
    payload: payload,
  };
}

export function setUserDistrict(payload) {
  return {
    type: SET_USER_DISTRICT,
    payload: payload,
  };
}

export function setUserLocation(payload) {
  return {
    type: SET_USER_LOCATION,
    payload: payload,
  };
}

export function setUserState(payload) {
  return {
    type: SET_USER_STATE,
    payload: payload,
  };
}

export function setUserPincode(payload) {
  return {
    type: SET_USER_PINCODE,
    payload: payload,
  };
}

export function setCompanyInfo(payload) {
  return {
    type: SET_COMPANY_INFO,
    payload: payload,
  };
}

export function setSectors(payload) {
  return {
    type: SET_SECTORS,
    payload: payload,
  };
}

export function setBranchAddress(payload) {
  return {
    type: SET_BRANCH_ADDRESS,
    payload: payload,
  };
}

export function unsetAddress(payload) {
  return {
    type: UNSET_ADDRESS,
    payload: payload,
  };
}

export function pushBranchAddress(payload) {
  return {
    type: PUSH_BRANCH_ADDRESS,
    payload: payload,
  };
}

export function setAllUsers(payload) {
  return {
    type: SET_ALL_USERS,
    payload: payload,
  };
}

export function viewUserProfile(token) {
  const data = encrypt({
    "user-token": token,
  });
  return (dispatch) => {
    dispatch(set_loader());
    fetch(UNIVERSAL.BASEURL + "/userDetails/view_profile_details", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader());
          dispatch(setUserName(responseJson.result.name));
          dispatch(setUserPosition(responseJson.result.designation));
          dispatch(setUserImage(responseJson.result.profile_image));
          dispatch(setUserContact(responseJson.result.contact_info.contact_no));
          dispatch(setUserDistrict(responseJson.result.contact_info.district));
          dispatch(setUserLocation(responseJson.result.contact_info.location));
          dispatch(setUserState(responseJson.result.contact_info.state));
          dispatch(setUserPincode(responseJson.result.contact_info.pincode));
        }
      })
      .catch((error) => {
        dispatch(unset_loader());
        console.error(error);
      });
  };
}

// export function upodateUserProfile(
//   token,
//   name,
//   position,
//   contact_info,
//   profile_image
// ) {
//   var formData = new FormData();
//   const data = encrypt({
//     "user-token": token,
//     name: name,
//     position: position,
//     contact_info: contact_info,
//     profile_image: profile_image,
//   });
//   formData.append("data", data);
//   formData.append("profile_image", profile_image);
//   return (dispatch) => {
//     dispatch(set_loader());
//     fetch(UNIVERSAL.BASEURL + "/userDetails/update_profile_details", {
//       method: "POST",
//       body: formData,
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         if (responseJson.status) {
//           dispatch(setName(name));
//           dispatch(setUserProfile(responseJson.result));

//           localStorage.setItem("userName", name);
//           localStorage.setItem(
//             "userProfile",
//             JSON.stringify(responseJson.result)
//           );
//         }
//         dispatch(unset_loader());
//         dispatch(set_snack_bar(true, responseJson.message));
//       })
//       .catch((error) => {
//         dispatch(unset_loader());
//         console.error(error);
//       });
//   };
// }

export function resetUserPassword(token, current_password, new_password) {
  const data = encrypt({
    "user-token": token,
    current_password: current_password,
    new_password: new_password,
  });
  return (dispatch) => {
    dispatch(set_loader());
    fetch(UNIVERSAL.BASEURL + "/userDetails/reset_password", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(unset_loader());
        dispatch(set_snack_bar(true, responseJson.message));
      })
      .catch((error) => {
        dispatch(unset_loader());
        console.error(error);
      });
  };
}

export const setMasterSector = (payload) => {
  payload.map((sector, index) => {
    return sector.industry_data.map((industry, index2) => {
      return (payload[index].industry_data[index2].isSelected = false);
    });
  });

  return {
    type: SET_MASTER_SECTOR,
    payload: payload,
  };
};

export function viewCompanyInfo(token, organisation_id) {
  const data = encrypt({
    "user-token": token,
    organisation_id: organisation_id,
  });
  return (dispatch) => {
    dispatch(set_loader());
    fetch(UNIVERSAL.BASEURL + "/businessUnit/view_company_details", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(unset_loader());
          dispatch(setCompanyInfo(responseJson.result.company_details));
          dispatch(setMasterSector(responseJson.result.sector_data));
          dispatch(
            setSelectedSectors(responseJson.result.company_details.sector)
          );
          dispatch(setSectors(responseJson.result.sector_data));
          dispatch(
            setBranchAddress(responseJson.result.company_details.branch_office)
          );
        }
      })
      .catch((error) => {
        dispatch(unset_loader());
        console.error(error);
      });
  };
}



//common action for removing the files

export const removeFile = (
  token,
  assessment_id,
  step_no,
  url,
  field_name,
  assessment_topic_id,
  activity_id,
  kpi_id,
  metric_id
) => {
  const data = encrypt({
    "user-token": token,
    assessment_id,
    step_no,
    url,
    field_name,
    assessment_topic_id: assessment_topic_id,
    activity_id: activity_id,
    kpi_id: kpi_id,
    metric_id: metric_id,
  });
  return (dispatch) => {
    fetch(UNIVERSAL.BASEURL + "/remove_file/remove_file", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
        }
      })
      .catch((err) => console.log(err));
  };
};

export const removeSettingsFile = (token, url, field_name, company_id) => {
  const data = encrypt({
    "user-token": token,
    url,
    field_name,
    company_id,
  });
  return (dispatch) => {
    fetch(UNIVERSAL.BASEURL + "/remove_file/remove_file_from_settings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((err) => console.log(err));
  };
};

// export const upgradePlan = (
//   token,
//   organisation_id,
//   name,
//   shared_by_user_img
// ) => {
//   const data = encrypt({
//     "user-token": token,
//     organisation_id,
//   });

//   return (dispatch) => {
//     dispatch(set_loader());
//     fetch(UNIVERSAL.BASEURL + "/razorpay/create_subsciption", {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "content-type": "application/json",
//       },
//       body: JSON.stringify({
//         data,
//       }),
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         if (responseJson.status) {
//           console.log('res',responseJson)
//           const options = {
//             key: "rzp_test_ok9Z4MvOECP4YV",
//             subscription_id: responseJson.result,
//             name: name,
//             description: "Monthly Test Plan",
//             image: shared_by_user_img,
//             // callback_url:`${UNIVERSAL.BASEURL + '/razorpay/verify'}`,
//             // redirect: false,
//             handler: (response) => {
//               const {
//                 razorpay_payment_id,
//                 razorpay_subscription_id,
//                 razorpay_signature,
//               } = response;
//               dispatch(
//                 verifySubscription(
//                   razorpay_payment_id,
//                   razorpay_subscription_id,
//                   razorpay_signature,
//                   token,
//                   organisation_id,
//                   shared_by_user_img
//                 )
//               );
//             },
//             //     handler: async function (response) {
//             //     console.log('1',response.razorpay_payment_id);
//             //     console.log('2',response.razorpay_subscription_id);
//             //     console.log('3',response.razorpay_signature);
//             //     const body =
//             //       response.razorpay_payment_id +
//             //       "|" +
//             //       response.razorpay_subscription_id;
//             //     const generated_signature = await crypto
//             //       .createHmac("sha256", "rzp_test_ok9Z4MvOECP4YV")
//             //       .update(body.toString())
//             //       .digest("hex");
//             //     // const generated_signature = await crypto.hmac_sha256( response.razorpay_payment_id+ "|" + response.razorpay_subscription_id, "rzp_test_ok9Z4MvOECP4YV");
//             //      console.log('generated_signature',generated_signature)
//             //     if (generated_signature == response.razorpay_signature) {
//             //       console.log('payment');
//             //     }else{
//             //       console.log('failed')
//             //     }
//             //   },
//             // prefill: {
//             //   name: "Philimon Nag",
//             //   email: "Philimon@appstone.in",
//             //   contact: "+919741831711",
//             // },
//             // "notes": {
//             //   "note_key_1": "Tea. Earl Grey. Hot",
//             //   "note_key_2": "Make it so."
//             // },
//             theme: {
//               color: "#3374b9",
//             },
//           };
//           // const rapay = window.Razorpay(options);
//           const rapay = window.Razorpay(options);
//           console.log('rapay',rapay)
//           rapay.open();
//           // dispatch(set_snack_bar(responseJson.status, responseJson.message));
//         }
//         dispatch(unset_loader());
//       })
//       .catch((err) => console.log(err));
//   };
// };



export const setSubscriptionDetails = (payload) => ({
  type: SET_SUBSCRIPTION_DETAILS,
  payload,
});
export const setInvoiceDetails = (payload) => ({
  type: SET_INVOICE_DETAILS,
  payload,
});


export const verifySubscription = (
  razorpay_payment_id,
  razorpay_subscription_id,
  razorpay_signature,
  token,
  organisation_id
) => {
  const url = window.location.origin;
  const domain_name = window.location.hostname;

  const data = encrypt({
    "user-token": token,
    razorpay_payment_id,
    razorpay_subscription_id,
    razorpay_signature,
    url,
    domain_name,
    organisation_id,
  });

  return (dispatch) => {
    dispatch(set_loader());
    fetch(UNIVERSAL.BASEURL + "/razorpay/verify", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          localStorage.setItem("status", "ACTIVE");
          localStorage.setItem("location", window.location.pathname);

          dispatch(setSubscriptionDetails(data));
          // dispatch(getInvoice(token, organisation_id));
        }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
        dispatch(unset_loader());
      })
      .catch((err) => console.log(err));
  };
};

export const getInvoice = (token, organization_id ,payment_status ) => {
  const data = encrypt({
    "user-token": token,
    organisation_id: organization_id,payment_status 
  });
  return (dispatch) => {
    dispatch(set_loader());
    fetch(UNIVERSAL.BASEURL + "/razorpay/view_invoices", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        dispatch(setInvoiceDetails(responseJson.result));
        // dispatch(
        //   setIsSubscriptionActive(
        //     responseJson.result?.payment_status === "EXPIRED" ? false : true
        //   )
        // : responseJson.result[0]?.paid
        // );
        dispatch(unset_loader());
        // async () => {
        // const browser = await puppeteer.launch();
        // const page = await browser.newPage();
        // await page.goto(url);
        // await page.pdf({ path: "doc.pdf" });
        // await page.screenshot({ path: "image.png" });
        // await browser.close();
        // }
        // dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((err) => console.log(err)).finally(()=>{
        dispatch(unset_loader());
      })
  };
};

const setPlanAmount = (payload) => ({ type: SET_PLAN_AMOUNT, payload });

export const getPlans = (token, organization_id ,payment_status) => {
  const data = encrypt({
    "user-token": token,
    organisation_id: organization_id,payment_status
  });
  return (dispatch) => {
    dispatch(set_loader());
    fetch(UNIVERSAL.BASEURL + "/razorpay/get_payment_amount", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status)
          dispatch(setPlanAmount(responseJson?.payment_amount));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};
