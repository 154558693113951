import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React from "react";

const admin = [
  {
    id: 1,
    name: "saad",
    email: "saad@example.com",
    role: "Admin",
    isCheck: false,
  },
];

function UserDetailsWithRole({ role, userList = [] }) {
  return (
    <Box style={{ display: "flex",  gap: 30 }}>
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 500,
          display: "flex",
          alignItems: "center",
          width: "20%",
        }}
      >
        {role}
      </Typography>
      <List
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "80%",
          justifyContent: "space-between",
        }}
      >
        {userList.map((user) => (
          <ListItem
            key={user?.id}
            role={undefined}
            dense={true}
            button
            //   selected={value?.isCheck}
            style={{
              paddingTop: "0px",
              paddingBottom: "0px",
              flex: 1,
            }}
          >
            <ListItemAvatar>
              <Avatar
                alt={`Avatar n°${1}`}
                style={{ backgroundColor: "blueViolet" }}
                // src={`/static/images/avatar/${value + 1}.jpg`}
              >
                NS
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              // id={labelId}
              primary={user?.name}
              secondary={user?.email}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

function ExploreQuestion({ approver, contributer }) {
  return (
    <>
      <UserDetailsWithRole role={"Admin"} userList={admin} />
      <UserDetailsWithRole role={"Approver"} userList={approver} />
      <UserDetailsWithRole role={"Contributer"} userList={contributer} />
    </>
  );
}

export default ExploreQuestion;
