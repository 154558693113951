import React from "react";
import {
  Box,
  TableRow,
  TableCell,
  IconButton,
  Icon,
  Typography,
  Collapse,
} from "@material-ui/core";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";
import AddIcon from "@material-ui/icons/Add";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import DynamicTable from "../../../../../UI/dynamicTable/DynamicTable";
import CustomButton from "../../../../../UI/button/button";

const textStyle = (type) => {
  let width = "2em";
  if (type === "main") {
    width = "50em";
  }

  return {
    color: "#15314E",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    width: width,
    borderBottom: "none",
    padding: "10px",
  };
};
const headings = [
  { name: "Que.No", showSort: false },
  { name: "Questions", showSort: false },
  { name: "Status", showSort: false },
  { name: "View", showSort: false },
];
const data = [
  {
    _id: "65dc3ba9b0dc39a9437050aa1",
    bank_name: "AcquiBank Patners",
    no_of_surveys: 1,
    contact_person: "Shanya Natrajan",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa2",
    bank_name: "FlowSource",
    no_of_surveys: 2,
    contact_person: "Another User",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa3",
    bank_name: "Other Bank",
    no_of_surveys: 3,
    contact_person: "More User",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa3",
    bank_name: "Other Bank",
    no_of_surveys: 3,
    contact_person: "More User",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa3",
    bank_name: "Other Bank",
    no_of_surveys: 3,
    contact_person: "More User",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa3",
    bank_name: "Other Bank",
    no_of_surveys: 3,
    contact_person: "More User",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa3",
    bank_name: "Other Bank",
    no_of_surveys: 3,
    contact_person: "More User",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa3",
    bank_name: "Other Bank",
    no_of_surveys: 3,
    contact_person: "More User",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa1",
    bank_name: "AcquiBank Patners",
    no_of_surveys: 1,
    contact_person: "Shanya Natrajan",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa2",
    bank_name: "FlowSource",
    no_of_surveys: 2,
    contact_person: "Another User",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa1",
    bank_name: "AcquiBank Patners",
    no_of_surveys: 1,
    contact_person: "Shanya Natrajan",
    status: "Approved",
  },
  {
    _id: "65dc3ba9b0dc39a9437050aa2",
    bank_name: "FlowSource",
    no_of_surveys: 2,
    contact_person: "Another User",
    status: "Approved",
  },
];

const options = ["Approved", "In Progress", "Completed"];

const statusStyle = (status) => {
  let colorToUse = "#8C7038";
  let bgColorToUse = "#FFEFD0";

  if (status === "complete") {
    colorToUse = "#5BB37F";
    bgColorToUse = "#EBF6EF";
  } else if (status === "notassign") {
    colorToUse = "black";
    bgColorToUse = "lightGrey";
  }

  return {
    width: "max-content",
    minWidth: "9em",
    height: "32px",
    borderRadius: 20,
    textTransform: "capitalize",
    // padding: "8px 12px 8px 12px",
    display: "flex",
    alignItems: "center",
    color: colorToUse,
    backgroundColor: bgColorToUse,
    gap: "4px",
    borderBottom: "none",
  };
};
function AuditQuestionTable({ handleExploreQuestion, sectionQuestions }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);

  // Initialize statuses for each row
  const initialStatuses = new Array(sectionQuestions.length).fill(0); // initial value {0, 1, 2}
  const [statuses, setStatuses] = React.useState(initialStatuses);

  // Initialize open state for each row
  const initialOpenStates = new Array(sectionQuestions.length).fill(false);
  const [openStates, setOpenStates] = React.useState(initialOpenStates);

  const handleClick = (event, rowIndex) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowIndex);
  };

  const handleMenuItemClick = (index) => {
    const newStatuses = [...statuses];
    newStatuses[selectedRow] = index;
    setStatuses(newStatuses);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const colorObj = [
    { color: "#154328", backgroundColor: "#BFE2CD" },
    { color: "#15314E", backgroundColor: "#EBF1F8" },
    { color: "#154328", backgroundColor: "#BFE2CD" },
  ];

  return (
    <DynamicTable headings={headings}>
      {sectionQuestions.map((rowData, rowIndex) => (
        <React.Fragment key={rowData?._id}>
          <TableRow>
            <TableCell align="center" style={textStyle()}>
              {rowIndex + 1}
            </TableCell>
            <TableCell style={textStyle("main")}>{rowData.question}</TableCell>
            <TableCell style={{ border: "none", padding: "0px", width: "9em" }}>
              <CustomButton
                variant="contained"
                size="small"
                color="secondary"
                endIcon={<Icon>expand_more</Icon>}
                style={{
                  textTransform: "capitalize",
                  ...colorObj[statuses[rowIndex]],
                }}
                onClick={(event) => handleClick(event, rowIndex)}
              >
                {options[statuses[rowIndex]]}
              </CustomButton>
              <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                keepMounted
                open={selectedRow === rowIndex && Boolean(anchorEl)}
                onClose={handleClose}
                elevation={1}
              >
                {options.map((option, index) => (
                  <MenuItem
                    key={option}
                    selected={statuses[rowIndex] === index}
                    onClick={() => handleMenuItemClick(index)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </TableCell>
            <TableCell
              align="center"
              style={{ border: "none", padding: "0px" }}
            >
              <IconButton
                size="small"
                onClick={() => {
                  const newOpenStates = [...openStates];
                  newOpenStates[rowIndex] = !newOpenStates[rowIndex];
                  setOpenStates(newOpenStates);
                  setSelectedRow(rowData?._id);
                }}
              >
                <AddIcon fontSize="small" style={{ color: "#3374B9" }} />
              </IconButton>
            </TableCell>
          </TableRow>
          {rowData?._id === selectedRow && (
            <TableRow style={{ backgroundColor: "#F6F6F6" }}>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0, border: "none" }}
                colSpan={6}
              >
                <Collapse
                  in={openStates[rowIndex]}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box
                    elevation={1}
                    margin={1}
                    style={{
                      border: "1px solid #E9E9E9",
                      backgroundColor: "#FEFEFE",
                      borderRadius: "8px",
                      padding: "10px",
                      height: "70px",
                    }}
                  >
                    <Typography
                      style={{ fontSize: "14px", fontWeight: 400 }}
                      gutterBottom
                      component="div"
                    >
                      NexaGen Innovations
                    </Typography>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          )}
        </React.Fragment>
      ))}
    </DynamicTable>
  );
}

export default AuditQuestionTable;
