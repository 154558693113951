import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  TableRow,
  TableCell,
  makeStyles,
  Typography,
  Avatar,
} from "@material-ui/core";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";

import DynamicTable from "../../../../UI/dynamicTable/DynamicTable";
import {
  assignedHeadings,
  completedData,
} from "../../../../screen/vendorAdminScreen/tempData";

// -------- stylings -----------
const textStyle = (type) => {
  let weight = 400;
  if (type === "main") {
    weight = 500;
  }

  return {
    color: "#15314E",
    fontWeight: weight,
    fontSize: "14px",
    lineHeight: "20px",
  };
};

const statusStyle = (status) => {
  let colorToUse = "#8C7038";
  let bgColorToUse = "#FFEFD0";

  if (status === "complete") {
    colorToUse = "#5BB37F";
    bgColorToUse = "#EBF6EF";
  }

  return {
    width: "max-content",
    minWidth: "9em",
    height: "32px",
    borderRadius: 20,
    textTransform: "capitalize",
    padding: "8px 12px 8px 12px",
    display: "flex",
    alignItems: "center",
    color: colorToUse,
    backgroundColor: bgColorToUse,
    gap: "4px",
  };
};

// Hover styles for TableRow
const useStyles = makeStyles({
  tableRowHover: {
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#EBF1F8",
      cursor: "pointer",
    },
  },
});

// ------------------------------

// -------- main render ---------
const CompletedTab = () => {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();

  return (
    <Box p={2}>
      {/* Table */}
      <DynamicTable headings={assignedHeadings}>
        {completedData.map((rowData, rowIndex) => (
          <>
            <TableRow>
              <TableCell style={{ border: "none" }} colSpan={6}>
                {rowData?.hasTitle && (
                  <Typography
                    style={{
                      color: "#15314E",
                      fontSize: "16px",
                      fontWeight: 600,
                    }}
                  >
                    {rowIndex === 0 && "January'23"}
                    {rowIndex === 1 && "November'23"}
                    {rowIndex === 2 && "October'23"}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow
              key={rowIndex}
              className={classes.tableRowHover}
              onClick={() =>
                history.push(
                  `${location.pathname}/${rowData.task_type.replace(/ /g, "_")}/completed`
                )
              }
            >
              <TableCell style={textStyle("main")}>
                {rowData.task_type}
              </TableCell>
              <TableCell style={textStyle()}>
                {rowData.survey_frequency}
              </TableCell>
              <TableCell style={textStyle()}>
                {rowData.action_required}
              </TableCell>
              <TableCell>
                <Box style={statusStyle(rowData.status.toLowerCase())}>
                  <DotIcon style={{ widht: "10px", height: "10px" }} />
                  {rowData.status}
                </Box>
              </TableCell>
              <TableCell style={textStyle()}>{rowData.sent_on}</TableCell>
              <TableCell style={textStyle()}>
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  sx={{ gap: 10 }}
                >
                  <Avatar
                    src={rowData.assigned_by.avatar || rowData.assigned_by.name}
                    alt={rowData.assigned_by.name}
                  />
                  <Box display={"flex"} flexDirection={"column"}>
                    <Typography style={textStyle("main")}>
                      {rowData.assigned_by.name}
                    </Typography>
                    <Typography
                      style={{
                        color: "#AFAFAF",
                        fontWeight: 500,
                        fontSize: "11px",
                        lineHeight: "16px",
                      }}
                    >
                      {rowData.assigned_by.designation}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          </>
        ))}
      </DynamicTable>
    </Box>
  );
};
export default CompletedTab;
