import React from "react";
import StepLayOut from "../stepLayout";
import Step4Left from "./left/Step4Left";
import Step4Right from "./right/Step4Right";

function SurveyStep4({response}) {
  return <StepLayOut left={<Step4Left />} right={<Step4Right response={response} />} />;
}

export default SurveyStep4;
