import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { sectionWiseData as defaultData } from "../components/widgets/riskSurveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionData";
import {
  getQuestion, setQuestions,
} from "../redux/actions/riskdashboard/riskDashboardActions";

const useInfiniteScroll = (scrollDivRef) => {
  const dispatch = useDispatch();
  const {
    sectionWiseData,
    selectedSection,
    sectionWiseQuestion,
    hasMoreData,
    offset,
  } = useSelector((state) => state.riskDashboard);
  const login = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  // const [offset, setOffset] = useState(0);
console.log(selectedSection);

  useEffect(() => {


    const fetchData = () => {
      if (selectedSection !== "section" && sectionWiseData?.length > 0) {

        dispatch(
          setQuestions(defaultData[selectedSection])
        );


      }
    };

    fetchData();


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSection, sectionWiseData?.length]);

  const resetHookState = () => {
    dispatch({ type: "SET_OFFSET", payload: 0 });
    dispatch(setQuestions([]));
    dispatch({ type: "HAS_MORE_DATA", payload: true });
  }

  return { loading, resetHookState };
};

export default useInfiniteScroll;