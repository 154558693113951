import React, { useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import SurveyStepper from "../../stepHeader/SurveyStepper";
import { Box, IconButton, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import CustomButton from "../../../../../UI/button/button";
import DrawerStep1 from "./DrawerStep1";
import DrawerStep2 from "./DrawerStep2";

const steps = ["Select Section", "Select Stakeholder"];

// const listData = [
//   { id: 1, name: "Topic A", isCheck: false },
//   { id: 2, name: "Subject B", isCheck: false },
//   { id: 3, name: "Issue C", isCheck: false },
//   { id: 4, name: "Category D", isCheck: false },
//   { id: 5, name: "Theme E", isCheck: false },
//   { id: 6, name: "Aspect F", isCheck: false },
//   { id: 7, name: "Matter G", isCheck: false },
//   { id: 8, name: "Subject H", isCheck: false },
//   { id: 9, name: "Point I", isCheck: false },
//   { id: 10, name: "Controversy J", isCheck: false },
//   { id: 11, name: "Debate K", isCheck: false },
//   { id: 12, name: "Discussion L", isCheck: false },
//   { id: 13, name: "Dispute M", isCheck: false },
//   { id: 14, name: "Argument N", isCheck: false },
//   { id: 15, name: "Contention O", isCheck: false },
// ];

export default function AssignDrawer(props) {
  const {
    isDrawerOpen,
    handleCloseDrawer,
    contributors,
    approvers,
    handleContributers,
    handleApprovers,
    handleDeleteApprovers,
    handleDeleteContributers,
    toggleSingleItemUser,
    handleReset,
    users,
    surveyList,
  } = props;

  const [list, setList] = useState([]);


  useEffect(() => {
    const updated = surveyList.map((survey, index) => ({
      id: index + 1,
      name: survey,
      isCheck: false,
    }));
    setList(updated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const toggleSingleItemList = (itemId) => {
    const updatedList = list.map((item) => {
      return item.id === itemId ? { ...item, isCheck: !item.isCheck } : item;
    });

    setList(updatedList);
  };

  const toggleAllItems = (value) => {
    const updatedList = list.map((item) => ({
      ...item,
      isCheck: value,
    }));
    setList(updatedList);
  };

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor={"right"}
          open={isDrawerOpen}
          onClose={handleCloseDrawer}
        >
          <Box
            style={{
              padding: "10px",
              width: "70vw",
              height: "100%",
            }}
          >
            {/* header */}
            <Box
              display={"flex"}
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                // padding: "5px",
              }}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  width: "20%",
                }}
              >
                Assign Section
              </Typography>
              <Box style={{ width: "50%" }}>
                <SurveyStepper steps={steps} activeStep={1} />
              </Box>
              <IconButton onClick={handleCloseDrawer}>
                <ClearIcon
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#3374B9",
                  }}
                />
              </IconButton>
            </Box>

            {/* instruction */}
            <Typography
              style={{
                fontSize: "12px",
                fontWeight: 400,
                // width: "20%",
                color: "#505050",
              }}
            >
              Empower your survey experience by selecting specific sections
              tailored to your interests, ensuring a focused and relevant
              exploration of environmental, social, and governance aspects.
            </Typography>

            {/* table */}
            {activeStep === 0 && (
              <DrawerStep1
                listData={list}
                handleToggle={toggleSingleItemList}
              />
            )}
            {activeStep === 1 && (
              <DrawerStep2
                listData={users}
                handleToggle={toggleSingleItemUser}
                handleApprovers={handleApprovers}
                handleContributers={handleContributers}
                contributors={contributors}
                approvers={approvers}
                handleDeleteApprovers={handleDeleteApprovers}
                handleDeleteContributers={handleDeleteContributers}
              />
            )}

            {/* button */}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                position: "absolute",
                bottom: 5,
                right: 15,
                gap: 10,
                // width: "45%",
              }}
            >
              <CustomButton
                variant="text"
                color="primary"
                onClick={() => {
                  if (activeStep === 0) {
                    toggleAllItems(false);
                  } else {
                    handleReset();
                  }
                }}
              >
                Clear Selection
              </CustomButton>
              {activeStep === 0 && (
                <CustomButton
                  variant="text"
                  color="primary"
                  onClick={() => toggleAllItems(true)}
                >
                  Select All
                </CustomButton>
              )}
              <CustomButton
                variant="contained"
                color="primary"
                onClick={activeStep === 0 ? handleNext : handleCloseDrawer}
              >
                Complete List
              </CustomButton>
            </Box>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
