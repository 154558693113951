// import encrypt from "../../../redux/actions/shared/sharedActions";
// import UNIVERSAL from "../../../config";
import { set_loader, unset_loader } from "../loader/loader_action";
import {
  SET_SELECTED_QUESTION,
  SET_SELECTED_SECTION,
} from "../../../constants/taskConstants";
import {
  selectSection1,
  selectSection2,
} from "../../../components/screen/taskScreen/taskTemp";

export const setSelectedQuestion = (payload) => {
  return {
    type: SET_SELECTED_QUESTION,
    payload,
  };
};

export const setSelectedSection = (payload) => {
  return {
    type: SET_SELECTED_SECTION,
    payload,
  };
};

// -------------------------------------------------------------------
export const SetSelectedSection = (_id, task_type) => {
  return (dispatch) => {
    dispatch(set_loader);

    if (_id === "65dc3ba9b0dc39a9437051aa3") {
      dispatch(setSelectedSection(selectSection1));
    } else if (_id === "65dc3ba9b0dc39a9437051aa4") {
      dispatch(setSelectedSection(selectSection2));
    }

    dispatch(unset_loader);
  };
};

export const UpdateQuestionOption = (ques_id, selectedOpt, survey_id) => {
  return (dispatch, getState) => {
    dispatch(set_loader);

    try {
      const selectedSection = getState().taskReducer.selectedSection;

      const surveyToUpdate = selectedSection.find(
        (survey) => survey._id === survey_id
      );

      if (surveyToUpdate) {
        const questionToUpdate = surveyToUpdate.questions.find(
          (question) => question._id === ques_id
        );

        if (questionToUpdate) {
          // Update the selectedOpt of the question
          questionToUpdate.selectedOpt = selectedOpt;

          // Dispatch action to update selectedSection
          dispatch(setSelectedSection(selectedSection));
        }
      }
    } catch (error) {
      console.log(error);
    }

    dispatch(unset_loader);
  };
};

export const UpdateSurveyComplete = (type, survey_id) => {
  return (dispatch, getState) => {
    dispatch(set_loader);

    try {
      const selectedSection = getState().taskReducer.selectedSection;

      const surveyToUpdate = selectedSection.find(
        (survey) => survey._id === survey_id
      );

      if (surveyToUpdate) {
        if (type === "add") {
          surveyToUpdate.isSurveyComplete = true;
        } else {
          surveyToUpdate.isSurveyComplete = false;
          surveyToUpdate.questions.forEach((question) => {
            question.selectedOpt = "";
          });
        }
        dispatch(setSelectedSection(selectedSection));
      }
    } catch (error) {
      console.log(error);
    }

    dispatch(unset_loader);
  };
};

export const UpdateSelectedQuestion = (ques_id, survey_id, type) => {
  return (dispatch, getState) => {
    dispatch(set_loader);

    try {
      if (type === "add") {
        const selectedSection = getState().taskReducer.selectedSection;

        const selectedSurvey = selectedSection.find(
          (survey) => survey._id === survey_id
        );

        if (selectedSurvey) {
          const selectedQuestion = selectedSurvey.questions.find(
            (question) => question._id === ques_id
          );

          if (selectedQuestion) {
            dispatch(setSelectedQuestion(selectedQuestion));
          }
        }
      } else {
        dispatch(setSelectedQuestion(null));
      }
    } catch (error) {
      console.log(error);
    }

    dispatch(unset_loader);
  };
};
