import { Box, Typography } from "@material-ui/core";
import { FaCheck } from "react-icons/fa6";

const TaskLeftPanel = ({
  selectedSection,
  activeTab,
  setActiveTab,
  completedTab,
}) => {
  const tabStyle = (isActive) => ({
    gap: 10,
    backgroundColor: isActive ? "#EBF1F8" : "transparent",
    borderRadius: "8px",
    padding: "16px",

    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  });

  const checkMark = (tabId) => {
    if (completedTab.includes(tabId)) {
      return true;
    }
  };

  return (
    <Box style={{ width: "261px", height: "75vh", overflowY: "auto" }}>
      {selectedSection.map((tab, index) => (
        <Box
          key={tab?._id}
          onClick={() => setActiveTab(tab)}
          style={tabStyle(activeTab?._id === tab?._id)}
        >
          <Box
            style={{
              backgroundColor: checkMark(tab?._id)
                ? "#32A05F"
                : activeTab?._id === tab?._id
                ? "#3374B9"
                : "#F6F6F6",
              borderRadius: "20px",
              minWidth: "24px",
              minHeight: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {completedTab.includes(tab?._id) ? (
              <FaCheck color="#EBF1F8" />
            ) : (
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: "11px",
                  lineHeight: "16px",
                  color: activeTab?._id === tab?._id ? "#EBF1F8" : "#6C6C6C",
                }}
              >
                {index + 1}
              </Typography>
            )}
          </Box>
          <Typography
            style={{
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "20px",
              color: activeTab === index ? "#15314E" : "#6C6C6C",
            }}
          >
            {tab?.section_name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default TaskLeftPanel;
