import { useHistory } from "react-router-dom";

import { Box, TableRow, TableCell, IconButton } from "@material-ui/core";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";

import GroupDoc from "../../../images/GroupDoc.png";
import DynamicTable, {
  statusStyle,
  textStyle,
  useTableStyles,
} from "../../UI/dynamicTable/DynamicTable";
import { data, headings } from "./tempData";

// -------- main render ---------
const MainTable = () => {
  const classes = useTableStyles();

  const history = useHistory();

  return (
    <Box p={2}>
      {/* Table */}
      <DynamicTable headings={headings}>
        {data.map((rowData, rowIndex) => (
          <TableRow
            key={rowIndex}
            className={classes.tableRowHover}
            onClick={() =>
              history.push(
                `/vendor_admin/${rowData.bank_name.replace(/ /g, "_")}`
              )
            }
          >
            <TableCell style={textStyle("main")}>{rowData.bank_name}</TableCell>
            <TableCell style={textStyle()}>{rowData.no_of_surveys}</TableCell>
            <TableCell style={textStyle()}>{rowData.contact_person}</TableCell>
            <TableCell>
              <Box style={statusStyle(rowData.status.toLowerCase())}>
                <DotIcon style={{ widht: "10px", height: "10px" }} />
                {rowData.status}
              </Box>
            </TableCell>
            <TableCell>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  console.log(data[rowIndex]);
                }}
              >
                <img src={GroupDoc} alt="DocIcon" />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </DynamicTable>
    </Box>
  );
};
export default MainTable;
