import React, { useEffect } from "react";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Box from "@material-ui/core/Box";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";

import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import RemoveIcon from "@material-ui/icons/Remove";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import {
  radioOption,
  QUESTION_TYPE_SCALE,
  QUESTION_TYPE_MULTICHOICE,
  QUESTION_TYPE_COMMENT_BOX,
  QUESTION_TYPE_LOCATION,
  QUESTION_TYPE_DOCUMENT_UPLOAD,
  QUESTION_TYPE_DATE_PICKER,
  SUB_QUESTION_0_10,
  SUB_QUESTION_LIKERT,
  SUB_QUESTION_SATISFACTION,
  SUB_QUESTION_QUALITY,
  SUB_QUESTION_FREQUENCY,
  SUB_QUESTION_PERFORMANCE,
  SUB_QUESTION_IMPORTANCE,
  SUB_QUESTION_EMOTIONS,
  DATE_PICKER_SINGLE_DATE,
  DATE_PICKER_RANGE_DATE,
} from "./QuestionData";
import CustomButton from "../../../../../../UI/button/button";
import { IconButton } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedOption } from "../../../../../../../redux/actions/corporateSurvey/corporateSurveyActions";
import RadioButton from "../../../../../../UI/radioButton/radioButton";
import { UpdateQuestionOption } from "../../../../../../../redux/actions/taskActions/TaskAction";

function QuestionBody({
  questionType,
  answers,
  checkBoxOption,
  setCheckBoxOption,
  submittedAnswer,
  responseAnswer,
  removeEditOption,
  questionId,
  activeTabId,
  isFrom,
}) {
  switch (questionType) {
    case QUESTION_TYPE_COMMENT_BOX:
      return (
        <CommentBox
          option={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          questionId={questionId}
          activeTabId={activeTabId}
          isFrom={isFrom}
        />
      );
    case QUESTION_TYPE_DOCUMENT_UPLOAD:
      return (
        <DocumentUpload
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          questionId={questionId}
          activeTabId={activeTabId}
        />
      );
    case QUESTION_TYPE_MULTICHOICE:
      return (
        <MultiChoice
          option={answers}
          checkBoxOption={checkBoxOption}
          setCheckBoxOption={setCheckBoxOption}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          removeEditOption={removeEditOption}
        />
      );
    case DATE_PICKER_SINGLE_DATE:
      return (
        <DatePicker
          option={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
        />
      );
    case DATE_PICKER_RANGE_DATE:
      return (
        <DatePicker
          hasRange={true}
          option={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
        />
      );
    case QUESTION_TYPE_SCALE:
    case SUB_QUESTION_0_10:
    case SUB_QUESTION_LIKERT:
    case SUB_QUESTION_SATISFACTION:
    case SUB_QUESTION_QUALITY:
    case SUB_QUESTION_FREQUENCY:
    case SUB_QUESTION_PERFORMANCE:
    case SUB_QUESTION_IMPORTANCE:
    case SUB_QUESTION_EMOTIONS:
      return (
        <Scale
          questionType={questionType}
          option={answers}
          submittedAnswer={submittedAnswer}
          responseAnswer={responseAnswer}
          questionId={questionId}
          activeTabId={activeTabId}
        />
      );
    // Add more cases as needed
    default:
      return <></>;
  }
}

const CommentBox = ({
  submittedAnswer,
  responseAnswer,
  questionId,
  activeTabId,
  isFrom,
}) => {
  const dispatch = useDispatch();

  const { selectedOption } = useSelector((state) => state?.corporateSurvey);

  const handleChange = (e) => {
    // dispatch(setSelectedOption(e.target.value));
    dispatch(UpdateQuestionOption(questionId, e.target.value, activeTabId));
    // setValue(parseInt(event?.weightage));
  };

  React.useEffect(() => {
    if (!responseAnswer) {
      if (submittedAnswer?.answer || submittedAnswer?.option) {
        dispatch(
          setSelectedOption(submittedAnswer?.option || submittedAnswer?.answer)
        );
      } else {
        dispatch(setSelectedOption(null));
      }
    } else {
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let handleValue = "";

  if (isFrom === "taskSection") {
    handleValue = submittedAnswer?.option || "";
  } else if (selectedOption) {
    handleValue = selectedOption;
  } else if (submittedAnswer?.answer) {
    handleValue = submittedAnswer?.answer;
  } else if (responseAnswer) {
    handleValue = responseAnswer;
  } else {
    handleValue = "";
  }

  return (
    <TextareaAutosize
      style={{ width: "100%" }}
      aria-label="minimum height"
      minRows={3}
      // placeholder="Comment"
      value={handleValue}
      onChange={(e) => handleChange(e)}
    />
  );
};

const DocumentUpload = ({
  submittedAnswer,
  responseAnswer,
  questionId,
  activeTabId,
}) => {
  const dispatch = useDispatch();

  const { selectedOption } = useSelector((state) => state?.corporateSurvey);

  React.useEffect(() => {
    if (!responseAnswer) {
      if (submittedAnswer) {
        dispatch(setSelectedOption(submittedAnswer?.answer));
      } else {
        dispatch(setSelectedOption(null));
      }
    } else {
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    dispatch(setSelectedOption(e.target.files[0]));
    dispatch(UpdateQuestionOption(questionId, e.target.files[0], activeTabId));
    // setValue(parseInt(event?.weightage));
  };

  const handleName = responseAnswer
    ? responseAnswer?.name
    : selectedOption?.name;
  return (
    <>
      {(selectedOption === "" || selectedOption === null) && !responseAnswer ? (
        <>
          <input
            name="actUpload"
            id="contained-button-activityFile"
            type="file"
            onChange={(event) => handleChange(event)}
            style={{ display: "none" }}
          />
          <label htmlFor="contained-button-activityFile">
            <Box
              display="flex"
              component="span"
              alignItems="center"
              style={{ cursor: "pointer" }}
            >
              <AttachFileIcon color="disabled" />
              <Box
                mx={2}
                style={{ borderBottom: "2px solid gray" }}
                width="100%"
              >
                <Typography variant="caption" color="textSecondary">
                  Upload Document
                </Typography>
              </Box>
            </Box>
          </label>
        </>
      ) : (
        <>
          <Box display="flex" alignItems="center">
            <AttachFileIcon color="disabled" />
            <Box
              mx={2}
              style={{ borderBottom: "2px solid #3374B9" }}
              width="100%"
            >
              <Typography variant="caption" color="primary">
                {handleName}
              </Typography>
            </Box>
            <ClearIcon
              color="primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                // removeFile(
                //     login.token,
                //     assessment_id,
                //     3,
                //     activityFile.url,
                //     "activity_file",
                //     esgPlan.currentTopic.assessment_topic_id,
                //     currentActivityID
                // );
                dispatch(setSelectedOption(null));
              }}
            />
          </Box>
        </>
      )}
    </>
  );
};

const MultiChoice = ({
  option,
  checkBoxOption,
  setCheckBoxOption,
  submittedAnswer,
  responseAnswer,
  removeEditOption,
}) => {
  const dispatch = useDispatch();

  const { selectedOption } = useSelector((state) => state?.corporateSurvey);

  const checkBoxOptionArray = [
    { name: "option 1", checked: false },
    { name: "option 2", checked: false },
  ];

  // use in add / update

  useEffect(() => {
    if (checkBoxOption?.length === 0 && !submittedAnswer && !responseAnswer) {
      setCheckBoxOption(option || checkBoxOptionArray);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // use in submit answer

  React.useEffect(() => {
    if (!responseAnswer) {
      if (submittedAnswer) {
        dispatch(setSelectedOption(submittedAnswer?.answer));
      } else {
        dispatch(setSelectedOption(null));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [checkBoxOption, setCheckBoxOption] =
  //   React.useState(checkBoxOptionArray);

  const [editingIndex, setEditingIndex] = React.useState(null);
  const [editedText, setEditedText] = React.useState("");

  const handleChange = (event) => {
    if (submittedAnswer) {
      dispatch(
        setSelectedOption(
          selectedOption.map((option) =>
            option.name === event.target.name
              ? { ...option, checked: event.target.checked }
              : option
          )
        )
      );
    } else {
      setCheckBoxOption((preValue) =>
        preValue.map((option) =>
          option.name === event.target.name
            ? { ...option, checked: event.target.checked }
            : option
        )
      );
      dispatch(
        setSelectedOption(
          checkBoxOption.map((option) =>
            option.name === event.target.name
              ? { ...option, checked: event.target.checked }
              : option
          )
        )
      );
    }
  };

  const handleAddOption = () => {
    setCheckBoxOption((preValue) => [
      ...preValue,
      { name: `option ${preValue.length + 1}`, checked: false },
    ]);
  };

  const handleDoubleClick = (index, currentLabel) => {
    setEditingIndex(index);
    setEditedText(currentLabel);
  };

  const handleLabelBlur = (index) => {
    setEditingIndex(null);

    const updatedOptions = checkBoxOption.map((option, i) => {
      if (i === index) {
        return { ...option, name: editedText };
      }
      return option;
    });

    setCheckBoxOption(updatedOptions);
  };

  const handleLabelChange = (event) => {
    setEditedText(event.target.value);
  };

  const handleOption = responseAnswer
    ? responseAnswer
    : submittedAnswer
    ? selectedOption || []
    : checkBoxOption;

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <FormControl component="fieldset">
        <FormGroup>
          {handleOption.map((option, index) => (
            <div key={index}>
              {editingIndex === index ? (
                <TextField
                  type="text"
                  value={editedText}
                  onChange={handleLabelChange}
                  onBlur={() => handleLabelBlur(index)}
                  autoFocus
                />
              ) : (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "60vw",
                  }}
                >
                  <FormControlLabel
                    control={
                      <>
                        <Checkbox
                          // disabled
                          size="small"
                          style={{ color: "#3374B9" }}
                          checked={option.checked}
                          onChange={(event) => handleChange(event, index)}
                          name={option.name}
                        />
                      </>
                    }
                    label={
                      <div style={{ wordBreak: "break-all" }}>
                        {option.name}
                      </div>
                    }
                    onDoubleClick={() =>
                      !submittedAnswer &&
                      !removeEditOption &&
                      handleDoubleClick(index, option.name)
                    }
                  />
                  {!submittedAnswer && !removeEditOption && (
                    <IconButton
                      onClick={() =>
                        setCheckBoxOption((preValue) =>
                          preValue.filter((option, i) => i !== index)
                        )
                      }
                    >
                      <RemoveIcon />
                    </IconButton>
                  )}
                </Box>
              )}
            </div>
          ))}
        </FormGroup>
        {!submittedAnswer && !removeEditOption && (
          <FormHelperText>Double Click to Edit Option</FormHelperText>
        )}
      </FormControl>
      {!submittedAnswer && !removeEditOption && (
        <CustomButton
          style={{ width: "15%" }}
          variant="outlined"
          color="primary"
          onClick={() => handleAddOption()}
        >
          add option
        </CustomButton>
      )}
    </Box>
  );
};

const Scale = ({
  questionType,
  option,
  submittedAnswer,
  responseAnswer,
  questionId,
  activeTabId,
}) => {
  const dispatch = useDispatch();
  const { selectedOption } = useSelector((state) => state?.corporateSurvey);
  // const [value, setValue] = React.useState(submittedAnswer?.answer?.weightage);

  const handleChange = (e, event) => {
    dispatch(setSelectedOption(event));
    dispatch(UpdateQuestionOption(questionId, event, activeTabId));
    // setValue(parseInt(event?.weightage));
  };

  React.useEffect(() => {
    if (!responseAnswer) {
      if (submittedAnswer || submittedAnswer?.option) {
        dispatch(
          setSelectedOption(submittedAnswer?.option || submittedAnswer?.answer)
        );
      } else {
        dispatch(setSelectedOption(null));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedAnswer]);

  const handleOption = option ? option : radioOption[questionType];
  let handleValue = "";

  if (selectedOption?.option) {
    handleValue = selectedOption?.option;
  } else if (submittedAnswer?.option) {
    handleValue = submittedAnswer?.option;
  } else if (submittedAnswer?.answer?.option) {
    handleValue = submittedAnswer?.answer?.option;
  } else if (selectedOption) {
    handleValue = selectedOption;
  } else if (submittedAnswer?.answer) {
    handleValue = submittedAnswer?.answer;
  } else if (responseAnswer?.option) {
    handleValue = responseAnswer?.option;
  } else if (responseAnswer) {
    handleValue = responseAnswer;
  } else {
    handleValue = "";
  }
  return (
    <FormControl component="fieldset">
      {/* <FormLabel component="legend">Gender</FormLabel> */}
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={handleValue}
        // onChange={handleChange}
      >
        {handleOption.map((o, i) => (
          <FormControlLabel
            // defaultValue={selectedOption?.option}
            key={i}
            value={o?.option || o}
            control={
              <RadioButton
                disableRipple
                disableFocusRipple
                size="small"
                style={{ border: "#505050", color: "#3374B9" }}
              />
            }
            label={o?.option || o}
            onClick={(e) => handleChange(e, o)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const DatePicker = ({ hasRange = false, submittedAnswer, responseAnswer }) => {
  const dispatch = useDispatch();

  const { selectedOption } = useSelector((state) => state?.corporateSurvey);

  const handleStartDateChange = (key, date) => {
    dispatch(
      setSelectedOption(
        selectedOption !== null
          ? { ...selectedOption, [key]: date }
          : { [key]: date }
      )
    );
  };

  React.useEffect(() => {
    if (!responseAnswer) {
      if (submittedAnswer) {
        dispatch(setSelectedOption(submittedAnswer?.answer));
      } else {
        dispatch(setSelectedOption(null));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      noValidate
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "25px",
      }}
    >
      <TextField
        id="start-date"
        label={hasRange ? "Start Date" : "Select Date"}
        type="date"
        //   className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        value={
          selectedOption?.startDate ||
          submittedAnswer?.answer?.startDate ||
          responseAnswer?.startDate
        }
        onChange={(e) => handleStartDateChange(e.target.name, e.target.value)}
        variant="outlined"
        color="primary"
        name="startDate"
      />
      {hasRange && (
        <TextField
          id="end-date"
          label="End Date"
          type="date"
          //   className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          value={
            selectedOption?.endDate ||
            submittedAnswer?.answer?.endDate ||
            responseAnswer?.endDate
          }
          onChange={(e) => handleStartDateChange(e.target.name, e.target.value)}
          variant="outlined"
          color="primary"
          name="endDate"
        />
      )}
    </form>
  );
};

export default QuestionBody;
