import React, { useState } from "react";
import StepLayOut from "../stepLayout";
import Step2Left from "./left/Step2Left";
import Step2Right from "./right/Step2Right";
import { dummyData } from "../step1/right/Step1Right";
// import { response } from "../../../riskSurveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionData";
// const userList = [
//   {
//     id: 1,
//     name: "John Doe",
//     email: "john@example.com",
//     role: "Admin",
//     isCheck: false,
//   },
//   {
//     id: 2,
//     name: "Alice Smith",
//     email: "alice@example.com",
//     role: "User",
//     isCheck: false,
//   },
//   {
//     id: 3,
//     name: "Bob Johnson",
//     email: "bob@example.com",
//     role: "User",
//     isCheck: false,
//   },
//   {
//     id: 4,
//     name: "Emma Wilson",
//     email: "emma@example.com",
//     role: "Admin",
//     isCheck: false,
//   },
//   {
//     id: 5,
//     name: "Michael Brown",
//     email: "michael@example.com",
//     role: "User",
//     isCheck: false,
//   },
//   {
//     id: 6,
//     name: "Sara Miller",
//     email: "sara@example.com",
//     role: "Admin",
//     isCheck: false,
//   },
//   {
//     id: 7,
//     name: "Peter Davis",
//     email: "peter@example.com",
//     role: "User",
//     isCheck: false,
//   },
//   {
//     id: 8,
//     name: "Linda Moore",
//     email: "linda@example.com",
//     role: "User",
//     isCheck: false,
//   },
//   {
//     id: 9,
//     name: "Tom Wilson",
//     email: "tom@example.com",
//     role: "Admin",
//     isCheck: false,
//   },
//   {
//     id: 10,
//     name: "Jane Johnson",
//     email: "jane@example.com",
//     role: "User",
//     isCheck: false,
//   },
//   {
//     id: 11,
//     name: "Mark Smith",
//     email: "mark@example.com",
//     role: "Admin",
//     isCheck: false,
//   },
//   {
//     id: 12,
//     name: "Eva Davis",
//     email: "eva@example.com",
//     role: "User",
//     isCheck: false,
//   },
//   {
//     id: 13,
//     name: "Chris Brown",
//     email: "chris@example.com",
//     role: "Admin",
//     isCheck: false,
//   },
//   {
//     id: 14,
//     name: "Sophie Miller",
//     email: "sophie@example.com",
//     role: "User",
//     isCheck: false,
//   },
//   {
//     id: 15,
//     name: "Alex Moore",
//     email: "alex@example.com",
//     role: "Admin",
//     isCheck: false,
//   },
// ];
function SurveyStep2({response}) {
  const [contributors, setContributors] = useState([]);
  const [approvers, setApprover] = useState([]);
  const [users, setUsers] = useState(dummyData?.members);
  const [selectedSection, setSelectedSection] = useState([]);
  const [status, setStatus] = useState('yet to assign');
  const handleContributers = () => {
    const updateList = users.filter((data) => data?.isCheck);
    setContributors([
      ...contributors,
      ...updateList.map((item) => ({
        ...item,
        isCheck: false,
      })),
    ]);
    setUsers((preValue) =>
      preValue.map((item) => ({
        ...item,
        isCheck: false,
      }))
    );
  };

  const handleApprovers = () => {
    const updateList = users.filter((data) => data?.isCheck);
    setApprover([
      ...approvers,
      ...updateList.map((item) => ({
        ...item,
        isCheck: false,
      })),
    ]);
    setUsers((preValue) =>
      preValue.map((item) => ({
        ...item,
        isCheck: false,
      }))
    );
  };

  const handleDeleteContributers = (id) => {
    const updateList = contributors.filter((data) => data?.id !== id);
    setContributors(updateList);
  };

  const handleDeleteApprovers = (id) => {
    const updateList = approvers.filter((data) => data?.id !== id);
    setApprover(updateList);
  };

  const toggleSingleItemUser = (itemId) => {
    const updatedList = users.map((item) =>
      item.id === itemId ? { ...item, isCheck: !item.isCheck } : item
    );

    setUsers(updatedList);
  };
  const handleSelectSection = (section) => {
    setSelectedSection(section)
  }
  const handleStatus = (status) => {
    setStatus(status)
  }
  return (
    <StepLayOut
      left={
        <Step2Left
          contributors={contributors}
          approvers={approvers}
          handleContributers={handleContributers}
          handleApprovers={handleApprovers}
          handleDeleteApprovers={handleDeleteApprovers}
          handleDeleteContributers={handleDeleteContributers}
          users={users}
          handleReset={() => {
            setContributors([]);
            setApprover([]);
          }}
          toggleSingleItemUser={toggleSingleItemUser}
          surveyList={Object.keys(response)}
          handleSelectSection={handleSelectSection}
          handleStatus={handleStatus}
          sectionQuestions={response[selectedSection] || []}
        />
      }
      right={
        <Step2Right
          contributors={contributors}
          approvers={approvers}
          handleContributers={handleContributers}
          handleApprovers={handleApprovers}
          handleDeleteApprovers={handleDeleteApprovers}
          handleDeleteContributers={handleDeleteContributers}
          users={users}
          handleReset={() => {
            setContributors([]);
            setApprover([]);
          }}
          toggleSingleItemUser={toggleSingleItemUser}
          sectionQuestions={response[selectedSection] || []}
          status={status}
          handleStatus={handleStatus}
          surveyList={Object.keys(response)}
        />
      }
    />
  );
}

export default SurveyStep2;
