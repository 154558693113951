import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

// other imports
import { BlueBackgroundCard } from "../../../../../../../widgets/esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { Question } from "../../../../../../selfAssessment/selfAssessmentResponse";
import OptimizeInitialRender from "./OptimizeInitialRender";
const ResponseSectionList = ({ submittedQuestions, response }) => {
  const nonClickableStyle = {
    pointerEvents: "none",
  };
  if (submittedQuestions?.length === 0) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ height: "80%", overflowY: "scroll", paddingBottom: "10px" }}>
      <OptimizeInitialRender chunkSize={1}>
        {Object.keys(response).map((question, index) => {
          return (
            <div key={index} style={nonClickableStyle}>
              <BlueBackgroundCard heading={question} />
              <OptimizeInitialRender chunkSize={1}>
                {response[question].map((question) => (
                  <Question
                    key={question?._id}
                    question={question.question}
                    options={question.answers}
                    type={question.question_type}
                    weightage={question.weightage}
                    submittedAnswer={question.submitted_answer}
                    removeEditOption={true}
                    comment={question?.comment}
                  />
                ))}
              </OptimizeInitialRender>
            </div>
          );
        })}
      </OptimizeInitialRender>
    </Box>
  );
};

export default ResponseSectionList;