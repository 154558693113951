import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  TableRow,
  TableCell,
  makeStyles,
  Typography,
  Avatar,
} from "@material-ui/core";
import DotIcon from "@material-ui/icons/FiberManualRecordRounded";

import DynamicTable, {
  statusStyle,
  textStyle,
} from "../../../../UI/dynamicTable/DynamicTable";
import {
  assignedHeadings,
  assignedData,
} from "../../../../screen/vendorAdminScreen/tempData";

// -------- stylings -----------

// Hover styles for TableRow
const useStyles = makeStyles({
  tableRowHover: {
    // border: "1px solid #E9E9E9",
    borderRadius: "8px",
    margin: 10,
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#EBF1F8",
      cursor: "pointer",
    },
  },
});

// ------------------------------

// -------- main render ---------
const AssignedTab = () => {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();

  return (
    <Box p={2}>
      {/* Table */}
      <DynamicTable headings={assignedHeadings}>
        {assignedData.map((rowData, rowIndex) => (
          <>
            <TableRow>
              <TableCell style={{ border: "none" }} colSpan={6}>
                {rowData?.hasTitle && (
                  <Typography
                    style={{
                      color: "#15314E",
                      fontSize: "16px",
                      fontWeight: 600,
                    }}
                  >
                    {rowIndex === 0 ? "February '24" : "January'24"}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow
              key={rowIndex}
              className={classes.tableRowHover}
              onClick={() =>
                history.push(
                  `${location.pathname}/${rowData.task_type.replace(/ /g, "_")}`
                )
              }
            >
              <TableCell style={{ ...textStyle("main") }}>
                {rowData.task_type}
              </TableCell>
              <TableCell style={{ ...textStyle() }}>
                {rowData.survey_frequency}
              </TableCell>
              <TableCell style={{ ...textStyle() }}>
                {rowData.action_required}
              </TableCell>
              <TableCell>
                <Box style={{ ...statusStyle(rowData.status.toLowerCase()) }}>
                  <DotIcon style={{ widht: "10px", height: "10px" }} />
                  {rowData.status}
                </Box>
              </TableCell>
              <TableCell style={{ ...textStyle() }}>
                {rowData.sent_on}
              </TableCell>
              <TableCell style={textStyle()}>
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  sx={{ gap: 10 }}
                >
                  <Avatar
                    src={rowData.assigned_by.avatar || rowData.assigned_by.name}
                    alt={rowData.assigned_by.name}
                  />
                  <Box display={"flex"} flexDirection={"column"}>
                    <Typography style={textStyle("main")}>
                      {rowData.assigned_by.name}
                    </Typography>
                    <Typography
                      style={{
                        color: "#AFAFAF",
                        fontWeight: 500,
                        fontSize: "11px",
                        lineHeight: "16px",
                      }}
                    >
                      {rowData.assigned_by.designation}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          </>
        ))}
      </DynamicTable>
    </Box>
  );
};
export default AssignedTab;
