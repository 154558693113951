import React from "react";

import StepHeader from "./stepHeader/StepHeader";
import SurveyStep1 from "./step1";
import SurveyStep2 from "./step2";
import SurveyStep3 from "./step3";
import SurveyStep4 from "./step4";
import SurveyButton from "./surveyButton/SurveyButton";
import FinalSubmission from "./finalStep/FinalSubmission";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { response1, response2 } from "../../riskSurveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionData";

function getStepContent(step, response) {
  switch (step) {
    case 0:
      return <SurveyStep1 />;
    case 1:
      return <SurveyStep2 response={response} />;
    case 2:
      return <SurveyStep3 response={response} />;
    case 3:
      return <SurveyStep4 response={response} />;
    case 4:
      return <FinalSubmission />;
    default:
      return null
  }
}

const steps = [
  "Add Stakeholder",
  "View & Assign Survey",
  "Audit & Freeze",
  "Submission",
];

const SurveySteps = () => {
  const {location} = useHistory();

  const {pathname} = location
  // console.log(history);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  


  return (
    <>
      {/* header */}
      <StepHeader steps={steps} activeStep={activeStep} />
      {/* main content */}
      {getStepContent(activeStep, pathname === "/vendor_admin/FlowSource/Survey_2_(Month_Feb)" ? response1 : response2 )}
      {/* proceed button */}
      <SurveyButton
        steps={steps}
        activeStep={activeStep}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </>
  );
};
export default SurveySteps;
