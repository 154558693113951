import React from "react";
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";

import CustomButton from "../../../../UI/button/button"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 20,
  },
  button: {
    marginRight: theme.spacing(2),
    minWidth: "100px",
    textTransform: "capitalize",
  },
}));

export default function SurveyButton({
  activeStep,
  handleBack,
  handleNext,
  steps,
}) {
  const classes = useStyles();
  const history = useHistory();

  const goBack = () => {
    history.goBack(); // This function takes you back to the previous route
  };

  return (
    <div className={classes.root}>
      {activeStep < steps.length && <CustomButton
        disabled={activeStep === 0}
        onClick={handleBack}
        className={classes.button}
        color="primary"
      // style={{ color: "#3374B9" }}
      >
        Previous
      </CustomButton>}

      {activeStep < steps.length && <CustomButton
        variant="contained"
        color="primary"
        onClick={handleNext}
        className={classes.button}
      // style={{ background: "#3374B9" }}
      >
        {activeStep === steps.length - 1 ? "Submit Response" : "Proceed"}
      </CustomButton>}
      {activeStep === steps.length && <CustomButton
        variant="contained"
        color="primary"
        onClick={goBack}
        className={classes.button}
      // style={{ background: "#3374B9" }}
      >
        Go to Survey listing
      </CustomButton>}
    </div>
  );
}
