import React from "react";

import { Box, Typography } from "@material-ui/core";
import WidgetComponent from "../../widgets/vendorDashboard/WidgetComponent";

import { IoPeopleCircleSharp } from "react-icons/io5";
import { FaArrowUpShortWide } from "react-icons/fa6";
import ChartsComponent from "../../widgets/vendorDashboard/ChartsComponent";
import ChartPie from "../../widgets/vendorDashboard/Charts";
import TaskContainer from "../../../containers/taskCont/taskContainer";
import GaugeChart from "../../../containers/taskCont/TempGaugeChart";
import chartImage from "../../../images/ContentChart.png";
import VendorListTable from "../../widgets/vendorDashboard/TableDashboard";

function VendorDashboard() {
  return (
    <Box>
      <Typography
        style={{ color: "#15314E", fontSize: "22px", fontWeight: 600 }}
      >
        Dashboard
      </Typography>
      <Box style={{ padding: "10px" }}>
        <Box style={{ display: "flex", gap: 20 }}>
          <WidgetComponent
            title={"Total No. of Vendors"}
            icon={
              <IoPeopleCircleSharp size={30} style={{ color: "#3374B9" }} />
            }
          >
            <Typography
              style={{ color: "#15314E", fontSize: "26px", fontWeight: 500 }}
            >
              186
            </Typography>
          </WidgetComponent>
          <WidgetComponent
            title={"Avg. Security Risk"}
            icon={<FaArrowUpShortWide size={30} style={{ color: "#3374B9" }} />}
          >
            <Typography
              style={{
                color: "#52231D",
                fontSize: "16px",
                fontWeight: 500,
                backgroundColor: "#EDCAC6",
                padding: "10px 20px 10px 20px",
                borderRadius: "80px",
              }}
            >
              Low
            </Typography>
          </WidgetComponent>
          <WidgetComponent
            title={"Avg. Vendor Risk"}
            icon={<FaArrowUpShortWide size={30} style={{ color: "#3374B9" }} />}
          >
            <Typography
              style={{
                color: "#154328",
                fontSize: "16px",
                fontWeight: 500,
                backgroundColor: "#BFE2CD",
                padding: "10px 20px 10px 20px",
                borderRadius: "80px",
              }}
            >
              Low
            </Typography>
          </WidgetComponent>
          <WidgetComponent
            title={"Avg. ESG Risk"}
            icon={<FaArrowUpShortWide size={30} style={{ color: "#3374B9" }} />}
          >
            <Typography
              style={{
                color: "#8C7038",
                fontSize: "16px",
                fontWeight: 500,
                backgroundColor: "#FFEFD0",
                padding: "10px 20px 10px 20px",
                borderRadius: "80px",
              }}
            >
              Medium
            </Typography>
          </WidgetComponent>
        </Box>

        <Box style={{ display: "flex", gap: 20 }}>
          <ChartsComponent title={"Vendors by Service type"}>
            <ChartPie
              backgroundColor={["#176BA0", "#19AADE", "#29066B", "#142459"]}
              label={["Software", "Clout", "Datasets", "Hardware"]}
              dataChart={[3, 5, 32, 9]}
            />
          </ChartsComponent>
          <ChartsComponent title={"Security Risk"}>
            <ChartPie
              backgroundColor={["#FFD685", "#5BB37F", "#D0766B"]}
              label={["Low", "Medium", "High"]}
              dataChart={[40, 15, 20]}
            />
          </ChartsComponent>
          <ChartsComponent title={"TPRM Risk"}>
            <ChartPie
              backgroundColor={["#FFD685", "#5BB37F", "#D0766B"]}
              label={["Low", "Medium", "High"]}
              dataChart={[13, 35, 62]}
            />
          </ChartsComponent>
          <ChartsComponent title={"ESG Risk"}>
            <ChartPie
              backgroundColor={["#FFD685", "#5BB37F", "#D0766B"]}
              label={["Low", "Medium", "High"]}
              dataChart={[70, 25, 25]}
            />
          </ChartsComponent>
        </Box>
        <Box style={{ display: "flex", gap: 20 }}>
          <ChartsComponent title={"Average Risk by Vendors"}>
            <GaugeChart value={1.4} />
          </ChartsComponent>
          <ChartsComponent title={"Overall Risk Performance"}>
            <Box style={{ width: "85%",  marginLeft:"auto", marginRight:"auto" }}>
              <img
                src={chartImage}
                alt="chart"
                width={"100%"}
              />
              
            </Box>
          </ChartsComponent>
        </Box>
        <Box style={{ marginTop: "20px" }}>
          <VendorListTable />
        </Box>
      </Box>
    </Box>
  );
}

export default VendorDashboard;
