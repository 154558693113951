import React from "react";

import StepLeft from "../../stepLayout/left/StepLeft";
import { Box, Typography } from "@material-ui/core";
import { LeftCard } from "../../step1/left/SurveyLeftCard";

import { MdOutlineAccessTime } from "react-icons/md";
import { BiDockLeft } from "react-icons/bi";

const basicDataFirstTab = [
  {
    id: 1,
    mainHead: "Completed Survey Sections",
    subHead:
      "Survey section",
  },
];

function Step4Left() {
  return (
    <StepLeft>
      <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
        Sections
      </Typography>
      <Box style={{ display: "flex", padding: "10px", justifyContent: "flex-start", gap: 10 }}>
        <MdOutlineAccessTime size={"16px"} style={{ color: "#5C90C7" }} />
        <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
          Every Month
        </Typography>
        <BiDockLeft size={"16px"} style={{ color: "#5C90C7" }} />
        <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
          12 Sections
        </Typography>
      </Box>
      <LeftCard toMap={basicDataFirstTab} subTabNo={1} />
    </StepLeft>
  );
}

export default Step4Left;
