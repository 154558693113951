import {
  AppBar,
  Icon,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { appTheme } from "../riskAssessment/brmDetaails";
import { appbarStyles } from "../esgCompanyProfile/esgCompantProfile";
import TabPanel from "../../UI/tabPanel/tabPanel";
import EsgScore from "../esgCompanyProfile/esgScore";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import CompanyProfile from "../esgCompanyProfile/companyProfile";
import DimentionCharts from "../esgDeclaration/dimentionChart/dimentionCharts";
import VendorScore from "../esgCompanyProfile/vendorScore";
import { Radar } from "react-chartjs-2";

function VendorProfile(props) {
  const [vendorProfileTabNo, setVendorProfileTabNo] = useState(0);
  const app = appbarStyles();
  const data = {
    scoreObject: {
      security: {
        score: 8.692905242905244,
        sum_of_weighted_score: 0.08692905242905244,
        final_weighted_score: 0.008692905242905244,
        average: {
          analytics: 0.355978835978836,
          intrusion: 0.25665584415584414,
          log: 0.25665584415584414,
          file: 0.25665584415584414,
          vulnerablity: 0.25665584415584414,
          incident: 0.25665584415584414,
          container: 0.25665584415584414,
          cloud: 0.25665584415584414,
        },
        weighted_score: {
          analytics: 0.0355978835978836,
          intrusion: 0.05133116883116883,
          log: 0.25665584415584414,
          file: 0.25665584415584414,
          vulnerablity: 0.25665584415584414,
          incident: 0.25665584415584414,
          container: 0.25665584415584414,
          cloud: 0.25665584415584414,
        },
        data_points: {
          analytics: 3.5,
          intrusion: 5.1,
          log: 4.5,
          file: 2,
          vulnerablity: 1.4,
          incident: 3,
          container: 3,
          cloud: 3,
        },
      },
      compilance: {
        score: 8.692905242905244,
        sum_of_weighted_score: 0.08692905242905244,
        final_weighted_score: 0.008692905242905244,
        average: {
          configure: 0.355978835978836,
          regulatory: 0.25665584415584414,
          vendor: 0.25665584415584414,
          audit: 0.25665584415584414,
          incident: 0.25665584415584414,
          data: 0.25665584415584414,
          concent: 0.25665584415584414,
        },
        weighted_score: {
          configure: 0.0355978835978836,
          regulatory: 0.05133116883116883,
          vendor: 0.25665584415584414,
          audit: 0.25665584415584414,
          incident: 0.25665584415584414,
          data: 0.25665584415584414,
          concent: 0.25665584415584414,
        },
        data_points: {
          configure: 3.5,
          regulatory: 5.1,
          vendor: 4.5,
          audit: 2,
          incident: 1.4,
          data: 3,
          concent: 3,
        },
      },
    },
    esgScore: 45.98661827925614,
    esgcScore: 5.555555555555555,
    finalEsg: 83.77108691740585,
    energy_kpis: {
      "Total electricity consumption in Gigajoules  from renewable sources": 0,
      "Total fuel consumption in Gigajoules  from renewable sources": 0,
      "Total Energy consumption  in Gigajoules  from renewable sources ": 0,
      "Total electricity consumption in Gigajoules  from non renewable sources": 57122.44,
      "Total fuel consumption in Gigajoules  fromnon renewable sources": 0,
      "Total Energy consumption  in Gigajoules  from non renewable sources ": 0,
    },
    water_kpis: {
      "wasteCount withdrawal by source (in kilolitres)  from surface water": 0,
      "Water withdrawal by source (in kilolitres)  from ground water": 0,
      "Water withdrawal by source (in kilolitres)  from third party water ": 0,
      "Amount of rainwater was utilized  (in kilolitres) ": 0,
      " Volume of water withdrawal  (in kilolitres)": 0,
      "Total volume of water consumption (in kilolitres)  ": 78400,
      " Water intensity per rupee of turnover (Water consumed / turnover) ": 0,
    },
  };

  const graphdata = {
    labels: ["Compliance", "General", "Cyber security", "Physical Security"],
    datasets: [
      {
        label: "Cyber security",
        data: [7, 3, 8, 6],
        backgroundColor: "rgb(241, 214, 214,0.15)",
        borderColor: "#F1D6D6",
        borderWidth: 1.5,
        // fill: true,
        pointStyle: "clearRect",
      },
      {
        label: "General",
        data: [4.5, 8, 3, 8],
        backgroundColor: "rgb(51, 116, 185,.15)",
        borderColor: "#3374B9",
        borderWidth: 1.5,
        //  fill: true,
        pointStyle: "clearRect",
      },
      {
        label: "Physical Security",
        data: [8, 4, 3, 3],
        backgroundColor: "rgb(50, 160, 95,.15)",
        borderColor: "#32A05F",
        borderWidth: 1.5,
        //  fill: true,
        pointStyle: "clearRect",
      },
      {
        label: "Compliance",
        data: [2, 6, 3, 4],
        backgroundColor: "rgb(232, 168, 56,.15)",
        borderColor: "#E8A838",
        borderWidth: 1.5,
        //  fill: true,
        pointStyle: "clearRect",
      },
    ],
  };
  const options = {
    legend: {
      labels: {
        usePointStyle: true, //<-- set this
      },
    },
  };
  const config3 = {
    type: "radar",
    data: graphdata,
    options: options,

    // {
    //   scale: {
    //     beginAtZero: true,
    //     min: 0,
    //   },
    //   elements: {
    //     line: {
    //       borderWidth: 1,
    //     },
    //   },
    // },
  };

  const config = {
    type: "radar",
    data: graphdata,
    options: {
      // plugins: {
      //   scale: {
      //     beginAtZero: true,
      //     min: 0,
      //   },
      //   legend: {
      //     labels: {
      //       usePointStyle: true, //<-- set this
      //     },
      //   },
      // },
      // interaction: {
      //   intersect: false,
      // },
    },
  };
  const options1 = {
    // maintainAspectRatio: false,
    scales: {
      r: {
        suggestedMin: 0,
        suggestedMax: 1,
        // pointLabels: {
        //   display: false, // Hides the labels around the radar chart
        // },
        ticks: {
          display: false, // Hides the labels in the middel (numbers)
        },
      },
    },
    legend: {
      labels: {
        usePointStyle: true, //<-- set this
      },
    },
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {" "}
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            Vendor Profile
          </Typography>
        </div>
        <div>
          {/* <InfoOutlinedIcon
            style={{ color: "#3374b9", marginRight: "12px", cursor: "pointer" }}
          /> */}
          <Icon style={{ cursor: "pointer", color: "#3374b9", fontSize: 20 }}>
            more_vert
          </Icon>
        </div>
      </div>
      <MuiThemeProvider theme={appTheme}>
        <AppBar position="static" color="inherit" className={app.AppBar}>
          <Tabs
            value={vendorProfileTabNo}
            onChange={(e, newVal) => {
              setVendorProfileTabNo(newVal);
            }}
            // onChange={handleTabChange}
          >
            <Tab
              disableRipple
              className={app.tab}
              label={<span className={app.tabLabel}>Vendor Score</span>}
            />
            <Tab
              disableRipple
              className={app.tab}
              label={<span className={app.tabLabel}>Vendor Assessment </span>}
            />
          </Tabs>
        </AppBar>
      </MuiThemeProvider>

      <div
        className="scroll"
        style={{ height: "51vh", marginTop: "10px", paddingBottom: 20 }}
      >
        <TabPanel value={vendorProfileTabNo} index={0}>
          {<VendorScore scores={data} />}
        </TabPanel>
        <TabPanel value={vendorProfileTabNo} index={1}>
          <div className="scroll" style={{ height: "55vh" }}>
            <div style={{ display: "flex", gap: 10 }}>
              <div style={{ width: "40%" }}>
                <BlueBackgroundCard heading={"Assessment Score"} />
                <CompanyProfile scores={data} />
              </div>
              <div style={{ width: "60%" }}>
                <BlueBackgroundCard heading={"Dimensions"} />
                <Radar config={config} options={options1} data={graphdata} />
                {/* <DimentionCharts isProfile {...props} scores={data} /> */}
              </div>
            </div>
          </div>
        </TabPanel>
      </div>
    </div>
  );
}

export default VendorProfile;
