import {
  Box,
  Typography,
  Radio,
  FormControlLabel,
  MuiThemeProvider,
  RadioGroup,
} from "@material-ui/core";
import { useDispatch } from "react-redux";

import { appTheme } from "../../../../riskAssessment/brmDetaails";
import { UpdateQuestionOption } from "../../../../../../redux/actions/taskActions/TaskAction";
import { useEffect, useState } from "react";
import QuestionBody from "../../../../riskSurveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionBody";

const subText = [
  `Please be aware that a ""yes"" answer may imply your willingness to undergo a further security assessment for your service.`,
  `Please describe the specific access needs and protocols involved. if 'Yes' you maybe required to complete an additional Security Assessment). If ""No"" or ""Not Applicable,"" please provide an explanation. "`,
  `This may include the ability to view, modify, or manage the data. Please provide details of data access requirements and roles (controller/processor). If yes, be aware that your answers will be reviewed by our senior data privacy officer and may trigger a further data privacy assessment due to the sensitivity of the data involved.`,
];

const options = [
  "Strongly Disagree",
  "Disagree",
  "Neutral",
  "Agree",
  "Strongly Agree",
];

const QuestionsSection = ({ question, activeTab }) => {
  const dispatch = useDispatch();

  const [randomIndex, setRandomIndex] = useState(0);

  let submittedAnswer = { option: question?.selectedOpt };

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * subText.length);
    setRandomIndex(randomIndex);
  }, [question?._id]);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    dispatch(
      UpdateQuestionOption(question?._id, selectedValue, activeTab?._id)
    );
  };

  return (
    <Box p={2}>
      <Box display={"flex"} flexDirection={"column"} sx={{ gap: 10 }}>
        {/* Question */}
        <Box
          style={{
            borderRadius: "8px",
            padding: "16px",
            border: "1px solid #E9E9E9",
          }}
        >
          <Typography
            style={{
              color: "#242424",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            {question?.question}
          </Typography>
        </Box>

        {/* disclamer */}
        <Typography
          style={{
            color: "#878787",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          {subText[randomIndex]}
        </Typography>
      </Box>

      {/* options */}
      <Box>
        <QuestionBody
          questionType={question?.question_type}
          answers={options}
          submittedAnswer={submittedAnswer}
          questionId={question?._id}
          activeTabId={activeTab?._id}
          isFrom={"taskSection"}
        />
        {/* <MuiThemeProvider theme={appTheme}>
          <RadioGroup
            aria-label="options"
            name="options"
            value={question?.selectedOpt}
            onChange={handleOptionChange}
          >
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option}
                control={<Radio color="primary" />}
                label={option}
              />
            ))}
          </RadioGroup>
        </MuiThemeProvider> */}
      </Box>
    </Box>
  );
};

export default QuestionsSection;
