import Box from "@material-ui/core/Box";

import ProgressHeader from "./RightComps/ProgressHeader";
import QuestionsSection from "./RightComps/QuestionsSection";
import { Question } from "../../../selfAssessment/selfAssessmentResponse";

const RightSections = ({ question, progress, currentQues, activeTab }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} sx={{ gap: 20 }}>
      {/* Header */}
      <ProgressHeader
        title={activeTab?.section_name}
        sub={activeTab?.section_sub_name}
        progress={progress}
        totalQues={activeTab?.questions?.length}
        currentQues={currentQues}
      />

      {/* Main */}
      {/* <Question question={question?.question} type={question?.question_type} /> */}

      <QuestionsSection
        question={question}
        currentQues={currentQues}
        activeTab={activeTab}
      />
    </Box>
  );
};
export default RightSections;
