import Chip from "@material-ui/core/Chip";

const LowtoHighChip = ({ selectedChip, setSelectedChip }) => {
  return (
    <div style={{ display: "flex" }}>
      <Chip
        label="Low"
        style={{
          color: selectedChip === "low" ? "#32A05F" : "#6C6C6C",
          backgroundColor: selectedChip === "low" ? "#EBF6EF" : "#F6F6F6",
          borderRadius: 12,
          fontSize: 13,

          width: "fit-content",
          margin: "5px 8px",
        }}
        clickable
        onClick={() => setSelectedChip("low")}
      />
      <Chip
        label="Medium"
        style={{
          color: selectedChip === "medium" ? "#8C7038" : "#6C6C6C",
          backgroundColor: selectedChip === "medium" ? "#FFEFD0" : "#F6F6F6",
          borderRadius: 12,
          fontSize: 13,
          width: "fit-content",
          margin: "5px 8px",
        }}
        clickable
        onClick={() => setSelectedChip("medium")}
      />
      <Chip
        label="High"
        style={{
          color: selectedChip === "high" ? "#52231D" : "#6C6C6C",
          backgroundColor: selectedChip === "high" ? "#EDCAC6" : "#F6F6F6",
          borderRadius: 12,
          fontSize: 13,
          
          width: "fit-content",
          margin: "5px 8px",
        }}
        clickable
        onClick={() => setSelectedChip("high")}
      />
    </div>
  );
};
export default LowtoHighChip;
