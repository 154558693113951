import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import { useState } from "react";
import { FiTrash } from "react-icons/fi";

import CustomButton from "../../../UI/button/button";
import FileUploaded from "../../../../images/FileUploded.png";

const DataRequest = () => {
  const history = useHistory();

  // State to store file names and upload status
  const [fileNames, setFileNames] = useState(["", "", ""]);
  const [isFileUploaded, setIsFileUploaded] = useState([false, false, false]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Function to handle file upload
  const handleFileUpload = (index) => (event) => {
    const file = event.target.files[0];
    if (file) {
      const newFileNames = [...fileNames];
      newFileNames[index] = file.name;
      setFileNames(newFileNames);

      const newIsFileUploaded = [...isFileUploaded];
      newIsFileUploaded[index] = true;
      setIsFileUploaded(newIsFileUploaded);
    }
  };

  // Function to handle file delete
  const handleFileDelete = (index) => () => {
    const newFileNames = [...fileNames];
    newFileNames[index] = "";
    setFileNames(newFileNames);

    const newIsFileUploaded = [...isFileUploaded];
    newIsFileUploaded[index] = false;
    setIsFileUploaded(newIsFileUploaded);
  };

  return (
    <Box width={"80%"} height={"75vh"} style={{ position: "relative" }}>
      {!isSubmitted ? (
        <>
          {/* Header Box */}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              style={{
                color: "#15314E",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Data Request
            </Typography>
            <CustomButton
              variant={"contained"}
              color={"primary"}
              style={{ width: "10em", textTransform: "capitalize" }}
              onClick={() => setIsSubmitted(true)}
            >
              Submit
            </CustomButton>
          </Box>

          {/* Table Box */}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type Of Document</TableCell>
                <TableCell>Financial Year</TableCell>
                <TableCell>Upload File</TableCell>
                <TableCell>File Name</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[
                { type: "Annual Report", year: "2022" },
                { type: "ESG Report", year: "2022" },
                { type: "GRI Report", year: "2022" },
              ].map((e, index) => (
                <TableRow key={index}>
                  <TableCell>{e.type}</TableCell>
                  <TableCell>{e.year}</TableCell>
                  <TableCell>
                    <input
                      type="file"
                      onChange={handleFileUpload(index)}
                      style={{ display: "none" }}
                      id={`file-upload-${index}`}
                    />
                    <label htmlFor={`file-upload-${index}`}>
                      <CustomButton
                        component="span"
                        variant="text"
                        color="primary"
                        style={{ textTransform: "capitalize" }}
                      >
                        Upload
                      </CustomButton>
                    </label>
                  </TableCell>
                  <TableCell>{fileNames[index] || "-"}</TableCell>
                  <TableCell>
                    {isFileUploaded[index] && (
                      <IconButton onClick={handleFileDelete(index)}>
                        <FiTrash color="#C45446" />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      ) : (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          sx={{ gap: 10 }}
        >
          <img
            src={FileUploaded}
            alt="FileUploaded"
            style={{ aspectRatio: "1:1", width: "35%" }}
          />
          <Typography
            style={{
              color: "#0F0F0F",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            This Section has been Saved.
          </Typography>
          <Typography
            style={{
              color: "#0F0F0F",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            If you wish to redo the survey please use the redo button. Or go
            back to the task section.
          </Typography>

          <Box
            style={{
              position: "absolute",
              right: 30,
              bottom: 10,
              display: "flex",
              gap: 10,
            }}
          >
            <CustomButton
              variant="text"
              color="primary"
              style={{
                textTransform: "capitalize",
              }}
              onClick={() => setIsSubmitted(false)}
            >
              Redo Survey
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              style={{
                textTransform: "capitalize",
              }}
              onClick={() => history.goBack()}
            >
              Go To Task Menu
            </CustomButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DataRequest;
