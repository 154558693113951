import { Box, Typography } from "@material-ui/core";
import QuestionsSection from "./QuestionsSection";
import CustomButton from "../../../../../UI/button/button";

const SpecificQuestion = ({ question, activeTab, handleSelectQuestion }) => {
  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      style={{ gap: 15 }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        style={{ gap: 5, backgroundColor: "#EBF1F8" }}
        p={2}
      >
        {/* title text */}
        <Typography
          style={{
            color: "#15314E",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          {activeTab === 0 ? "Company Profile" : "Information Security"}
        </Typography>

        {/* sub text */}
        <Typography
          style={{
            color: "#245283",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          {activeTab === 0
            ? "Shape the narrative of your company's sustainability journey by providing detailed insights in our Company Profile Section, capturing the essence of your environmental, social, and governance practices."
            : "Fortify your organization's digital resilience by meticulously addressing key aspects of Information Security in our section, ensuring robust measures are in place to protect sensitive data and bolster trust."}
        </Typography>
      </Box>
      <Box p={2}>
        <QuestionsSection question={question} activeTab={activeTab} />
      </Box>

      <Box
        style={{
          display: "flex",
          position: "absolute",
          right: 10,
          bottom: 10,
          gap: 10,
        }}
      >
        <CustomButton
          variant={"text"}
          color={"primary"}
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            handleSelectQuestion(question, "remove");
          }}
        >
          Cancel
        </CustomButton>
        <CustomButton
          variant={"contained"}
          color={"primary"}
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            handleSelectQuestion(question, "remove");
          }}
        >
          Submit
        </CustomButton>
      </Box>
    </Box>
  );
};
export default SpecificQuestion;
