import React, { useState } from "react";
import StepLayOut from "../stepLayout";
import Step3Left from "./left/Step3Left";
import Step3Right from "./right/Step3Right";

function SurveyStep3({ response }) {
  const [tabValue, setTabValue] = React.useState(0);
  const [selectedSection, setSelectedSection] = useState([]);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleSelectSection = (section) => {
    setSelectedSection(section);
  };
  return (
    <StepLayOut
      left={
        <Step3Left
          surveyList={Object.keys(response)}
          handleSelectSection={handleSelectSection}
          tabValue={tabValue}
          handleTabChange={handleTabChange}
        />
      }
      right={
        <Step3Right
          sectionQuestions={response[selectedSection] || []}
          tabValue={tabValue}
        />
      }
    />
  );
}

export default SurveyStep3;
