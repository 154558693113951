import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import General from "../../../images/general.svg";
import Plan from "../../../images/plan.svg";
import Company from "../../../images/company.svg";
import Profile from "../../../images/profile.svg";
import User from "../../../images/user_setting.svg";
import { Typography, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMenu } from "../../../redux/actions/generalSettings/generalSettingsAction";

export default function GeneralSettingMenu(props) {
  const useStyles = makeStyles(() => ({
    img: { height: 45, width: 45 },
  }));
  const classes = useStyles();

  // const { generalSettings, setSelectedMenu, login } = props;
  const { generalSettings, login } = useSelector((state) => state);
  const dispatch = useDispatch();
  const userType = localStorage.getItem("user_type");
  return (
    <div>
      <List>
        <ListItem
          button
          style={{
            borderBottom:
              generalSettings.selectedMenu === "profile-details" &&
              "2px solid #3374B9",
            padding: "0px 12px",
          }}
          onClick={() => {
            dispatch(setSelectedMenu("profile-details"));
          }}
        >
          <ListItemIcon>
            <img src={Profile} alt="icon" className={classes.img} />
          </ListItemIcon>
          <Typography
            style={{
              padding: 0,
              fontSize: 14,
              fontWeight:
                generalSettings.selectedMenu === "profile-details" ? 420 : 400,
            }}
          >
            Profile Details
          </Typography>
          {/* <ListItemText primary="Profile Details" /> */}
        </ListItem>
        {
          <ListItem
            button
            style={{
              borderBottom:
                generalSettings.selectedMenu === "company-details" &&
                "2px solid #3374B9",
              padding: "0px 12px",
            }}
            onClick={() => {
              dispatch(setSelectedMenu("company-details"));
            }}
          >
            <ListItemIcon>
              <img src={Company} alt="icon" className={classes.img} />
            </ListItemIcon>
            <Typography
              style={{
                padding: 0,
                fontSize: 14,
                fontWeight:
                  generalSettings.selectedMenu === "company-details"
                    ? 420
                    : 400,
              }}
            >
              Company Details
            </Typography>
          </ListItem>
        }
        {/* PlLANS TAB */}
        {
          // <ListItem
          // disabled
          //   button
          //   style={{
          //     borderBottom:
          //       generalSettings.selectedMenu === "plans" && "2px solid #3374B9",
          //     padding: "0px 12px",
          //   }}
          //   onClick={() => {
          //     dispatch(setSelectedMenu("plans"));
          //   }}
          // >
          //   <ListItemIcon>
          //     <img src={Plan} alt="icon" className={classes.img} />
          //   </ListItemIcon>
          //   <Typography
          //     style={{
          //       padding: 0,
          //       fontSize: 14,
          //       fontWeight:
          //         generalSettings.selectedMenu === "plans" ? 420 : 400,
          //     }}
          //   >
          //     Plans
          //   </Typography>
          // </ListItem>
        }
         {/* General Settings TAB */}
        <ListItem
          button
          style={{
            borderBottom:
              generalSettings.selectedMenu === "general-settings" &&
              "2px solid #3374B9",
            padding: "0px 12px",
          }}
          onClick={() => {
            dispatch(setSelectedMenu("general-settings"));
          }}
        >
          <ListItemIcon>
            <img src={General} alt="icon" className={classes.img} />
          </ListItemIcon>
          <Typography
            style={{
              padding: 0,
              fontSize: 14,
              fontWeight:
                generalSettings.selectedMenu === "general-settings" ? 420 : 400,
            }}
          >
            General Settings
          </Typography>
        </ListItem>
        {/* user Settings TAB */}
        {
          <ListItem
            button
            // disabled={!login.isSubscriptionActive}
            style={{
              borderBottom:
                generalSettings.selectedMenu === "user-settings" &&
                "2px solid #3374B9",
              padding: "0px 12px",
            }}
            onClick={() => {
              dispatch(setSelectedMenu("user-settings"));
            }}
          >
            <ListItemIcon>
              <img src={User} alt="icon" className={classes.img} />
            </ListItemIcon>
            <Typography
              style={{
                padding: 0,
                fontSize: 14,
                fontWeight:
                  generalSettings.selectedMenu === "user-settings" ? 420 : 400,
              }}
            >
              User Settings
            </Typography>
          </ListItem>
        }
      </List>
    </div>
  );
}
