import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import OtpInput from "react-otp-input";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Grow from "@material-ui/core/Grow";
import Fade from "@material-ui/core/Fade";

import {
  emailValidation,
  passwordValidation,
} from "../../../redux/actions/regex/regexValidation";

import signupCardUseStyles from "./signupCardStyles";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffOutlined";

import YouTube from "react-youtube";
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from "@material-ui/core";
import CustomTextfield from "../../UI/textfield/textfield";
import CustomButton from "../../UI/button/button";
import brief from "../../../images/briefcaseBlue.png";
import user from "../../../images/userIconBlue.png";
import mail from "../../../images/mail.png";
import greenTick from "../../../images/tick.png";
import Explore from "../../../images/Explore.svg";
import LogoAvatar from "../../../images/LogoAvatar.png";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  forgetPassword,
  gpt_signup,
  gql_login_with_email,
  gql_vendor_signup,
  gql_verify_otp,
  login_with_email,
  resendOtp,
  setSignupStepNo,
  verifyOtp,
} from "../../../redux/actions/login/loginAction";
import { useDispatch, useSelector } from "react-redux";
import PlanExpired from "../../widgets/esgGpt/PlanExpired";
import Feedback from "../../widgets/esgGpt/Feedback";

function SignupCard(props) {
  const { signupCard, onlyLogin } = props;
  const classes = signupCardUseStyles();
  const initialFormDetails = {
    fullName: "",
    email: "",
    password: "",
    orgName: "",
    designation: "",
  };

  // const { signupStepNo, isLoggedIn } = useSelector((state) => state.login);
  const signupStepNo =
    JSON.parse(localStorage.getItem("signup_process_no")) || 1;
  const [redirect] = useState(false);
  const [formDetails, setFormDetails] = useState({ ...initialFormDetails });
  const [nameError, setNameError] = useState(true);
  const [emailError, setEmailError] = useState(true);
  const [passwordError, setPasswordError] = useState(true);
  // const [value, setValue] = useState(0);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [code, setCode] = useState("");
  const [checked, setChecked] = useState(false);
  const [disableSendAgain, setDisableSendAgain] = useState(false);
  const [redirectToDetails, setRedirectToDetails] = useState(false);
  const [keepMeSignedIn, setKeepMeSignedIn] = useState(false);

  const [isForgetPasswordDialog, setForgetPasswordDialog] = useState(false);

  const handleSubmit = () => {
    const { fullName, email, password } = formDetails;
    let name = fullName;
    // signupWithEmail(name, email, password);
  };
  const handleOtpChange = (code) => {
    setCode(code);
  };
  const onFormChange = (e) => {
    const { name, value } = e.target;
    if (name === "FullName") {
      if (value === "") {
        setNameError(false);
      } else {
        setNameError(true);
      }
    }
    if (name === "lastName") {
      if (value === "") {
        setNameError(false);
      } else {
        setNameError(true);
      }
    }
    if (name === "email") {
      setEmailError(emailValidation(value));
    }
    if (name === "password") {
      setPasswordError(passwordValidation(value));
    }
    if (name === "confirmPassword") {
    }
    setFormDetails((formDetails) => ({
      ...formDetails,
      [name]: value,
    }));
  };

  const forgetPasswordDialog = () => {
    return (
      <Dialog
        open={isForgetPasswordDialog}
        onClose={() => setForgetPasswordDialog(false)}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle>
          Reset Password
          <Typography variant="body2">
            Please enter your email address to reset your password. We will send
            a reset link to your email address.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <CustomTextfield
            variant="outlined"
            placeholder="Enter your Email"
            fullWidth
            onKeyPress={(e) =>
              e.key === "Enter" &&
              dispatch(forgetPassword(email, window.location.hostname))
            }
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(forgetPassword(email, window.location.hostname));
              setForgetPasswordDialog(false);
              setEmail("");
            }}
            disabled={!emailValidation(email)}
          >
            reset password
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const isDisabled = () => {
    // console.log(formDetails);
    return formDetails.fullName.trim() !== "" &&
      formDetails.email !== "" &&
      emailError &&
      passwordError &&
      formDetails.password !== "" &&
      confirmPassword !== "" &&
      formDetails?.orgName.trim() !== "" &&
      checked &&
      formDetails.password === confirmPassword
      ? false
      : true;
  };

  const isLoginDisabled = () => {
    // console.log(formDetails);
    return formDetails.email !== "" &&
      emailError &&
      passwordError &&
      formDetails.password !== ""
      ? false
      : true;
  };

  const opts = {
    height: "340px",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  const onReady = (event) => {
    event.target.pauseVideo();
  };
  console.log("rihght", signupStepNo);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLogin, setLogin] = useState(false);

  const [otpEmail, setOtpEmail] = useState("");

  useEffect(() => {
    // const number = Number(localStorage.getItem("signup_process-no"));
    // dispatch(setSignupStepNo(number));
    setLogin(onlyLogin ? true : false);
  }, []);

  // set otpEmail
  useEffect(() => {
    if (formDetails.email !== "" && signupStepNo === 2) {
      setOtpEmail(formDetails.email);
    } else if (!formDetails.email && signupStepNo === 2) {
      setOtpEmail(localStorage.getItem("email_id"));
    }
  }, [formDetails.email, signupStepNo]);

  if (disableSendAgain) {
    setTimeout(() => {
      setDisableSendAgain(false);
    }, 1000 * 60);
  }
  return (
    <>
      {isForgetPasswordDialog && forgetPasswordDialog()}
      {/* {plan && <PlanExpired isOpen={plan} setOpen={() => setPlan(!plan)} />} */}
      {/* {plan && <Feedback additional isOpen={plan} setOpen={() => setPlan(!plan)} />} */}
      {/* {plan && <Feedback isOpen={plan} setOpen={() => setPlan(!plan)} />} */}
      {/* <Slide
        direction="down"
        in={true}
        mountOnEnter
        unmountOnExit
        timeout={700}
      > */}
      <Card variant="outlined" style={{ minWidth: "650px" }}>
        <CardContent className={classes.cardcontent}>
          {/* <Box className={classes.cardBox}>
             <List className={classes.cardList} component="nav"> */}
          <div>
            <div
              style={{
                textAlign: "center",
                borderBottom: "1px solid #C0D4E9",
                padding: "10px",
                backgroundColor: "#F6F6F6",
              }}
            >
              <Typography style={{ fontSize: 17, fontWeight: 600 }}>
                {isLogin ? "Login" : "Signup"}
              </Typography>
            </div>
            {/* <button onClick={() => setPlan(!plan)}>click to expire</button> */}
            <Slide
              direction="left"
              in={signupStepNo === 0}
              timeout={500}
              mountOnEnter
              unmountOnExit
            >
              <div style={{ padding: 10 }}>
                <div style={{ padding: "10px 00px 0px 20px" }}>
                  <Typography style={{ fontSize: 18, fontWeight: 600 }}>
                    Explore the Various details about all the companies ESG{" "}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 13,
                      fontWeight: 400,
                      color: "#676767",
                    }}
                  >
                    This will help us personalize your experience in GPT Wise.
                  </Typography>
                </div>
                <div style={{ padding: 5 }}>
                  <Card
                    elevation={3}
                    style={{
                      borderRadius: 25,
                      width: "100%",
                      height: 340,
                      margin: "18px 0px",
                    }}
                  >
                    <YouTube
                      videoId={"6ZZr9riOSls"}
                      opts={opts}
                      onReady={onReady}
                    />
                  </Card>
                </div>
                <div style={{ padding: 5 }}>
                  <Button
                    variant="contained"
                    style={{
                      padding: "5px 10px",
                      backgroundColor: "#3374B9",
                      color: "#FFFF",
                      textTransform: "none",
                      width: "100%",
                    }}
                    onClick={() => {
                      dispatch(setSignupStepNo(signupStepNo + 1));
                    }}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </Slide>
            <Slide
              direction="left"
              in={signupStepNo === 1}
              timeout={500}
              mountOnEnter
              unmountOnExit
            >
              {isLogin ? (
                <div style={{ padding: 10 }}>
                  <div style={{ padding: "10px 00px 0px 20px" }}>
                    <Typography style={{ fontSize: 18, fontWeight: 600 }}>
                      Welcome Back!
                    </Typography>
                    {/* <Typography
                      style={{
                        fontSize: 13,
                        fontWeight: 400,
                        color: "#676767",
                      }}
                    >
                      This will help us personalize your experience in GPT Wise.
                    </Typography> */}
                  </div>
                  <div style={{ padding: 5 }}>
                    <>
                      <Typography className={classes.labelText}>
                        Work Email
                      </Typography>
                      <CustomTextfield
                        id="email"
                        name="email"
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="email"
                        placeholder="@example.com"
                        value={formDetails.email}
                        error={!emailError}
                        helperText={
                          !emailError ? "Please enter a valid Email." : ""
                        }
                        onChange={onFormChange}
                        className={classes.costumTextField}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              name="passSvg"
                              position="end"
                              className={classes.inputStyle}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <img alt="img" src={mail} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Typography className={classes.labelText}>
                        Password
                      </Typography>
                      <CustomTextfield
                        id="password"
                        name="password"
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Min 8 Characters"
                        type={showPassword ? "text" : "password"}
                        error={!passwordError}
                        helperText={
                          !passwordError ? "Please enter a valid password." : ""
                        }
                        value={formDetails.password}
                        onChange={onFormChange}
                        className={classes.costumTextField}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              name="passSvg"
                              position="end"
                              className={classes.inputStyle}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <VisibilityIcon
                                  fontSize="small"
                                  style={{ color: "#3374B9" }}
                                />
                              ) : (
                                <VisibilityOffIcon
                                  fontSize="small"
                                  style={{ color: "#3374B9" }}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </>
                  </div>
                  <div style={{ padding: "0px 5px" }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      paddingTop="120px"
                    >
                      <Box display="flex" alignItems="center">
                        <Button
                          color="primary"
                          style={{ textTransform: "none" }}
                          onClick={() => setForgetPasswordDialog(true)}
                        >
                          Forgot Password?
                        </Button>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Button
                          // variant="contained"
                          style={{
                            padding: "5px 10px",
                            backgroundColor: "#FFFF",
                            color: "#3374B9",
                            textTransform: "none",
                            "&:hover": {
                              boxShadow: "1px 1px 1px black",
                            },
                            width: "100%",
                            maxWidth: "200px",
                            minWidth: "150px",
                          }}
                          // disabled={isDisabled()}
                          onClick={() => {
                            setLogin(!isLogin);
                            setFormDetails({ ...initialFormDetails });
                          }}
                        >
                          {!isLogin ? "Go To login" : "Go To Signup"}
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            padding: "5px 10px",
                            backgroundColor: isLoginDisabled()
                              ? "lightgrey"
                              : "#3374B9",
                            color: "#FFFF",
                            textTransform: "none",
                            width: "100%",
                            maxWidth: "220px",
                            minWidth: "180px",
                          }}
                          disabled={isLoginDisabled()}
                          onClick={() => {
                            dispatch(
                              gql_login_with_email(
                                formDetails.email,
                                formDetails.password
                              )
                            );
                            // dispatch(setSignupStepNo(4));
                          }}
                        >
                          {"Login"}
                        </Button>
                      </Box>
                      {/* <CustomButton
                        name="signupBtn"
                        variant="contained"
                        color="primary"
                        size="small"
                        disableElevation
                        className={classes.signupBtn}
                        disabled={isDisabled}
                        onClick={() => {
                          handleSubmit();
                          // setSideMessage("otp");
                        }}
                      >
                        Signup
                      </CustomButton> */}
                    </Box>
                  </div>
                </div>
              ) : (
                <div style={{ padding: 10 }}>
                  <div style={{ padding: "10px 00px 0px 20px" }}>
                    <Typography style={{ fontSize: 18, fontWeight: 600 }}>
                      We need a few details to get you started.
                    </Typography>
                    {/* <Typography
                      style={{
                        fontSize: 13,
                        fontWeight: 400,
                        color: "#676767",
                      }}
                    >
                      No subtext needed
                    </Typography> */}
                  </div>
                  <div style={{ padding: 5 }}>
                    <>
                      <Typography className={classes.labelText}>
                        Name
                      </Typography>
                      <CustomTextfield
                        type="text"
                        name="fullName"
                        variant="outlined"
                        size="small"
                        placeholder="Full Name"
                        fullWidth
                        value={formDetails.fullName}
                        onChange={onFormChange}
                        className={classes.costumTextField}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              name="passSvg"
                              position="end"
                              className={classes.inputStyle}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <img alt="img" src={user} />
                            </InputAdornment>
                          ),
                        }}
                      />

                      <Typography className={classes.labelText}>
                        Work Email
                      </Typography>
                      <CustomTextfield
                        id="email"
                        name="email"
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="email"
                        placeholder="@example.com"
                        value={formDetails.email}
                        error={!emailError}
                        helperText={
                          !emailError ? "Please enter a valid Email." : ""
                        }
                        onChange={onFormChange}
                        className={classes.costumTextField}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              name="passSvg"
                              position="end"
                              className={classes.inputStyle}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <img alt="img" src={mail} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Typography className={classes.labelText}>
                        Crearte Password
                      </Typography>
                      <CustomTextfield
                        id="password"
                        name="password"
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Min 8 Characters"
                        type={showPassword ? "text" : "password"}
                        error={!passwordError}
                        helperText={
                          !passwordError ? "Please enter a valid password." : ""
                        }
                        value={formDetails.password}
                        onChange={onFormChange}
                        className={classes.costumTextField}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              name="passSvg"
                              position="end"
                              className={classes.inputStyle}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <VisibilityIcon
                                  fontSize="small"
                                  style={{ color: "#3374B9" }}
                                />
                              ) : (
                                <VisibilityOffIcon
                                  fontSize="small"
                                  style={{ color: "#3374B9" }}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Typography className={classes.labelText}>
                        Confirm Password
                      </Typography>
                      <CustomTextfield
                        id="confirmpassword"
                        name="confirmpassword"
                        variant="outlined"
                        size="small"
                        error={
                          confirmPassword !== "" &&
                          confirmPassword !== formDetails.password
                        }
                        helperText={
                          confirmPassword !== formDetails.password
                            ? "Password and confirm password should match."
                            : ""
                        }
                        fullWidth
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        type={showConfirmPassword ? "text" : "password"}
                        className={classes.costumTextField}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              name="cnfPassSvg"
                              position="end"
                              className={classes.inputStyle}
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                            >
                              {showConfirmPassword ? (
                                <VisibilityIcon
                                  fontSize="small"
                                  style={{ color: "#3374B9" }}
                                />
                              ) : (
                                <VisibilityOffIcon
                                  fontSize="small"
                                  style={{ color: "#3374B9" }}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />

                      <div style={{ display: "flex", gap: 10 }}>
                        <div style={{ width: "50%" }}>
                          <Typography className={classes.labelText}>
                            Organization Name
                          </Typography>
                          <CustomTextfield
                            type="text"
                            name="orgName"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={formDetails.orgName}
                            onChange={onFormChange}
                            className={classes.costumTextField}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  name="passSvg"
                                  position="end"
                                  className={classes.inputStyle}
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  <img alt="img" src={brief} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div style={{ width: "50%" }}>
                          <Typography className={classes.labelText}>
                            Designation (Optional)
                          </Typography>
                          <CustomTextfield
                            type="text"
                            name="designation"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={formDetails.designation}
                            onChange={onFormChange}
                            className={classes.costumTextField}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  name="passSvg"
                                  position="end"
                                  className={classes.inputStyle}
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  <img alt="img" src={brief} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </>
                  </div>
                  <div style={{ padding: "0px 5px" }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      paddingTop={isLogin ? "40px" : 0}
                    >
                      {!isLogin && (
                        <Box display="flex" alignItems="center">
                          <Checkbox
                            name="signupCheckbox"
                            color="primary"
                            disableRipple
                            checked={checked}
                            onChange={(e) => setChecked(e.target.checked)}
                          />
                          <a
                            href="/tnc"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none" }}
                          >
                            <Typography className={classes.checkboxLabelText}>
                              I accept to Data & Privacy terms
                            </Typography>
                          </a>
                        </Box>
                      )}
                      <Button
                        // variant="contained"
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#FFFF",

                          color: "#3374B9",
                          textTransform: "none",
                          "&:hover": {
                            boxShadow: "1px 1px 1px black",
                          },
                          // width: "100%",
                          // maxWidth: "250px",
                          // minWidth: "100px",
                        }}
                        // disabled={isDisabled()}
                        onClick={() => {
                          setLogin(!isLogin);
                          setFormDetails({ ...initialFormDetails });
                        }}
                      >
                        {!isLogin ? "Go To login" : "Go To Signup"}
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          padding: "5px 10px",
                          backgroundColor: isDisabled()
                            ? "lightgrey"
                            : "#3374B9",
                          color: "#FFFF",
                          textTransform: "none",
                          width: "100%",
                          maxWidth: "200px",
                          minWidth: "100px",
                        }}
                        // disabled={isDisabled()}
                        onClick={() => {
                          if (isLogin) {
                            dispatch(
                              login_with_email(
                                formDetails.email,
                                formDetails.password
                              )
                            );
                          } else {
                            // dispatch(setSignupStepNo(signupStepNo + 1));
                            dispatch(
                              gql_vendor_signup(
                                formDetails.fullName,
                                formDetails.email,
                                formDetails.orgName,
                                formDetails.password,
                                formDetails.designation
                              )
                            );
                          }
                          // setSignupStepNo(signupStepNo + 1);
                        }}
                      >
                        {isLogin ? "Login" : "Signup"}
                      </Button>
                    </Box>
                  </div>
                </div>
              )}
            </Slide>

            <Slide
              direction="left"
              in={signupStepNo == 2}
              timeout={500}
              mountOnEnter
              unmountOnExit
            >
              {
                <div style={{ padding: 10 }}>
                  <div style={{ padding: "10px 00px 0px 20px" }}>
                    <Typography style={{ fontSize: 18, fontWeight: 600 }}>
                      OTP
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 13,
                        fontWeight: 400,
                        color: "#676767",
                      }}
                    >
                      Please verify to login.
                    </Typography>
                  </div>
                  <div style={{ padding: 5 }}>
                    <div style={{ paddingLeft: 25 }}>
                      <Typography className={classes.codeTitle}>
                        Enter the 4 digit code sent to
                      </Typography>
                      <span className={classes.otpSpan}>
                        {/* {formDetails.email
                          ? formDetails.email
                          : localStorage.getItem("email_id")} */}

                        {otpEmail}
                      </span>
                    </div>
                    <Box paddingTop="56px" paddingBottom="24px">
                      <Slide
                        direction="left"
                        in={signupStepNo === 2}
                        timeout={1200}
                        mountOnEnter
                        unmountOnExit
                      >
                        {/* <Typography>vshm ;h re</Typography> */}
                        <OtpInput
                          value={code}
                          onChange={handleOtpChange}
                          numInputs={4}
                          separator={
                            <span className={classes.otpSeperator}></span>
                          }
                          isInputNum={true}
                          // shouldAutoFocus={true}
                          inputStyle={{
                            borderRadius: "4px",
                            border: "1px solid gray",
                            width: "56px",
                            height: "56px",
                            fontSize: "16px",
                            color: "#000",
                            fontWeight: "400",
                            caretColor: "blue",
                          }}
                          focusStyle={{
                            border: "1px solid #CFD3DB",
                            outline: "none",
                          }}
                        />
                      </Slide>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Typography className={classes.didntReceive}>
                        Didn't recieve ?
                      </Typography>
                      <CustomButton
                        color="primary"
                        disabled={disableSendAgain}
                        className={classes.sendAgain}
                        onClick={() => {
                          dispatch(resendOtp(otpEmail));
                          setDisableSendAgain(true);
                        }}
                      >
                        Send again
                      </CustomButton>
                      Or
                      <CustomButton
                        color="primary"
                        disabled={disableSendAgain}
                        className={classes.sendAgain}
                        onClick={() => {
                          localStorage.clear();
                          dispatch(setSignupStepNo(signupStepNo - 1));
                        }}
                      >
                        Go Back
                      </CustomButton>
                    </Box>
                  </div>

                  <div style={{ padding: "0px 5px", paddingTop: "10%" }}>
                    <Button
                      variant="contained"
                      style={{
                        padding: "5px 10px",
                        backgroundColor: "#3374B9",
                        color: "#FFFF",
                        textTransform: "none",
                        width: "100%",
                      }}
                      onClick={() => {
                        dispatch(
                          gql_verify_otp(localStorage.getItem("user_id"), code)
                        );
                      }}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              }
            </Slide>

            <Fade
              // direction="left"
              in={signupStepNo == 3}
              timeout={100}
              mountOnEnter
              unmountOnExit
            >
              <div style={{ padding: 10 }}>
                <div style={{ padding: "10px 00px 0px 20px" }}>
                  <Typography style={{ fontSize: 18, fontWeight: 600 }}>
                    ESG Intelligent AI Chatbot
                  </Typography>
                  {/* <Typography
                    style={{
                      fontSize: 13,
                      fontWeight: 400,
                      color: "#676767",
                    }}
                  >
                    Click the prompts below or type in your question to get started.
                  </Typography> */}
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", textAlign: "center" }}>
                    <img src={Explore} alt="Explore" style={{ width: "80%" }} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                      padding: 5,
                      // alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      style={{ fontSize: 13, fontWeight: 600, marginLeft: 5 }}
                    >
                      Here are some examples of what you can do:
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "14px 0px",
                      }}
                    >
                      <Avatar
                        src={greenTick}
                        style={{ width: 26, height: 26 }}
                      />
                      <Typography
                        style={{ fontSize: 13, fontWeight: 500, marginLeft: 5 }}
                      >
                        Review the our list of ESG Profiles
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "14px 0px",
                      }}
                    >
                      <Avatar
                        src={greenTick}
                        style={{ width: 26, height: 26 }}
                      />
                      <Typography
                        style={{ fontSize: 13, fontWeight: 500, marginLeft: 5 }}
                      >
                        Ask any under the sun with regards to ESG
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "14px 0px",
                      }}
                    >
                      <Avatar
                        src={greenTick}
                        style={{ width: 26, height: 26 }}
                      />
                      <Typography
                        style={{ fontSize: 13, fontWeight: 500, marginLeft: 5 }}
                      >
                        Request us to Build an ESG profile for Custom Company
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className={classes.topicCont}>
                  <div
                    role="button"
                    onClick={() => {
                      dispatch(setSignupStepNo(signupStepNo + 1));

                      setTimeout(() => {
                        // dispatch(setSignupStepNo(4));
                        dispatch(setSignupStepNo(signupStepNo + 1));
                        history.push("/esg_gpt/company_profiles");
                      }, 1600);
                    }}
                    className={classes.topic}
                  >
                    <Typography style={{ fontSize: 13, fontWeight: 500 }}>
                      Start Exploring
                    </Typography>
                    <KeyboardArrowRight className={classes.topicArrow} />
                  </div>
                </div>
              </div>
            </Fade>
            <Grow
              // direction="left"
              in={signupStepNo === 4}
              timeout={1500}
              mountOnEnter
              unmountOnExit
            >
              <div style={{ padding: 10 }}>
                <div style={{ padding: 5, textAlign: "center" }}>
                  <img src={Explore} alt="Explore" style={{ width: "60%" }} />
                </div>
              </div>
            </Grow>
          </div>
        </CardContent>
      </Card>
      {/* </Slide> */}
    </>
  );
}
export default React.memo(SignupCard);
