import { gql } from "@apollo/client";

export const REFRESH_TOKEN = gql`
  query RefreshTokens {
    refreshTokens {
      accessToken
      user {
        _id
        name
        email
        bank {
          _id
          details
          industry
          name
          sector
          website
        }
        active
        designation
        is_premium
        role
        type
      }
    }
  }
`;

export const LOGOUT = gql`
  query Logout {
    logout
  }
`;

export const LOGIN_WITH_EMAIL = gql`
  mutation Login($email: String!, $password: String!) {
    login(userDetails: { email: $email, password: $password }) {
      accessToken
      user {
        _id
        name
        email
        bank {
          _id
          details
          industry
          name
          sector
          website
        }
        active
        designation
        is_premium
        role
        type
      }
    }
  }
`;

export const SIGNUP = gql`
  mutation Signup(
    $name: String!
    $email: String!
    $organization_name: String!
    $password: String!
    $designation: String!
  ) {
    signup(
      userDetails: {
        designation: $designation
        email: $email
        name: $name
        organization_name: $organization_name
        password: $password
      }
    ) {
      message
      success
      userId
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation VerifyOtp($id: String!, $otp: Int!) {
    checkOtp(id: $id, otp: $otp) {
      accessToken
      message
      success
      user {
        _id
        name
        email
        bank {
          _id
          details
          industry
          name
          sector
          website
        }
        active
        designation
        is_premium
        role
      }
    }
  }
`;
