import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import NavCustomDrawer from "../UI/drawer/drawer";
import CustomAppBar from "../UI/appBar/AppBar";
import Login from "../../containers/resetPasswordCont/resetPasswordCont";
// import LoginCon from "../../containers/login/login-cont";
// import SurveyListingCont from "../../containers/surveyListing/surveyListingCont";
// import ReportListingCont from "../../containers/reportListing/reportListingCont";
// import ESGReportListingCont from "../../containers/esrReportListing/esgReportListingCont";
// import SingleESGReportCont from "../../containers/singleESGReport/sigleESGReportCont";
// import MetricReport from "../screen/singleESGReport/metricReport";
// import SustainabilityReportCont from "../../containers/SustainabilityReportMain/SustainabilityReportMainCont";
import "../router/router.css";
import MobileDrawer from "../UI/menuBar/menuBar";
// import OnboaringCon from "../../containers/onboarding/onboarding-cont";
// import signupContainer from "../../containers/signup/signupContainer";
import Controller_con from "../../containers/router/controllerCont";
import LoaderCon from "../../containers/loader/loader_cont";
import SnackbarCont from "../../containers/snackbar/snackbarCont";
// import EsgReport from "../widgets/esgReport";
// import Drawer from "../widgets/policies/drawer";
// import FAQ from "../widgets/policies/faq";
// import GeneralSettingsCont from "../../containers/generalSettings/generalSettingsCont";
// import ResetPasswordCon from "./../../containers/resetPasswordCont/resetPasswordCont";
// import OnboardinTour from "../screen/onboardinTour/onboardinTour";
// import BasicDetailsCont from "../../containers/basicDetails/basicDetailsCont";
// import GetStarted from "../screen/getStarted/getStarted";
// import SurveyUp from "../UI/maturityMatrix/SurveyUp";
// import Survey from "../UI/maturityMatrix/Survey";
// import GetStartedCont from "../../containers/getStarted/getStartedCont";
// import TnC from "../widgets/policies/T&C";
// import Privacy from "../widgets/policies/privacy";
// import DataHubCont from "../../containers/dataHub/dataHubCont";
// import DataHubSurveyCont from "../../containers/dataHub/dataHubSurveyCont";
// import AppDashBoard from "../UI/dataHubCalculatedCharts/AppDashBoard";
// import { DashBoardScreen } from "../screen/dashboard/dashboard";
// import DashboardCont from "../../containers/dashboard/dashboardCont";
// import riskAssessmentCont from "../../containers/riskAssessment/riskAssessmentCont";
// import CorporateCont from "../../containers/riskAssessment/corporateCont";
// import CorporateEvaluatorCont from "../../containers/riskAssessment/corporateEvaluatorCont";
// import BrmDetailsCont from "../../containers/riskAssessment/brmDetailsCont";
import LoginCont from "../../containers/login/LoginCont";
// import CorporateSurveyTaskCont from "../../containers/corporateSurveyTaskCont/corporateSurveyTaskCont";
import ResetPasswordCon from "../../containers/resetPasswordCont/resetPasswordCont";
// import RiskDashboardCont from "../../containers/riskDashboardCont/riskDashboardCont";
// import RiskDocument from "../widgets/riskDashboard/riskDocument";
// import riskDocumentCont from "../../containers/riskDashboardCont/riskDocumentCont";
// import EsgGptMain from "../screen/esgGpt/EsgGptMain";
// import EsgGptCompanyProfiles from "../widgets/esgGpt/esgGptCompanyProfile/esgGptCompanyProfiles";
// import EsgGptAddNewCompany from "../widgets/esgGpt/esgGptAddNewCompany/EsgGptAddNewCompany";
// import EsgGptChat from "../widgets/esgGpt/esgGptChat/EsgGptChat";
import RiskSurveyContainer from "../screen/riskSurvey/RiskSurveyContainer";
import RiskSurveyInnerContainer from "../screen/riskSurvey/RiskSurveyInnerContainer";
import VendorScreen from "../screen/vendor/VendorScreen";
import VendorCont from "../../containers/vendorCont/vendorCont";
import VendorOrganizations from "../screen/vendor/VendorOrganizations";
import VendorOrganizationsCont from "../../containers/vendorCont/vendorOrganizationsCont";
import signupCont from "../../containers/signup/signupCont";
import PolicyDrawer from "../widgets/policies/policyDrawer";
import corpotateCont from "../../containers/corpCont/corpotateCont";
import CorporateSurveyTaskCont from "../../containers/corporateSurveyTaskCont/corporateSurveyTaskCont";
import vendorAdmin from "../../containers/vendorAdminCont/vendorAdmin";
import SurveySteps from "../widgets/vendorAdmin/surveySteps";
import SurveyMain from "../widgets/vendorAdmin/selectedSurvey";
import GeneralSettings from "../screen/generalSettings/generalSettings";
import taskContainer from "../../containers/taskCont/taskContainer";
import { SelectedTaskMain, TaskSurveyMain } from "../widgets/taskSections";
import VendorDashboard from "../screen/vendorDashboard/VendorDashboard";
import SurveyCompletedSteps from "../screen/vendorAdminScreen/SurveyCompletedSteps";
// import SignupCont from "../../containers/signup/signupCont";

export const MContext = React.createContext();

class Router extends React.Component {
  autoMateLogout = () => {
    const { setLogOut, set_snack_bar } = this.props;
    localStorage.removeItem("user_token");
    setLogOut();
    set_snack_bar(true, "Please login again");
    return <Redirect to="/login" />;
  };

  state = {
    isOpen: false,
    updatePath: false,
    addNewCompany: false,
  };

  cb = (setIsOpen) => {
    this.setState({ isOpen: setIsOpen });
  };

  // componentDidUpdate() {
  //   if (
  //     false &&
  //     this.props.login.isLoggedIn === true &&
  //     window.location.pathname === "/add_company" &&
  //     !this.state.addNewCompany
  //   ) {
  //     this.setState({
  //       addNewCompany: true,
  //     });
  //   }
  // }

  render() {
    const { login, logOut } = this.props;
    // const userType = "gpt";
    // const userType = localStorage.getItem("user_type");
    const userType = login.user_type;

    const signup_process_no = JSON.parse(
      localStorage.getItem("signup_process_no")
    );
    // if (snackbar.response_received && snackbar.message === "Invalid user") {
    // this.autoMateLogout();
    // }

    let body;
    // if (true) {

    if (login.isLoggedIn === false && !login.token) {
      body = (
        <div>
          <Route path="/" component={Controller_con} />
          <Route exact path="/" component={signupCont} />
          {/* <Route
            exact
            path="/login"
            render={() => <SignupCont onlyLogin={true} {...this.props} />}
          /> */}
          <Route path="/login" component={LoginCont} />
          <Route exact path="/reset_password" component={ResetPasswordCon} />
          <Route
            exact
            path="/privacy_policy"
            render={() => <PolicyDrawer {...this.props} />}
          />
          <Route
            exact
            path="/faq"
            render={() => <PolicyDrawer {...this.props} />}
          />
          <Route
            exact
            path="/tnc"
            render={() => <PolicyDrawer {...this.props} />}
          />
          {window.location.pathname === "/privacy_policy" && (
            <Route render={() => <Redirect to="/privacy_policy" />} />
          )}
          {window.location.pathname === "/tnc" && (
            <Route render={() => <Redirect to="/tnc" />} />
          )}
          {window.location.pathname === "/faq" && (
            <Route render={() => <Redirect to="/faq" />} />
          )}
        </div>
      );
    } else if (login.isLoggedIn === true || login.token) {
      body = (
        <Switch>
          <div
            className={
              signup_process_no > 3 && window.location.pathname !== "/esg_gpt"
                ? "div-post-login"
                : ""
            }
          >
            {/* {console.log(login.signupStepNo)} */}
            {userType !== "gpt" && (
              <NavCustomDrawer
                isOpen={this.isOpen}
                setIsOpen={this.cb}
                logOut={logOut}
                token={login.token}
                user_type={login.user_type}
                login={login}
                // redirect={surveyListing.redirect_value}
                setRedirectValue={this.props.setRedirectValue}
              />
            )}
            {userType !== "gpt" && (
              <CustomAppBar
                logOut={logOut}
                isOpen={this.state.isOpen}
                {...this.props}
              />
            )}
            {userType !== "gpt" && <MobileDrawer />}
            {/* <NavCustomDrawer
              isOpen={this.isOpen}
              setIsOpen={this.cb}
              logOut={logOut}
              token={login.token}
              user_type={login.user_type}
              login={login}
              // redirect={surveyListing.redirect_value}
              setRedirectValue={this.props.setRedirectValue}
            />
            <CustomAppBar
              logOut={logOut}
              isOpen={this.state.isOpen}
              {...this.props}
            />
            <MobileDrawer /> */}
            <Route path="/" component={Controller_con} />
            {/* <Route exact path="/esg_gpt" component={signupCont} /> */}
            <Route exact path="/signup" component={signupCont} />

            {/* Bank Admin Routes */}
            <Route
              exact
              path="/vendor_dashboard"
              render={() => <VendorDashboard isVendor={true} {...this.props} />}
            />
            <Route
              exact
              path="/vendor"
              render={() => (
                <VendorOrganizationsCont isVendor={true} {...this.props} />
              )}
            />

            <Route
              path="/vendor/company_details"
              render={() => <VendorCont {...this.props} />}
            />

            <Route
              exact
              path="/vendor_survey"
              component={RiskSurveyContainer}
            />
            <Route
              exact
              path="/vendor_survey/survey_details"
              component={RiskSurveyInnerContainer}
            />

            {/* Corporate Routes */}
            <Route
              exact
              path="/corporate_questions"
              component={corpotateCont}
            />
            <Route
              exact
              path="/corporate_questions/survey"
              render={() => (
                <CorporateSurveyTaskCont isEvaluator={true} {...this.props} />
              )}
            />
            <Route
              exact
              path="/general_settings"
              render={() => <GeneralSettings {...this.props} />}
            />

            {/* Vendor Admin Routes */}
            <Route exact path="/vendor_admin" component={vendorAdmin} />
            <Route
              exact
              path="/vendor_admin/:survey"
              render={() => <SurveyMain />}
            />
            <Route
              exact
              path="/vendor_admin/:survey/:assigned"
              render={() => <SurveySteps />}
            />

            <Route
              exact
              path="/vendor_admin/:survey/:assigned/completed"
              render={() => <SurveyCompletedSteps />}
            />

            {/* Task Routes */}
            <Route exact path="/task" component={taskContainer} />
            <Route
              exact
              path="/task/:selected"
              render={() => <SelectedTaskMain />}
            />
            <Route
              exact
              path="/task/:selected/:survey"
              render={() => <TaskSurveyMain />}
            />

            {/* User Type Redirect */}
            {userType === "BA" && (
              <Route render={() => <Redirect to="/vendor" />} />
            )}
            {userType === "corp" && (
              <Route render={() => <Redirect to="/corporate_questions" />} />
            )}
            {userType === "VA" && (
              <Route render={() => <Redirect to="/vendor_admin" />} />
            )}

            {userType === "VG" && (
              <Route render={() => <Redirect to="/task" />} />
            )}

            <Route
              exact
              path="/privacy_policy"
              render={() => <PolicyDrawer {...this.props} />}
            />
            <Route
              exact
              path="/faq"
              render={() => <PolicyDrawer {...this.props} />}
            />
            <Route
              exact
              path="/tnc"
              render={() => <PolicyDrawer {...this.props} />}
            />
            {window.location.pathname === "/privacy_policy" && (
              <Route render={() => <Redirect to="/privacy_policy" />} />
            )}
            {window.location.pathname === "/tnc" && (
              <Route render={() => <Redirect to="/tnc" />} />
            )}
            {window.location.pathname === "/faq" && (
              <Route render={() => <Redirect to="/faq" />} />
            )}

            {/* <Route exact path="/drawer" component={Drawer} /> */}
            {/* <Route exact path="/tnc" component={FAQ} /> */}
            {window.location.pathname === "/vendor" && (
              <Route render={() => <Redirect to="/vendor" />} />
            )}
            {window.location.pathname === "/vendor_admin" && (
              <Route render={() => <Redirect to="/vendor_admin" />} />
            )}
            {window.location.pathname === "/corporate_questions" && (
              <Route render={() => <Redirect to="/corporate_questions" />} />
            )}
          </div>
        </Switch>
      );
    }

    return (
      <BrowserRouter>
        <SnackbarCont />
        <LoaderCon {...this.props} />
        {body}
      </BrowserRouter>
    );
  }
}
export default Router;
