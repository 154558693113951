import React from "react";

import StepRight from "../../stepLayout/right/StepRight";
import { Box, Typography } from "@material-ui/core";
import { BlueBackgroundCard } from "../../../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { Question } from "../../../../selfAssessment/selfAssessmentResponse";
import OptimizeInitialRender from "../../../../riskSurveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/OptimizeInitialRender";

function Step4Right({ response}) {
  const nonClickableStyle = {
    pointerEvents: "none",
  };
  return (
    <StepRight>
      <>
        {/* header
        <Box
          style={{
            border: "1px solid #A1BFDF",
            backgroundColor: "#EBF1F8",
            borderRadius: "8px",
            padding: "20px",
          }}
        >
          <Typography
            style={{ color: "#15314E", fontSize: "16px", fontWeight: 600 }}
          >
            Company Profile{" "}
          </Typography>
          <Typography
            style={{ color: "#245283", fontSize: "14px", fontWeight: 400 }}
          >
            Shape the narrative of your company's sustainability journey by
            providing detailed insights in our Company Profile Section,
            capturing the essence of your environmental, social, and governance
            practices.{" "}
          </Typography>
        </Box>
        {/* body 
        <Box style={{ overflow: "scroll", height: "75%", }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px",
              gap: 20,
            }}
          >
            <Box
              style={{
                border: "1px solid #E9E9E9",
                borderRadius: "8px",
                padding: "10px",
              }}
            >
              <Typography
                style={{ color: "#245283", fontSize: "14px", fontWeight: 400 }}
              >
                Shape the narrative of your company's sustainability journey by
                providing detailed insights in our Company Profile Section,
                capturing the essence of your environmental, social, and
                governance practices.{" "}
              </Typography>
            </Box>
            <Box
              style={{
                border: "1px solid #E9E9E9",
                borderRadius: "8px",
                padding: "10px",
              }}
            >
              <Typography
                style={{ color: "#245283", fontSize: "14px", fontWeight: 400 }}
              >
                NexaGen Innovations is a cutting-edge technology company poised to
                revolutionize industries through innovative solutions. Our scope
                of services encompasses advanced software development, artificial
                intelligence applications, and transformative digital strategies.
                We specialize in creating bespoke solutions tailored to clients'
                unique needs, ensuring seamless integration and maximum
                efficiency. NexaGen offers expert consultancy in emerging
                technologies, providing strategic insights to drive business
                growth. Our commitment extends to delivering top-tier
                cybersecurity solutions and robust IT infrastructure support. With
                a focus on sustainability, we integrate eco-friendly practices
                into our services, aligning with global environmental standards.
                NexaGen Innovations is your partner in navigating the digital
                future, offering comprehensive services that redefine industry
                standards and drive sustained success.
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px",
              gap: 20,
            }}
          >
            <Box
              style={{
                border: "1px solid #E9E9E9",
                borderRadius: "8px",
                padding: "10px",
              }}
            >
              <Typography
                style={{ color: "#245283", fontSize: "14px", fontWeight: 400 }}
              >
                Shape the narrative of your company's sustainability journey by
                providing detailed insights in our Company Profile Section,
                capturing the essence of your environmental, social, and
                governance practices.{" "}
              </Typography>
            </Box>
            <Box
              style={{
                border: "1px solid #E9E9E9",
                borderRadius: "8px",
                padding: "10px",
              }}
            >
              <Typography
                style={{ color: "#245283", fontSize: "14px", fontWeight: 400 }}
              >
                NexaGen Innovations is a cutting-edge technology company poised to
                revolutionize industries through innovative solutions. Our scope
                of services encompasses advanced software development, artificial
                intelligence applications, and transformative digital strategies.
                We specialize in creating bespoke solutions tailored to clients'
                unique needs, ensuring seamless integration and maximum
                efficiency. NexaGen offers expert consultancy in emerging
                technologies, providing strategic insights to drive business
                growth. Our commitment extends to delivering top-tier
                cybersecurity solutions and robust IT infrastructure support. With
                a focus on sustainability, we integrate eco-friendly practices
                into our services, aligning with global environmental standards.
                NexaGen Innovations is your partner in navigating the digital
                future, offering comprehensive services that redefine industry
                standards and drive sustained success.
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px",
              gap: 20,
            }}
          >
            <Box
              style={{
                border: "1px solid #E9E9E9",
                borderRadius: "8px",
                padding: "10px",
              }}
            >
              <Typography
                style={{ color: "#245283", fontSize: "14px", fontWeight: 400 }}
              >
                Shape the narrative of your company's sustainability journey by
                providing detailed insights in our Company Profile Section,
                capturing the essence of your environmental, social, and
                governance practices.{" "}
              </Typography>
            </Box>
            <Box
              style={{
                border: "1px solid #E9E9E9",
                borderRadius: "8px",
                padding: "10px",
              }}
            >
              <Typography
                style={{ color: "#245283", fontSize: "14px", fontWeight: 400 }}
              >
                NexaGen Innovations is a cutting-edge technology company poised to
                revolutionize industries through innovative solutions. Our scope
                of services encompasses advanced software development, artificial
                intelligence applications, and transformative digital strategies.
                We specialize in creating bespoke solutions tailored to clients'
                unique needs, ensuring seamless integration and maximum
                efficiency. NexaGen offers expert consultancy in emerging
                technologies, providing strategic insights to drive business
                growth. Our commitment extends to delivering top-tier
                cybersecurity solutions and robust IT infrastructure support. With
                a focus on sustainability, we integrate eco-friendly practices
                into our services, aligning with global environmental standards.
                NexaGen Innovations is your partner in navigating the digital
                future, offering comprehensive services that redefine industry
                standards and drive sustained success.
              </Typography>
            </Box>
          </Box>
        </Box> */}
        <Box style={{ overflow: "scroll", height: "90%",}}>
          <OptimizeInitialRender chunkSize={1}>
            {Object.keys(response).map((question, index) => {
              return (
                <div key={index} style={nonClickableStyle}>
                  <BlueBackgroundCard heading={question} />
                  <OptimizeInitialRender chunkSize={1}>
                    {response[question].map((question) => (
                      <Question
                        key={question?._id}
                        question={question.question}
                        options={question.answers}
                        type={question.question_type}
                        weightage={question.weightage}
                        submittedAnswer={question.submitted_answer}
                        removeEditOption={true}
                        comment={question?.comment}
                      />
                    ))}
                  </OptimizeInitialRender>
                </div>
              );
            })}
          </OptimizeInitialRender>
        </Box>
      </>
    </StepRight>
  );
}

export default Step4Right;
