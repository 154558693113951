import React from "react";

import { Grid, Paper, Typography, Box } from "@material-ui/core";
import ScoreChart from "./ScoreChart";
import { getColoredLogo } from "../../../utils/riskUtils/riskUtils";
import { green } from "@material-ui/core/colors";
import { TopicData, TopicHeader } from "./esgComponents";
import VendorScoreChart from "./VendorScoreChart";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";

function VendorScore(props) {
  // const { scores } = props;
  // const { environment, social, governance } = scores?.scoreObject;
  const { scoreObject } = props?.scores;
  // const { environment, social, governance } = scores?.scoreObject;

  // const TopicHeader = ({ topic, score }) => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "space-between",
  //         width: "100%",
  //         borderBottom: "1px solid lightblue",
  //         marginTop: "10px",
  //       }}
  //     >
  //       <div style={{ display: "flex", alignItems: "center" }}>
  //         <img src={getColoredLogo(topic)} alt="leaf" />
  //         <Typography style={{ fontSize: "14px", fontWeight: 600 }}>
  //           {topic}
  //         </Typography>
  //       </div>
  //       <div>
  //         <Typography style={{ fontSize: "22px", fontWeight: 600 }}>
  //           {score}
  //         </Typography>
  //       </div>
  //     </div>
  //   );
  // };
  // const TopicData = ({ topic, score }) => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "space-between",
  //         width: "100%",
  //         marginTop: "8px",
  //       }}
  //     >
  //       <div style={{ display: "flex", alignItems: "center" }}>
  //         <Typography
  //           style={{ fontSize: "14px", marginLeft: "5px", fontWeight: 400 }}
  //         >
  //           {topic}
  //         </Typography>
  //       </div>
  //       <div>
  //         <Typography style={{ fontSize: "16px", fontWeight: 400 }}>
  //           {score}
  //         </Typography>
  //       </div>
  //     </div>
  //   );
  // };

  const dataNew = [
    {
      topic: "Security",
      score: scoreObject?.security?.score?.toFixed(2),
      subTopic: [
        {
          topic: "Security Analytics",
          score: scoreObject?.security?.data_points?.analytics?.toFixed(2),
        },
        {
          topic: "Intrusion Detection",
          score: scoreObject?.security?.data_points?.intrusion?.toFixed(2),
        },
        {
          topic: "Log Data Analysis",
          score: scoreObject?.security?.data_points?.log?.toFixed(2),
        },
        {
          topic: "File Integrity Monitoring",
          score: scoreObject?.security?.data_points?.file?.toFixed(2),
        },
        {
          topic: "Vulnerability Detection",
          score: scoreObject?.security?.data_points?.vulnerablity?.toFixed(2),
        },
        {
          topic: "Incident Response",
          score: scoreObject?.security?.data_points?.incident?.toFixed(2),
        },
        {
          topic: "Container security",
          score: scoreObject?.security?.data_points?.container?.toFixed(2),
        },
        {
          topic: "Cloud Security",
          score: scoreObject?.security?.data_points?.cloud?.toFixed(2),
        },
      ],
    },
    {
      topic: "Compliance",
      score: scoreObject?.compilance?.score?.toFixed(2),
      subTopic: [
        {
          topic: "Configure Assessment",
          score: scoreObject?.compilance?.data_points?.configure?.toFixed(2),
        },
        {
          topic: "Regulatory Compliance",
          score: scoreObject?.compilance?.data_points?.regulatory?.toFixed(2),
        },
        {
          topic: "Vendor Risk Management Standards",
          score: scoreObject?.compilance?.data_points?.vendor?.toFixed(2),
        },
        {
          topic: "Audit Trails",
          score: scoreObject?.compilance?.data_points?.audit?.toFixed(2),
        },
        {
          topic: "Incident Response Plan",
          score: scoreObject?.compilance?.data_points?.incident?.toFixed(2),
        },
        {
          topic: "Data Residency and Sovereignty",
          score: scoreObject?.compilance?.data_points?.data?.toFixed(2),
        },
        {
          topic: "Consent Management",
          score: scoreObject?.compilance?.data_points?.concent?.toFixed(2),
        },
      ],
    },
  ];
  return (
    <div style={{ paddingBottom: "18px", marginTop: "30px", width: "100%" }}>
      {" "}
      <Grid container md={12}>
      <Grid item md={7} sm={12} > 
          {/* <Typography
            style={{ fontSize: "13px", padding: "5px" }}
            component={Paper}
          >
            The score reflects the company's ESG performance and can guide
            stakeholders in making informed decisions. (Out of 100) 54
          </Typography> */}
          <div style={{ display: "flex", flexWrap: "wrap",gap:5 }}>
            {dataNew.map((data) => {
              return (
                <div style={{ width: "49%",borderRadius:8 ,  backgroundColor:'#FBFBFB',paddingBottom:5}}>
                  <TopicHeader topic={data.topic} score={data.score} />
                  {data.subTopic.map((sub) => (
                    <TopicData topic={sub.topic} score={sub.score} />
                  ))}
                </div>
              );
            })}
          </div>
        </Grid>
        <Grid item md={5} sm={12}>
        {<VendorScoreChart scores={props?.scores} />}
        </Grid>
       
        {/* vendor scrore */}
        <BlueBackgroundCard heading={"Vendor Score"} />
        <Box
          style={{
            padding: 5,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography variant="h4">54%</Typography>
          <Typography variant="body2">
            Suporting text to the ESG Split score explaining Suporting text to
            the ESG Split score explaining{" "}
          </Typography>
        </Box>
      </Grid>
    </div>
  );
}

export default VendorScore;
