import React from "react";

import { Box, Grid } from "@material-ui/core";
import OptimizeInitialRender from "../../widgets/riskSurveyComponents/Inner/Elements/MainTabs/ResponseComponents/DrawerSections/OptimizeInitialRender";
import { response1 } from "../../widgets/riskSurveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionData";
import { BlueBackgroundCard } from "../../widgets/esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { Question } from "../../widgets/selfAssessment/selfAssessmentResponse";
import MainHeader from "./MainHeader";

function SurveyCompletedSteps() {
    const nonClickableStyle = {
        pointerEvents: "none",
      };
  return (
    <>
    <MainHeader/>
     <Grid
      container
      style={{ height: "85vh", padding: "10px" }}
      justifyContent="space-between"
    >
      <Grid
        item
        md={12}
        style={{
          height: "100%",
          padding: "10px",
          borderRadius: "8px",
          boxShadow: "0px 0px 8px 0px rgba(51, 116, 185, 0.25)",
        }}
      >
        <Box style={{ overflow: "scroll", height: "90%" }}>
          <OptimizeInitialRender chunkSize={1}>
            {Object.keys(response1).map((question, index) => {
              return (
                <div key={index} style={nonClickableStyle}>
                  <BlueBackgroundCard heading={question} />
                  <OptimizeInitialRender chunkSize={1}>
                    {response1[question].map((question) => (
                      <Question
                        key={question?._id}
                        question={question.question}
                        options={question.answers}
                        type={question.question_type}
                        weightage={question.weightage}
                        submittedAnswer={question.submitted_answer}
                        removeEditOption={true}
                        comment={question?.comment}
                      />
                    ))}
                  </OptimizeInitialRender>
                </div>
              );
            })}
          </OptimizeInitialRender>
        </Box>
      </Grid>
    </Grid>
    </>
   
  );
}
export default SurveyCompletedSteps;
