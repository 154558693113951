import { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import SectionComplete from "./RightPanelComps/SectionComplete";
import RightSections from "./RightPanelComps/RightSections";
import CustomButton from "../../../UI/button/button";
import { useDispatch } from "react-redux";
import { UpdateSurveyComplete } from "../../../../redux/actions/taskActions/TaskAction";
import RedoDialog from "./RightPanelComps/RedoDialog";
import RedoSpecific from "./RightPanelComps/RedoSpecific";

const TaskRightPanel = ({
  selectedSection,
  activeSurvey,
  setActiveSurvey,
  activeTab,
  completedTab,
  setCompletedTab,
  setActiveTab,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const totalQues = activeTab?.questions?.length; // Total number of questions
  const changeProgressBy = (1 / totalQues) * 100;

  const [surveyCompelte, setSurveyComplete] = useState(false);
  const [redoSpecific, setRedoSpecific] = useState(false);

  const [currentQues, setCurrentQues] = useState(1);
  const [progress, setProgress] = useState(changeProgressBy);

  // State to manage the dialog open/close
  const [openRedoDialog, setOpenRedoDialog] = useState(false);

  useEffect(() => {
    // if survey is complete
    if (activeTab?.isSurveyComplete === true) {
      setSurveyComplete(true);
      setProgress(99.9);
      setCurrentQues(totalQues);
    }

    // if survey is incomplete
    else {
      setSurveyComplete(false);
      setProgress((1 / totalQues) * 100);
      setCurrentQues(1);
    }
  }, [activeTab, totalQues]);

  const handleNextQuestion = () => {
    // changing progress
    if (progress < 99.9) {
      setProgress(progress + changeProgressBy);
      setCurrentQues((prev) => prev + 1);
    }
    // when progress is 100%
    else if (currentQues === totalQues && progress >= 99.9) {
      // if survey is already on complete
      if (surveyCompelte) {
        setSurveyComplete(false);
        setProgress(changeProgressBy);
        setCurrentQues(1);
        setActiveTab(activeTab + 1);
      }
      // if progress is 100% and survey is not complete
      else {
        setCompletedTab([...completedTab, activeTab?._id]);
        setSurveyComplete(true);
        dispatch(UpdateSurveyComplete("add", activeTab?._id));
      }
    }
  };

  const handlePrevQuestion = () => {
    // if progress is more than initial
    if (progress > (1 / totalQues) * 100) {
      setProgress(progress - changeProgressBy);
      setCurrentQues((prev) => prev - 1);
    }
    // if progress becomes initial
    else if (selectedSection[0]?._id !== activeTab?._id) {
      setActiveTab(selectedSection[activeSurvey - 1]);
      setActiveSurvey((prev) => prev - 1);

      setProgress(changeProgressBy);
      setCurrentQues(1);
    }
  };

  const toggleRedoDialog = () => {
    setOpenRedoDialog(!openRedoDialog);
  };

  const handleRedoSection = (proceedAction) => {
    if (proceedAction === "EntireSec") {
      // remove activeTab from completedTab
      setCompletedTab(completedTab.filter((id) => id !== activeTab?._id));
      // Resetting states
      setSurveyComplete(false);
      setProgress(changeProgressBy);
      setCurrentQues(1);

      // reset survey section
      dispatch(UpdateSurveyComplete("remove", activeTab?._id));
    } else {
      setRedoSpecific(true);
    }
    toggleRedoDialog();
  };

  const activeTabQues = selectedSection[selectedSection.length - 1]?.questions;

  // Check if all surveys are complete
  // selectedSection.every((survey) => survey.isSurveyComplete) ||
  const allSurveysComplete =
    activeTab?.questions[currentQues - 1]?.question ===
    activeTabQues[activeTabQues.length - 1]?.question;

  return (
    <Box width={"100%"} height={"100%"} style={{ position: "relative" }}>
      <Box
        style={{
          width: "100%",
          height: "100%",
          boxShadow: "0px 0px 8px 0px #3374B940",
        }}
      >
        {redoSpecific ? (
          // Redo Specific Question
          <RedoSpecific
            questions={activeTab?.questions}
            activeTab={activeTab}
            setRedoSpecific={setRedoSpecific}
          />
        ) : !surveyCompelte ? (
          // Company Profile
          <RightSections
            question={activeTab?.questions[currentQues - 1]}
            currentQues={currentQues}
            progress={progress}
            totalQues={totalQues}
            activeTab={activeTab}
          />
        ) : (
          // Survey is complete
          <SectionComplete
            progress={progress}
            totalQues={totalQues}
            currentQues={currentQues}
            activeTab={activeTab}
          />
        )}

        {/* Section Complete */}
      </Box>

      {/* Action Buttons */}
      <Box
        style={{
          display: redoSpecific ? "none" : "flex",
          position: "absolute",
          right: 10,
          bottom: 10,
          gap: 10,
        }}
      >
        {surveyCompelte ? (
          <>
            {/* survey complete actions */}
            <CustomButton
              variant={"text"}
              color={"primary"}
              style={{ textTransform: "capitalize" }}
              onClick={toggleRedoDialog}
            >
              Redo Section
            </CustomButton>
            <CustomButton
              variant={"contained"}
              color={"primary"}
              style={{ textTransform: "capitalize" }}
              onClick={() => {
                if (allSurveysComplete) {
                  history.goBack();
                } else {
                  // Increment activeTab
                  setActiveTab(selectedSection[activeSurvey + 1]);

                  setActiveSurvey((prev) => prev + 1);
                }
              }}
              // disabled={activeTab === 1}
            >
              {allSurveysComplete ? "Save & Exit" : "Continue to next Section"}
            </CustomButton>
          </>
        ) : (
          <>
            <CustomButton
              variant={"text"}
              color={"primary"}
              style={{ textTransform: "capitalize" }}
              onClick={handlePrevQuestion}
              disabled={
                selectedSection[0]?._id === activeTab?._id && currentQues === 1
              }
            >
              Previous
            </CustomButton>
            <CustomButton
              variant={"contained"}
              color={"primary"}
              style={{ textTransform: "capitalize" }}
              onClick={handleNextQuestion}
              disabled={!activeTab?.questions[currentQues - 1]?.selectedOpt}
            >
              Submit & Next
            </CustomButton>
          </>
        )}
      </Box>

      <RedoDialog
        open={openRedoDialog}
        handleClose={toggleRedoDialog}
        proceedAction={handleRedoSection}
      />
    </Box>
  );
};
export default TaskRightPanel;
