import CustomCheckBox from "../../UI/checkBox/checkBox";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import briefcase from "../../../images/briefcase.png";

import id from "../../../images/idcard.svg";
import userIcon from "../../../images/userIcon.png";
import list from "../../../images/list.svg";
import { Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import ListIcon from "@material-ui/icons/List";
export const UserCard = ({
  member,
  onClick,
  hideCheckbox = false,
  isChecked = false,
}) => {
  const { name, email, role } = member;
  const [checked, setChecked] = useState(isChecked);

  const hadleClick = () => {
    setChecked(!checked);
    onClick();
  };
  return (
    <Grid
      container
      md={12}
      onClick={hadleClick}
      style={{
        border: "1px solid #E0E0E0",
        borderRadius: 8,
        margin: "3px 0px",
        width: "100%",
        padding: 4,
        cursor: "pointer",
        backgroundColor: checked ? "#EBF1F8" : "",
      }}
      role="button"
    >
      <Grid container md={12} style={{ width: "100%" }}>
        <Grid md={10} container alignItems="center">
          {!hideCheckbox && (
            <CustomCheckBox
              color="secondary"
              checked={checked}
              disabled={false}
            />
          )}
          <Typography
            style={{
              fontSize: 13,
              fontWeight: 600,
              padding: hideCheckbox ? "3px 10px" : "3px 0px",
            }}
          >
            {name}
          </Typography>
        </Grid>
        {/* <Grid md={2} container alignItems="center" justifyContent="center">
          <img src={id} alt="brief" style={{ color: "#505050", width: 22 }} />
          <Typography
            style={{ fontSize: 12, fontWeight: 400, padding: "3px 8px" }}
          >
            INF241
          </Typography>
        </Grid> */}
      </Grid>
      <Grid container md={12} style={{ width: "100%", padding: "3px 0px" }}>
        <Grid md={4} container alignItems="center">
          <MailOutlineIcon style={{ color: "#505050", marginLeft: "8px" }} />
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 400,
              padding: "3px 0px",
              color: "#505050",
              marginLeft: "8px",
            }}
          >
            {email}
          </Typography>
        </Grid>
        <Grid md={4} container alignItems="center">
          <img
            src={briefcase}
            alt="brief"
            style={{ color: "#505050", width: 22 }}
          />
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 400,
              padding: "3px 0px",
              color: "#505050",
              marginLeft: "8px",
            }}
          >
            {role}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export const SurveyListingCard = ({ member, onClick }) => {
  // const { name, questions, userName } = member;
  const [checked, setChecked] = useState(false);

  const hadleClick = () => {
    setChecked(!checked);
    onClick();
  };
  return (
    <Grid
      container
      md={12}
      onClick={hadleClick}
      style={{
        border: "1px solid #E0E0E0",
        borderRadius: 8,
        margin: "3px 0px",
        width: "100%",
        padding: 4,
        cursor: "pointer",
        backgroundColor: checked ? "#EBF1F8" : "",
      }}
      role="button"
    >
      <Grid container md={12} style={{ width: "100%" }}>
        <Grid md={8} container alignItems="center">
          <CustomCheckBox
            color="secondary"
            checked={checked}
            disabled={false}
          />
          <Typography
            style={{ fontSize: 14, fontWeight: 600, padding: "3px 0px" }}
          >
            Onboarding Survey
          </Typography>
        </Grid>
        <Grid md={4} container alignItems="center" justifyContent="center">
          <img
            src={userIcon}
            alt="brief"
            style={{ color: "#505050", width: 22 }}
          />
          <Typography
            style={{ fontSize: 12, fontWeight: 400, padding: "3px 8px" }}
          >
            Shanya Natrajan
          </Typography>
        </Grid>
      </Grid>
      <Grid container md={12} style={{ width: "100%", padding: "3px 0px" }}>
        <Grid md={4} container alignItems="center">
          {/* <ListIcon style={{ color: "#505050", fontSize:10  }} /> */}
          <img
            src={list}
            alt="list"
            style={{ color: "#505050", marginLeft: "8px" }}
          />
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 400,
              padding: "3px 0px",
              color: "#505050",
              marginLeft: "8px",
            }}
          >
            24 Questions
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export const SurveyCard = ({ member, onClick }) => {
  const { name, email, role } = member;
  const [checked, setChecked] = useState(false);

  const hadleClick = () => {
    setChecked(!checked);
    onClick();
  };
  return (
    <Grid
      container
      md={12}
      onClick={hadleClick}
      style={{
        border: "1px solid #E0E0E0",
        borderRadius: 8,
        margin: "3px 0px",
        width: "100%",
        padding: 4,
        cursor: "pointer",
        backgroundColor: checked ? "#EBF1F8" : "",
      }}
      role="button"
    >
      <Grid container md={12} style={{ width: "100%" }}>
        <Grid md={10} container alignItems="center">
          <CustomCheckBox
            color="secondary"
            checked={checked}
            disabled={false}
          />
          <Typography
            style={{ fontSize: 13, fontWeight: 600, padding: "3px 0px" }}
          >
            {name}
          </Typography>
        </Grid>
        <Grid md={2} container alignItems="center" justifyContent="center">
          <img src={id} alt="brief" style={{ color: "#505050", width: 22 }} />
          <Typography
            style={{ fontSize: 12, fontWeight: 400, padding: "3px 8px" }}
          >
            INF241
          </Typography>
        </Grid>
      </Grid>
      <Grid container md={12} style={{ width: "100%", padding: "3px 0px" }}>
        <Grid md={4} container alignItems="center">
          <MailOutlineIcon style={{ color: "#505050", marginLeft: "8px" }} />
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 400,
              padding: "3px 0px",
              color: "#505050",
              marginLeft: "8px",
            }}
          >
            {email}
          </Typography>
        </Grid>
        <Grid md={4} container alignItems="center">
          <img
            src={briefcase}
            alt="brief"
            style={{ color: "#505050", width: 22 }}
          />
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 400,
              padding: "3px 0px",
              color: "#505050",
              marginLeft: "8px",
            }}
          >
            {role}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
