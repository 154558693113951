import React from "react";
import { Typography } from "@material-ui/core";

// redux imports
// import { useSelector } from "react-redux";


const ResponseSectionTopHeading = ({ selectedResCompany }) => {
    // from redux
    //   const { selectedResCompany } = useSelector((state) => state.riskDashboard);

    // -----------------------------------------------


    return (
        <Typography
            style={{
                padding: "8px",
                border: "1px solid #EBF1F8",
                color: "#15314E",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "20px",
            }}
        >
            {selectedResCompany?.name}
        </Typography>
    );
};

export default ResponseSectionTopHeading;