import React from "react";
import PercentageDoughnutChart from "../../UI/percentageDoughnut.js/percentageDoughnutChart";
import { Card, Grid, ListItem, Paper, Typography } from "@material-ui/core";
import folder from "../../../images/Folder.png";
import charge from "../../../images/charge.png";
import heart from "../../../images/SelfAssessment.png";
import DonutChart from "react-donut-chart";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
function ControversyProfile(props) {
  const { riskEvaluator } = props;
  const CircularChart = ({ score, title, color }) => {
    return (
      <Card style={{ margin: "10px 5px", padding: "5px", width: "45%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
            width: "100%",
            // marginTop: "10px",
            // marginLeft: "10px",
            padding: "10px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={heart}
              alt="charge"
              style={{ width: "40px", height: "40px" }}
            />

            <DonutChart
              // outerRadius={0.5}
              // innerRadius={0.9}

              className="donutchart-innertext-value"
              height={150}
              width={150}
              legend={false}
              emptyOffset={0.01}
              emptyColor="#F9EEED"
              formatValues={(values, total) => `${score}`}
              colors={[color ? color : "#9ABD8B"]}
              innerRadius={0.7}
              outerRadius={0.8}
              data={[
                {
                  label: "",
                  value: score,
                },
                {
                  label: "",
                  value: 100 - score,
                  isEmpty: true,
                },
              ]}
            />
            {/* <Doughnut data={data} options={options} plugins={plugins} /> */}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: color ? color : "#9ABD8B",
                borderRadius: "100%",
                width: "12px",
                height: "12px",
              }}
            />
            <Typography style={{ marginLeft: "10px" }}>{title}</Typography>
          </div>
        </div>
      </Card>
    );
  };

  let controversies = [
    "Anti-competition controversy",
    "Business ethics controversie",
    "Intellectual property controversies",
    "Critical countries controversie",
    "Public health controversies",
    "Tax fraud controversies",
    "Child labour controversies",
    "Human rights controversies",
    "Management compensation controversies count",
    "Consumer controversies",
    "Customer health and safety controversies",
    "Privacy controversies",
    "Product access controversies",
    "Responsible marketing controversies",
    "Responsible R&D controversies",
    "Environmental controversies",
    "Accounting controversies count",
    "Insider dealings controversies",
    "Shareholder rights controversies",
    "Diversity and opportunity controversies",
    "Employee health and safety controversies",
    "Wages or working conditions controversies",
    "Strikes",
  ];
  console.log(props.riskEvaluator.scores);
  return (
    <Grid container spacing={2}>
      <Grid item md={7}>
        <BlueBackgroundCard heading={"Controversy Score"} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Card style={{ padding: "5px", width: "100%" }}>
            {/* <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
            ESG Controversy Score
          </Typography> */}
            <Typography
              style={{ fontWeight: 400, fontSize: "13px", color: "#15314E" }}
            >
              Our Controversy Score provides valuable insights into your ESG
              practices, helping you meet stakeholder expectations and work
              towards a more sustainable future.
            </Typography>
            <div
              style={{
                display: "flex",
                minHeight: "42vh",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                  justifyContent: "space-between",
                  padding: "10px 10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "40px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#9ABD8B",
                      borderRadius: "100%",
                      width: "10px",
                      height: "10px",
                    }}
                  />
                  <Typography
                    style={{
                      marginLeft: "10px",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Controversy Score
                  </Typography>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                  }}
                >
                  <img
                    src={charge}
                    alt="charge"
                    style={{ width: "40px", height: "40px" }}
                  />
                  <PercentageDoughnutChart
                    color="#9ABD8B"
                    emptyColor="#EBF6EF"
                    percentage={Math.round(riskEvaluator.scores.esgcScore)}
                    customSize={150}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                  // justifyContent: "space-between",
                  justifyContent: "center",
                  padding: "10px 30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "20%",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#ECA270",
                      borderRadius: "100%",
                      width: "10px",
                      height: "10px",
                    }}
                  />
                  <Typography
                    style={{
                      marginLeft: "10px",
                      fontSize: "14px",
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Online Sentiment Score
                  </Typography>
                </div>
                <div
                  style={{ width: "50%", display: "flex", marginLeft: "20px" }}
                >
                  <img
                    src={heart}
                    alt="charge"
                    style={{ width: "40px", height: "40px" }}
                  />
                  <PercentageDoughnutChart
                    color="#ECA270"
                    emptyColor="#F9EEED"
                    percentage={Math.round(riskEvaluator.scores.esgcScore)}
                    customSize={150}
                  />
                </div>
              </div>
            </div>
          </Card>

          {/* <div style={{ width: "30%", padding: "5px" }}>
          <Typography style={{ fontWeight: 400, fontSize: "16px" }}>
            Deviation Percentage
          </Typography>
          <Typography
            style={{ fontWeight: 500, fontSize: "24px", padding: "16px 0px" }}
          >
            12%
          </Typography>
          <Typography
            style={{ fontWeight: 400, fontSize: "13px", color: "#15314E" }}
          >
            The deviation may indicate a gap between a company's actual ESG
            practices and what is being publicly disclosed, highlighting the
            need for improved transparency and accountability.
          </Typography>
        </div> */}
        </div>
      </Grid>
      <Grid
        item
        md={5}
        className="scroll"
        style={{ height: "60vh", paddingBottom: "25px" }}
      >
        <BlueBackgroundCard heading={"Controversy Topic"} sideHeading="Count" />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          {props.riskEvaluator.controversyData &&
            Object.entries(props.riskEvaluator.controversyData).map(
              ([key, value]) => (
                <Grid
                  container
                  justifyContent="space-between"
                  style={{
                    padding: 10,
                    backgroundColor: "#FBFBFB",
                    borderBottom: "1px solid #EBF1F8",
                  }}
                >
                  <Grid item md={10}>
                    <Typography style={{ fontSize: 13, fontWeight: 600 }}>
                      {key}
                    </Typography>
                  </Grid>
                  <Grid item md={1}>
                    <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                      {value}
                    </Typography>
                  </Grid>
                </Grid>
              )
            )}
        </div>
      </Grid>
      {/* <Typography style={{ marginTop: 15, fontSize: "15px", fontWeight: 600 }}>
        Controversy Count
      </Typography>
      <Typography
        style={{
          fontWeight: 400,
          fontSize: "13px",
          color: "#15314E",
          paddingBottom: 10,
        }}
      >
        Our Controversy Count provides valuable insights into your ESG
        Controversy practices, helping you meet stakeholder expectations and
        work towards a more sustainable future.
      </Typography>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        {props.riskEvaluator.controversyData &&
          Object.entries(props.riskEvaluator.controversyData).map(
            ([key, value]) => (
              <>
                <CircularChart
                  // score={45}
                  score={Math.round(value.toFixed(0))}
                  title={key}
                  color="#4dc9f6"
                />
              </>
            )
          )}
      </div> */}
    </Grid>
  );
}

export default ControversyProfile;
