import { Grid, Icon, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import link from "../../../images/link.png";
import pdfImg from "../../../images/PDF.png";
import user from "../../../images/userIconBlue.png";
import doc from "../../../images/fileIcon.svg";
import clock from "../../../images/clock.svg";
import moment from "moment";

function PublicReports({ file, handleFileRemove, publicDocuments, ...props }) {
  // const reportData = [
  //   {
  //     name: "file name",
  //     heading: "introduction to the art of future",
  //     link: "link",
  //   },
  //   {
  //     name: "file name",
  //     heading: "introduction to the art of future",
  //     link: "link",
  //   },
  //   {
  //     name: "file name",
  //     heading: "introduction to the art of future",
  //     link: "link",
  //   },
  // ];

  const fileRemove = (value) => {
    const filterFile = file.filter((v) => v.name !== value.name);
    handleFileRemove(filterFile);
  };

  return (
    <div className="scroll" style={{ marginTop: 18, height: "60vh" }}>
      <Grid
        container
        md={12}
        sm={12}
        xs={12}
        style={{
          marginTop: "10px",
          backgroundColor: "#EBF1F8",
          padding: "3px",
          borderRadius: "5px",
        }}
      >
        <Grid item md={3} xs={3}>
          <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
            Publications
          </Typography>
        </Grid>
        <Grid item md={2} xs={2} align="center">
          <Typography style={{ fontSize: "13px" }}>Document Type</Typography>
        </Grid>
        <Grid item md={2} xs={2} align="center">
          <Typography style={{ fontSize: "13px" }}>Uploaded By</Typography>
        </Grid>
        <Grid item md={2} xs={2} align="center">
          <Typography style={{ fontSize: "13px" }}>Upload Time</Typography>
        </Grid>
        <Grid item md={2} xs={2} align="center">
          <Typography style={{ fontSize: "13px" }}>Status</Typography>
        </Grid>
        <Grid item md={1} xs={1} align="center">
          <Typography style={{ fontSize: "13px" }}>Action</Typography>
        </Grid>
      </Grid>
      {console.log(props)}
      {publicDocuments &&
        publicDocuments.length > 0 &&
        publicDocuments.map((file) => (
          <Grid
            container
            md={12}
            sm={12}
            xs={12}
            style={{
              marginTop: "10px",
              padding: "5px",
              borderBottom: "1px solid grey",
              borderRadius: "2px",
              alignItems: "center",
            }}
          >
            {console.log(file?.uploaded_on)}
            <Grid
              item
              md={3}
              xs={3}
              container
              style={{ flexWrap: "nowrap", alignItems: "center" }}
            >
              <img
                style={{ width: "20px", height: "20px", marginRight: "10px" }}
                src={pdfImg}
                alt="pdf"
              />
              <div>
                <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                  {file.file_name || "publication name"}
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    marginLeft: "5px",
                    color: "grey",
                  }}
                >
                  {file.year || "publication year"}
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              md={2}
              xs={2}
              container
              align="center"
              style={{ flexWrap: "nowrap", alignItems: "center" }}
            >
              <img
                style={{ width: "16px", height: "16px" }}
                src={doc}
                alt="doc"
              />
              <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                {file.type || "doc type"}
              </Typography>
            </Grid>
            <Grid
              item
              md={2}
              xs={2}
              // container
              align="center"
              style={{
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: "20px", height: "20px", marginRight: "0px" }}
                src={user}
                alt="pdf"
              />
              <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                {file?.user_name || "username"}
              </Typography>
            </Grid>
            <Grid
              item
              md={2}
              xs={2}
              container
              align="center"
              style={{ flexWrap: "nowrap", alignItems: "center" }}
            >
              <img
                style={{ width: "20px", height: "20px", marginRight: "5px" }}
                src={clock}
                alt="pdf"
              />
              <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                {moment(file.uploaded_on).format("ll")}
              </Typography>
            </Grid>

            <Grid item md={2} xs={2} align="center">
              <Typography
                style={{
                  color: !file?.satus ? "#8C7038" : "#32A05F",
                  backgroundColor: !file?.satus ? "#FFEFD0" : "#EBF6EF",
                  borderRadius: 12,
                  fontSize: 12,
                  padding: "3px 8px",
                  width: "fit-content",
                  // margin: "5px 8px",
                }}
              >
                {!file?.satus ? "Uploading" : "Uploaded"}
              </Typography>
            </Grid>
            {/* <Grid item md={2} xs={2} align="center">
           <Typography style={{ fontSize: "13px" }}>{file.link}</Typography>

              <a
                href={file?.file_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{ width: "21px", cursor: "pointer" }}
                  src={link}
                  alt="link"
                />
              </a> 
            </Grid>*/}

            <Grid item md={1} xs={1} align="center">
              <Typography
                onClick={() => fileRemove(file)}
                style={{ fontSize: "13px" }}
              >
                <Icon
                  style={{ cursor: "pointer", color: "#3374b9", fontSize: 20 }}
                >
                  more_vert
                </Icon>
                {/* <Icon color="action" onClick={() => fileRemove(file)}>
                <RemoveCircleOutlineIcon
                    style={{ color: "#3374b9", cursor: "pointer" }}
                  />
                </Icon> */}
              </Typography>
            </Grid>
            {/* <Grid item md={1} xs={1} align="center">
      <Icon style={{ alignSelf: "right" }}>more_vert</Icon>
    </Grid> */}
          </Grid>
        ))}
    </div>
  );
}

export default PublicReports;
