import {
  AppBar,
  Box,
  Drawer,
  Grid,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import RiskSurveyTop from "../../widgets/riskSurveyComponents/Main/RiskSurveyTop";
import RiskSurveyDrawer from "../../widgets/riskSurveyComponents/Main/RiskSurveyDrawer";
import VendorLeft from "./VendorLeft";
import VendorRight from "./VendorRight";
import ProceedButtonCard from "../../UI/proceedButtonCard/proceedButtonCard";
import { appbarStyles } from "../../widgets/esgCompanyProfile/esgCompantProfile";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { appTheme } from "../../widgets/riskAssessment/brmDetaails";
import VendorProceedButton from "./vendorComps/VendorProceedButton";

function VendorScreen(props) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { setVendorTabNo, setVendorSubTabNo, vendor } = props;
  // console.log("ven", props);
  // handling drawer open and close
  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const classes = appbarStyles();
  const history = useHistory();

  useEffect(() => {
    setVendorSubTabNo(0);
    setVendorTabNo(0);
  }, []);
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        // px: 5,
      }}
    >
      {/* Main areas */}
      {/* <RiskSurveyTop handleDrawer={handleDrawer} /> */}

      {/* Drawer */}
      {/* <Drawer anchor="right" open={drawerOpen} onClose={handleDrawer}>
        <RiskSurveyDrawer handleDrawer={handleDrawer} />
      </Drawer> */}

      {/* MAIN SECTION VENDOR LEFT AND RIGHT */}
      <Grid container>
        <ArrowBack
          onClick={() => {
            history.goBack();
          }}
          style={{ cursor: "pointer" }}
        />
        <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
          {props?.brmData?.organizationDetails[0]?.name}
        </Typography>
      </Grid>
      <MuiThemeProvider theme={appTheme}>
        <AppBar position="static" color="inherit" className={classes.AppBar}>
          {
            <Tabs
              value={vendor.vendorTabNo}
              onChange={(e, newVal) => {
                setVendorTabNo(newVal);
                setVendorSubTabNo(0);
              }}
            >
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>Company Setup</span>}
              />

              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>Data Setup</span>}
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={
                  <span className={classes.tabLabel}>Vendor Diagnosis</span>
                }
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>ESG Diagnosis</span>}
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>Controversies</span>}
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>Vendor Risk</span>}
              />
            </Tabs>
          }
        </AppBar>
      </MuiThemeProvider>
      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          // height: windowWidth >= 992 ? "76vh" : "91%",
          padding: 10,
        }}
      >
        <Grid item xs={12} sm={11} md={4} style={{ height: "75vh" }}>
          <VendorLeft {...props} />
        </Grid>
        <Grid item xs={12} sm={11} md={8} style={{ height: "75vh" }}>
          <VendorRight {...props} />
        </Grid>
        <Grid item xs={11} md={11}>
          {<VendorProceedButton {...props} />}
        </Grid>
      </Grid>
    </Box>
  );
}

export default VendorScreen;
