import { Box } from "@material-ui/core";
import GaugeComponent from "react-gauge-component";
import "./tempCSS.css";

const GaugeChart = ({ value }) => {
  return (
    // <Box style={{ width: "100%", height: "100%" }}>
      <GaugeComponent
        type="semicircle"
        arc={{
          width: 0.4,
          padding: 0.005,
          cornerRadius: 10,
          // gradient: true,
          subArcs: [
            {
              limit: 0.5,
              color: "#5BB37F",
              showTick: true,

              onClick: () => console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA"),
              //   tooltip: {
              //     text: "0-0.5",
              //   },
            },
            {
              limit: 1.5,
              color: "#5BB37F",
              showTick: true,
            },
            {
              limit: 2.5,
              color: "#FFD685",
              showTick: true,
            },
            {
              limit: 3.5,
              color: "#FFD685",
              showTick: true,
            },
            {
              limit: 4.5,
              color: "#D0766B",
              showTick: true,
            },
            {
              color: "#D0766B",
            },
          ],
        }}
        pointer={{
          color: "#C0D4E9",
          length: 0.4,
          width: 35,
          elastic: true,
        }}
        labels={{
          valueLabel: {
            formatTextValue: (value) => value,
          },
          tickLabels: {
            type: "outer",
            valueConfig: {
              formatTextValue: (value) => value,
              fontSize: 5,
            },
            ticks: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }],
          },
        }}
        value={value}
        minValue={0}
        maxValue={5}
      />
    // </Box>
  );
};
export default GaugeChart;
