import React, { useState } from "react";
import { Box, Icon, IconButton, Typography } from "@material-ui/core";

import StepRight from "../../stepLayout/right/StepRight";

import CustomButton from "../../../../../UI/button/button";
import SurveyQuestionTable from "./SurveyQuestionTable";
import AssignDrawer from "../assignDrawer/AssignDrawer";
import ExploreQuestion from "./ExploreQuestion";
import { FaListUl } from "react-icons/fa6";
import { FiUserPlus } from "react-icons/fi";
import { FaCheck } from "react-icons/fa6";
import { FiActivity } from "react-icons/fi";
import { HiOutlineUsers } from "react-icons/hi";
import { FiUserCheck } from "react-icons/fi";
import NoData from "../../../../NoData/NoData";

function Step2Right({
  contributors,
  approvers,
  handleContributers,
  handleApprovers,
  handleDeleteApprovers,
  handleDeleteContributers,
  toggleSingleItemUser,
  handleReset,
  users,
  sectionQuestions,
  status,
  handleStatus,
  surveyList
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [hasExplore, setHasExplore] = useState(false);

  const handleExploreQuestion = (data) => {
    setHasExplore(true);
  };

  return (
    <StepRight>
      <Box
        style={{
          display: "flex",
          // padding: "10px",
          justifyContent: "space-between",
          gap: 10,
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
          }}
        >
          {hasExplore && (
            <IconButton size="small">
              <Icon
                style={{ color: "#3374B9" }}
                onClick={() => setHasExplore(false)}
                fontSize="small"
              >
                arrow_back
              </Icon>
            </IconButton>
          )}
          Company Profile
        </Typography>
        {hasExplore ? (
          <CustomButton
            variant="contained"
            color="primary"
            onClick={() => {
              setIsDrawerOpen(true);
            }}
            startIcon={
              <Icon style={{ transform: "scaleX(-1)" }}>keyboard_return</Icon>
            }
            style={{ textTransform: "capitalize" }}
          >
            View Thread
          </CustomButton>
        ) : (
          <CustomButton
            variant="text"
            color="primary"
            onClick={() => setIsDrawerOpen(true)}
            style={{ textTransform: "capitalize" }}
          >
            Assign Question
          </CustomButton>
        )}
      </Box>
      {!hasExplore && (
        <Box
          style={{
            display: "flex",
            padding: "10px",
            justifyContent: "flex-start",
            gap: 20,
            alignItems: "center"
          }}
        >
          <Box style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <FaListUl size={"16px"} style={{ color: "#5C90C7" }} />
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              11 Questions
            </Typography>
          </Box>
          <Box style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <FiUserPlus size={"16px"} style={{ color: "#5C90C7" }} />
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              0 Users Assigned
            </Typography>
          </Box>
          <Box style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <FaCheck size={"16px"} style={{ color: "#5C90C7" }} />
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              0 Users Completed
            </Typography>
          </Box>
          <Box style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <FiActivity size={"16px"} style={{ color: "#5C90C7" }} />
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              In Progress
            </Typography>
          </Box>




        </Box>
      )}
      {hasExplore && (
        <Box
          style={{
            display: "flex",
            padding: "10px",
            justifyContent: "flex-start",
            gap: 10,
          }}
        >
          <HiOutlineUsers size={"16px"} style={{ color: "#5C90C7" }} />
          <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
            {`${contributors.length} contributors`}
          </Typography>
          <FiUserCheck size={"16px"} style={{ color: "#5C90C7" }} />
          <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
            {`${approvers.length} Approver`}
          </Typography>
        </Box>
      )}
      {hasExplore && (
        <Box style={{ overflow: "scroll", height: "80%" }}>
          <ExploreQuestion approver={approvers} contributer={contributors} />
        </Box>
      )}
      {!hasExplore && (
        sectionQuestions.length > 0 ? <Box style={{ overflow: "scroll", height: "80%" }}>
          <SurveyQuestionTable status={status} handleExploreQuestion={handleExploreQuestion} sectionQuestions={sectionQuestions} />
        </Box> :  <Box style={{  height: "50%" }}><NoData message={'Please Select Section'} /></Box> 
      )}

      {isDrawerOpen && (
        <AssignDrawer
          isDrawerOpen={isDrawerOpen}
          handleCloseDrawer={() => { setIsDrawerOpen(false); handleStatus('In Progress') }}
          contributors={contributors}
          approvers={approvers}
          handleContributers={handleContributers}
          handleApprovers={handleApprovers}
          handleDeleteApprovers={handleDeleteApprovers}
          handleDeleteContributers={handleDeleteContributers}
          users={users}
          handleReset={handleReset}
          toggleSingleItemUser={toggleSingleItemUser}
          surveyList={surveyList}
        />
      )}
    </StepRight>
  );
}

export default Step2Right;
